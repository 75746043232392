import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FormControl, TextField } from '@mui/material';
import * as Constants from '../../../Helpers/Constants';
import * as Helpers from '../../../Helpers/Helpers';
import _ from 'lodash';
import {
  FINISHED_STATUSES_PAYMENT_ADJUSTMENT,
  RequestsTablePaymentAdjustmentStatusesConstants,
  createPaymentAdjustmentTypeDiv,
  addPaymentAdjustmentTypeStylingAttributes,
  PENDING_STATUSES_PAYMENT_ADJUSTMENT,
  STATUSES_PAYMENT_ADJUSTMENT,
} from '../helpers/Constants';

// Statuses Related Functions

export const getPaymentAdjustmentStatusesByTableTypeAndRole = (
  tableType,
  userRole,
  callerComponent,
) => {
  if (callerComponent === Constants.CallerComponent.View) {
    if (tableType === Constants.TablesTypes.Pending) {
      return PENDING_STATUSES_PAYMENT_ADJUSTMENT;
    } else if (tableType === Constants.TablesTypes.Finished) {
      return FINISHED_STATUSES_PAYMENT_ADJUSTMENT;
    }
  } else if (callerComponent === Constants.CallerComponent.Manage) {
    if (tableType === Constants.TablesTypes.Pending) {
      return getPendingStatusesByRole(userRole);
    } else if (tableType === Constants.TablesTypes.Finished) {
      return getFinishedStatusesByRole(userRole);
    }
  } else {
    console.error('Unexpected Caller Component');
  }
};

export const getPendingStatusesByRole = (userRole) => {
  // HR And Admin
  if ([Constants.ROLES.HR, Constants.ROLES.Admin].includes(userRole)) {
    return [STATUSES_PAYMENT_ADJUSTMENT.PendingHrApproval];
  } else {
    return [STATUSES_PAYMENT_ADJUSTMENT.PendingManagerApproval];
  }
};

export const getFinishedStatusesByRole = (userRole) => {
  if ([Constants.ROLES.HR, Constants.ROLES.Admin].includes(userRole)) {
    return FINISHED_STATUSES_PAYMENT_ADJUSTMENT;
  } else {
    return Helpers.getAllExcept(
      Object.values(STATUSES_PAYMENT_ADJUSTMENT),
      getPendingStatusesByRole(userRole),
    );
  }
};

export const createPaymentAdjustmentStatusesFilterQuery = (
  tableType,
  userRole,
  callerComponent,
) => {
  const statuses = getPaymentAdjustmentStatusesByTableTypeAndRole(
    tableType,
    userRole,
    callerComponent,
  );
  const queryString = Helpers.createStatusesQuery(statuses);
  return queryString;
};
// Statuses Related Functions

// Mui datatables Utils

export const getDatatableColumns = () => {
  const datatableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'requester',
      label: 'Requester',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Requester : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Requester"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'employee',
      label: 'Issued Employee',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Issued Employee : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Issued Employee"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'From',
      name: 'from',
      options: {
        display: false,
        filter: true,
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `From : ${reversedDate}`;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'To',
      name: 'to',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `To : ${reversedDate}`;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'reason',
      label: 'Reason',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'day',
      label: 'Day',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updated_at',
      label: 'Last Updated At',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return datatableColumns;
};

export const originalToStyled = (originalRequests) => {
  const renderedRequests = originalRequests.map((request) => {
    // Adding status style to the status
    const statusStyle =
      _.find(RequestsTablePaymentAdjustmentStatusesConstants, {
        id: request.status.id,
      }) || {};
    request.status.style = statusStyle.style;
    request.type = addPaymentAdjustmentTypeStylingAttributes(request.type);
    return request;
  });

  return renderedRequests;
};

export const styledToMuiDatatable = (renderedRequests, actionsClickHandler) => {
  let datatableRequests = [];
  datatableRequests = renderedRequests.map((request) => {
    let _updatedRequest = {};
    _updatedRequest = {
      ...request,
      requester: request.requester.email,
      employee: request.employee.email,
      // Decimal Time to minutes and hours should be here
      status: Helpers.createStatusBadgeDiv(
        request.status.name,
        request.status.style.bg,
        request.status.style.text,
      ),
      // type viewing from the enum
      type: createPaymentAdjustmentTypeDiv(request.type.id),
    };
    return _updatedRequest;
  });

  return datatableRequests;
};

export const originalToPrintable = (originalRequests) => {
  return originalRequests.map((element) => {
    return {
      id: element.id,

      'requester email': element.requester.email,

      'user id': element.employee.id,
      'user code': element.employee.code,
      'user name': element.employee.name,
      'user email': element.employee.email,
      'user role': element.employee.roles[0].display_name,
      'user team': element.employee.roles[0].team.name,

      day: element.day,
      type: element.type.name,
      value: element.value,

      reason: element.reason,
      status: element.status.name,
      comment: element.comment,

      'created by': element.created_by,
      'updated by': element.updated_by,
      'created at': element.created_at,
      'updated at': element.updated_at,
    };
  });
};

// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array
  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};
