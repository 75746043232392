import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import ExcusesManageApprove from './ExcusesManageApprove';

import * as Constants from '../../../Helpers/Constants';

import * as ExcuseRequestsUtils from './ExcusesManageUtils';

import ExcusesManageReject from './ExcusesManageReject';

export default function ExcusesManageShow({
  onClose,
  selectedValue,
  open,
  managedExcuseRequest,
  currentTable,
}) {
  const [renderedExcuseRequest, setRenderedExcuseRequest] = React.useState({});

  const [openApproveRequestDialog, setOpenApproveRequestDialog] =
    React.useState(false);

  const [openRejectRequestDialog, setOpenRejectRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Approve Request dialog //
  const handleApproveRequestOpen = () => setOpenApproveRequestDialog(true);
  const handleApproveRequestClose = () => setOpenApproveRequestDialog(false);
  // Approve Request dialog //

  // Reject Request dialog //
  const handleRejectRequestOpen = () => setOpenRejectRequestDialog(true);
  const handleRejectRequestClose = () => setOpenRejectRequestDialog(false);
  // Reject Request dialog //

  // Component did update
  React.useEffect(() => {
    // Update excuse request.
    const _renderedExcuseRequest = ExcuseRequestsUtils.originalToStyled([
      managedExcuseRequest,
    ]);
    setRenderedExcuseRequest(_renderedExcuseRequest[0]);
  }, [managedExcuseRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === Constants.TablesTypes.Pending) {
    dialogActions = (
      <>
        <div>
          <button
            className="btn btn-danger ms-2"
            onClick={() => handleRejectRequestOpen()}
          >
            Reject
          </button>
        </div>
        <div>
          <Button onClick={handleClose}>Close</Button>
          <button
            className="btn btn-success ms-2 me-2 mb-2"
            onClick={() => handleApproveRequestOpen()}
          >
            Approve
          </button>
        </div>
      </>
    );
  } else if (currentTable.type === Constants.TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="ms-auto me-2">
          <Button onClick={handleClose}>Close</Button>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedExcuseRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <ExcusesManageApprove
              open={openApproveRequestDialog}
              onClose={handleApproveRequestClose}
              managedExcuseRequest={managedExcuseRequest}
            />
            <ExcusesManageReject
              open={openRejectRequestDialog}
              onClose={handleRejectRequestClose}
              managedExcuseRequest={managedExcuseRequest}
            />
            <DialogTitle>Excuse Request Details</DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText
                  primary={<>Request ID : {renderedExcuseRequest.id}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>User Name : {renderedExcuseRequest.user.name}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Email : {renderedExcuseRequest.user.email}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Date : {renderedExcuseRequest.date}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Reason : {renderedExcuseRequest.reason}</>}
                />
              </ListItem>
              <ListItem>
                <div>Status :</div>
                <span
                  className={`
						ms-2 badge
						${renderedExcuseRequest.status.style.bg}
						${renderedExcuseRequest.status.style.text}
					`}
                >
                  {renderedExcuseRequest.status.name}
                </span>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Management Notes : {renderedExcuseRequest.comment || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated At : {renderedExcuseRequest.updated_at || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated By : {renderedExcuseRequest.updated_by || ''}
                    </>
                  }
                />
              </ListItem>
            </List>

            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
