import * as React from 'react';
import { Button } from '@mui/material';

import * as Requests from '../../Helpers/Requests';
import * as Helpers from '../../Helpers/Helpers';
import * as EvaluationManageUtils from './helpers/utils';
import EvaluationManageShow from './show';

import MUIDataTable from 'mui-datatables';
import { EVALUATION_SHOW_ACTIONS } from './helpers/consts';

export default function EvaluationManageTable({ currentTable }) {
  const IdCellIndex = 0;

  // Requests data variables
  const [originalEvaluationRequests, setOriginalEvaluationRequests] =
    React.useState([]);
  const [datatableEvaluationRequests, setDatatableEvaluationRequests] =
    React.useState([]);
  // Requests data variables

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  let currentTableState = {};
  let currentFilters = [];

  const [currentFilterFields, setCurrentFilterFields] = React.useState({});
  // Table effects variables

  // Pagination variables
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 5,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (updatedRowsPerPageValue) => {
    setController({
      ...controller,
      rowsPerPage: updatedRowsPerPageValue,
      page: 0,
    });
  };
  // Pagination variables

  // Mui datatables variables
  const options = {
    selectableRows: 'none',
    count: requestsCount,
    page: controller.page,
    rowsPerPage: controller.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: true,
    filterType: 'textField',
    onRowClick: (row) => {
      handleManageRequestOpen(row[IdCellIndex]);
    },
    // controlling download content
    onDownload: (buildHead, buildBody, columns, data) => {
      Requests.getEvaluations(
        currentTable.type,
        null,
        null,
        currentFilterFields,
      )
        .then((evaluationRequests) => {
          const tableData = evaluationRequests.data;
          const printableTableData =
            EvaluationManageUtils.originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'Appraisals.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
    // setting row style
    setRowProps: () => ({
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    }),
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    // setting Head cell style
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      return style;
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(newTableState.page);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(newTableState.rowsPerPage);
          break;
      }
      currentTableState = newTableState;
    },
    // Handle changing the filter from the chips
    onFilterChange: (column, newFilters, type) => {
      const applyFilters = () => newFilters;
      if (newFilters !== currentFilters) {
        handleFilterSubmit(applyFilters);
        currentFilters = newFilters;
      }

      // Reset the pagination to the first page
      setController((prev) => ({ ...prev, page: 0 }));
    },
  };
  // Mui datatables

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    Requests.getEvaluations(
      currentTable.type,
      controller.page,
      controller.rowsPerPage,
      currentFilterFields,
    )
      .then((evaluations) => {
        setOriginalEvaluationRequests(evaluations.data);
        setRequestsCount(evaluations.meta.total);
        setCurrentColumns(EvaluationManageUtils.getDatatableColumns());
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [controller, currentTable]);
  // Component did mount.

  // update styled and datatable requests whenever fetched requests change
  React.useEffect(() => {
    const _styledEvaluationRequests = EvaluationManageUtils.originalToStyled(
      originalEvaluationRequests,
    );
    const _datatableEvaluationRequests =
      EvaluationManageUtils.styledToMuiDatatable(
        _styledEvaluationRequests,
        handleManageRequestOpen,
      );
    setDatatableEvaluationRequests(_datatableEvaluationRequests);
  }, [originalEvaluationRequests]);
  // update styled and datatable requests whenever fetched requests change

  // Handling apply filters button action
  const handleFilterSubmit = (applyFilters) => {
    const filterFields = EvaluationManageUtils.createFilterFields(
      currentTableState,
      applyFilters,
    );

    setCurrentFilterFields(filterFields);

    Requests.getEvaluations(
      currentTable.type,
      controller.page,
      controller.rowsPerPage,
      filterFields,
    )
      .then((filteredRequests) => {
        setOriginalEvaluationRequests(filteredRequests.data);
        setRequestsCount(filteredRequests.meta.total);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  // Handling apply filters button action

  // Manage Evaluation Request section
  const [managedRequestLoaded, setManagedRequestLoaded] = React.useState(false);
  const [openManageRequestDialog, setOpenManageRequestDialog] =
    React.useState(false);
  const [managedEvaluationRequest, setManagedEvaluationRequest] =
    React.useState({});

  const handleManageRequestOpen = (requestId) => {
    Requests.getEvaluation(requestId, EVALUATION_SHOW_ACTIONS.Show)
      .then((evaluationRequest) => {
        setManagedEvaluationRequest(evaluationRequest);
        setManagedRequestLoaded(true);
        setOpenManageRequestDialog(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  const handleManageRequestClose = () => setOpenManageRequestDialog(false);
  // Manage Evaluation Request section

  return (
    <>
      {managedRequestLoaded && (
        <EvaluationManageShow
          open={openManageRequestDialog}
          onClose={handleManageRequestClose}
          managedEvaluationRequest={managedEvaluationRequest}
          currentTable={currentTable}
        />
      )}
      <MUIDataTable
        title={currentTable.titleName}
        data={datatableEvaluationRequests}
        columns={currentColumns}
        options={options}
      />
    </>
  );
}
