import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import * as Requests from '../Helpers/Requests';
import * as Constants from '../Helpers/Constants';

export default function Home() {
  // load the component only when userData exists.
  const [userDataLoaded, setUserDataLoaded] = React.useState(false);

  React.useEffect(() => {
    // getting the current user
    Requests.getCurrentUser()
      // if user token is not existing or incorrect
      // the catch function will handle the redirect to login
      .then((user) => {
        Constants.setCurrentUser(user);
        setUserDataLoaded(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, []);

  return (
    <>
      {userDataLoaded && (
        <>
          <Header />
          <Sidebar />
          <div
            style={{
              left: Constants.sidebarWidth,
              position: 'relative',
              width: `calc(100% - ${Constants.sidebarWidth})`,
            }}
          >
            <div className="mx-5 mt-5">
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  );
}
