import React from 'react';

import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';
import { createPayrollInvoice } from '../../../Helpers/Requests';
import { Pages } from '../../../Helpers/Constants';

export default function InvoicePublish({
  publishModalOpen,
  setPublishModalOpen,
  invoiceFilterValues,
}) {
  const [requestLoading, setRequestLoading] = React.useState(false);

  const handleDeleteLoan = (e) => {
    e.preventDefault();

    setRequestLoading(true);

    const formData = {
      ...invoiceFilterValues,
      month: invoiceFilterValues.month.format('YYYY-MM'),
    };

    createPayrollInvoice(formData)
      .then(() => {
        toast('Invoice was published successfully.', {
          type: 'success',
        });
        setTimeout(() => {
          window.location.replace(Pages.PayrollHistory.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <Dialog
        onClose={() => setPublishModalOpen(false)}
        open={publishModalOpen}
      >
        <form onSubmit={handleDeleteLoan} style={{ width: '500px' }}>
          <DialogTitle>Confirm Invoice Publishing</DialogTitle>
          <List sx={{ pt: 0 }}>
            <DialogContent dividers>
              <div>Are you sure you want to publish this invoice?</div>
            </DialogContent>
          </List>
          <DialogActions className="d-flex justify-content-between mx-2">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setPublishModalOpen(false)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-warning"
              disabled={requestLoading}
            >
              Confirm Publish
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
