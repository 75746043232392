import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import * as Constants from '../../../Helpers/Constants';

import * as ExcuseRequestsUtils from './ExcusesViewUtils';
import { getRoleInitialPendingStatuses } from '../Constants';
import ExcusesViewDeleteModal from './ExcusesViewDeleteModal';

export default function ExcusesViewShow(props) {
  const { onClose, selectedValue, open, viewedExcuseRequest } = props;
  const [openDeleteRequestDialog, setOpenDeleteRequestDialog] =
    React.useState(false);
  const [renderedExcuseRequest, setRenderedExcuseRequest] = React.useState({});

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Component did update
  React.useEffect(() => {
    // Update excuse request.
    const _renderedExcuseRequest = ExcuseRequestsUtils.originalToStyled([
      viewedExcuseRequest,
    ]);
    setRenderedExcuseRequest(_renderedExcuseRequest[0]);
  }, [viewedExcuseRequest]);

  // Controlling dialog buttons depending on the current table
  const dialogActions = (
    <>
      <div>
        {getRoleInitialPendingStatuses(Constants.userData.role.name).includes(
          viewedExcuseRequest.status.id,
        ) && (
          <button
            className="ms-2 mb-2 btn btn-danger"
            onClick={() => setOpenDeleteRequestDialog(true)}
          >
            Delete Request
          </button>
        )}
      </div>
      <div className="ms-auto me-2">
        <Button onClick={handleClose}>Close</Button>
      </div>
    </>
  );
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedExcuseRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <ExcusesViewDeleteModal
              openApproveRequestDialog={openDeleteRequestDialog}
              setOpenDeleteRequestDialog={setOpenDeleteRequestDialog}
              viewedExcuseRequest={viewedExcuseRequest}
            />
            <DialogTitle>Excuse Request Details</DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText
                  primary={<>Request ID : {renderedExcuseRequest.id}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Date : {renderedExcuseRequest.date}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Reason : {renderedExcuseRequest.reason}</>}
                />
              </ListItem>
              <ListItem>
                <div>Status :</div>
                <span
                  className={`
						ms-2 badge
						${renderedExcuseRequest.status.style.bg}
						${renderedExcuseRequest.status.style.text}
					`}
                >
                  {renderedExcuseRequest.status.name}
                </span>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Management Notes : {renderedExcuseRequest.comment || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated At : {renderedExcuseRequest.updated_at || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated By : {renderedExcuseRequest.updated_by || ''}
                    </>
                  }
                />
              </ListItem>
            </List>

            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
