import dayjs from 'dayjs';

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>//
// >>>>>>>>> Date Format conversion to match calender library date >>>>>>>>//
export const isoToLong = (date) => {
  return new Date(date);
};

export const longToIso = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const originalToRendered = (originalFormat) => {
  if (originalFormat) {
    const renderedFormat = originalFormat.map((element) => {
      return {
        ...element,
        startDate: isoToLong(element.start_date),
        endDate: isoToLong(element.end_date),
      };
    });
    return renderedFormat;
  }
};

export const renderedToOriginal = (renderedFormat) => {
  if (renderedFormat) {
    const originalFormat = renderedFormat.map((element) => {
      return {
        ...element,
        start_date: longToIso(element.start_date),
        end_date: longToIso(element.end_date),
      };
    });
    return originalFormat;
  }
};

export const createEmptyOriginalObj = () => {
  return {
    id: 0,
    name: '',
    start_date: '',
    end_date: '',
  };
};

export const createFormData = (holiday) => {
  return {
    name: holiday.name,
    start_date: holiday.start_date,
    end_date: holiday.end_date,
  };
};

// <<<<<<<<< Date Format conversion to match calender library date <<<<<<<<//
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

export const setCurrentHoliday = (
  currentHoliday,
  property,
  value,
  searchedArray,
) => {
  let updatedHoliday = {};

  switch (property) {
    case 'date':
      updatedHoliday = updateHolidayDate(currentHoliday, value);
      break;

    default:
      updatedHoliday = updateHolidayProperty(currentHoliday, property, value);
  }
  return updatedHoliday;
};

const updateHolidayDate = (currentHoliday, date) => {
  return {
    ...currentHoliday,
    date: longToIso(new Date(date)),
  };
};

const updateHolidayProperty = (currentHoliday, property, value) => {
  return {
    ...currentHoliday,
    [property]: value,
  };
};
