import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as Requests from '../../../Helpers/Requests';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';
import { Pages } from '../../../Helpers/Constants';

export default function RecurringPaymentsManageDelete({
  onClose,
  selectedValue,
  open,
  renderedRecurringPaymentRequest,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const [requestLoading, setRequestLoading] = React.useState(false);

  const handleDeleteRecurringPayment = (e) => {
    setRequestLoading(true);
    e.preventDefault();

    Requests.deleteRecurringPayment(
      renderedRecurringPaymentRequest.user_id,
      renderedRecurringPaymentRequest.id,
    )
      .then(() => {
        toast('Recurring Payment was deleted successfully.', {
          type: 'success',
        });
        setTimeout(() => {
          window.location.replace(Pages.RecurringPaymentsManage.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form
          onSubmit={handleDeleteRecurringPayment}
          style={{ width: '500px' }}
        >
          <DialogTitle>Delete Recurring Payment Request</DialogTitle>
          <DialogContent dividers>
            <List sx={{ pt: 0 }}>
              <div className="my-2">
                <div>
                  Are you sure you want to delete this Recurring Payment?
                </div>
                <b> This action can not be undone.</b>
              </div>
            </List>
          </DialogContent>
          <DialogActions className="d-flex justify-content-between mx-2">
            <button className="btn btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              disabled={requestLoading}
            >
              Confirm Delete
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
