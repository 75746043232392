const IdCellIndex = 0;

export const StatusUpdatesTableCols = {
  ID: { Name: 'id', Label: 'ID' },
  User: { Name: 'user', Label: 'User' },
  start_date: { Name: 'start_date', Label: 'Start Date', Width: 18 },
  end_date: { Name: 'end_date', Label: 'End Date', Width: 18 },
  RoleName: { Name: 'role_name', Label: 'Role Name' },
  Team: {
    Name: 'team',
    Label: 'Team',
    Width: 11.5,
    // Options: TEAMS,
  },
  HourRate: { Name: 'hour_rate', Label: 'Hour Rate', Type: 'number' },
  PlanswiftDivisionName: {
    Name: 'planswift_division_name',
    Label: 'Estimating Division Name',
  },
  PlanswiftSkillStatus: {
    Name: 'planswift_skill_status',
    Label: 'Estimating Skill Status',
  },
  PlanswiftSeniority: {
    Name: 'planswift_seniority',
    Label: 'Estimating Seniority',
  },
  PlanswiftGroup: { Name: 'planswift_group', Label: 'Estimating Group' },
  RevitGroup: { Name: 'revit_group', Label: 'Revit Group' },
  Notes: { Name: 'notes', Label: 'Notes' },
};
export const TextCols = [
  StatusUpdatesTableCols.RoleName,
  StatusUpdatesTableCols.HourRate,
  StatusUpdatesTableCols.PlanswiftDivisionName,
  StatusUpdatesTableCols.PlanswiftSkillStatus,
  StatusUpdatesTableCols.PlanswiftSeniority,
  StatusUpdatesTableCols.PlanswiftGroup,
  StatusUpdatesTableCols.RevitGroup,
  StatusUpdatesTableCols.Notes,
];

export const DateCols = [
  StatusUpdatesTableCols.start_date,
  StatusUpdatesTableCols.end_date,
];

export const DropDownCols = [StatusUpdatesTableCols.Team];

export const getTableCols = () => {
  const tableCols = [
    {
      colDetails: StatusUpdatesTableCols.ID,
      isFilterable: false,
      isSortable: false,
      isShown: false,
    },
    {
      colDetails: StatusUpdatesTableCols.User,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: StatusUpdatesTableCols.start_date,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: StatusUpdatesTableCols.end_date,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: StatusUpdatesTableCols.RoleName,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: StatusUpdatesTableCols.Team,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: StatusUpdatesTableCols.HourRate,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: StatusUpdatesTableCols.PlanswiftDivisionName,
      isFilterable: true,
      isSortable: true,
      isShown: false,
    },
    {
      colDetails: StatusUpdatesTableCols.PlanswiftSkillStatus,
      isFilterable: true,
      isSortable: true,
      isShown: false,
    },
    {
      colDetails: StatusUpdatesTableCols.PlanswiftSeniority,
      isFilterable: true,
      isSortable: true,
      isShown: false,
    },
    {
      colDetails: StatusUpdatesTableCols.PlanswiftGroup,
      isFilterable: true,
      isSortable: true,
      isShown: false,
    },
    {
      colDetails: StatusUpdatesTableCols.RevitGroup,
      isFilterable: true,
      isSortable: true,
      isShown: false,
    },
    {
      colDetails: StatusUpdatesTableCols.Notes,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
  ];

  return createTableCols(tableCols);
};

const createTableCols = (tableCols) => {
  return tableCols.map((tableCol) => {
    return createTableCol(...Object.values(tableCol));
  });
};

const createTableCol = (colDetails, isFilterable, isSortable, isShown) => {
  return {
    name: colDetails.Name,
    label: colDetails.Label,
    options: {
      filter: isFilterable,
      sort: isSortable,
      display: isShown,
    },
  };
};

export const getTableOptions = (handleRowClick) => {
  return {
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'multiselect',
    downloadOptions: {
      filename: 'paragons-status-updates.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onRowClick: (row) => {
      handleRowClick(row[IdCellIndex]);
    },
    responsive: 'standard',
    setRowProps: () => ({
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    }),
  };
};

export const extractShownStatusUpdateData = (statusUpdateData) => {
  return {
    ...statusUpdateData,
    [StatusUpdatesTableCols.Team.Name]: statusUpdateData[
      StatusUpdatesTableCols.Team.Name
    ]
      ? statusUpdateData[StatusUpdatesTableCols.Team.Name].name
      : null,
    [StatusUpdatesTableCols.User.Name]: statusUpdateData[
      StatusUpdatesTableCols.User.Name
    ]
      ? statusUpdateData[StatusUpdatesTableCols.User.Name].name
      : null,
  };
};

export const formatStatusUpdatesData = (statusUpdatesData) => {
  return statusUpdatesData.map((statusUpdate) => {
    const formattedStatusUpdate = { ...statusUpdate };
    formattedStatusUpdate[StatusUpdatesTableCols.User.Name] =
      statusUpdate[StatusUpdatesTableCols.User.Name].name;
    formattedStatusUpdate[StatusUpdatesTableCols.Team.Name] =
      statusUpdate[StatusUpdatesTableCols.Team.Name].name;
    return formattedStatusUpdate;
  });
};
