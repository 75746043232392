import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DialogContent, MenuItem, TextField } from '@mui/material';
import dayjs from 'dayjs';
import ConfirmationModal from './confirmationModal';
import {
  getMonthMidDay,
  handleRequestErrors,
  setMonthMidDay,
} from '../../../Helpers/Requests';
import { toast } from 'react-toastify';
import { Pages } from '../../../Helpers/Constants';

export default function PaymentSettingPage() {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [dayUpdated, setDayUpdated] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);

  const [formValues, setFormValues] = useState({
    month: dayjs().startOf('month'),
    month_mid_day: '',
  });

  useEffect(() => {
    setDayUpdated(false);

    getMonthMidDay(formValues.month.format('YYYY-MM'))
      .then((day) => {
        setDayUpdated(true);

        setFormValues({ ...formValues, month_mid_day: day });
      })
      .catch((err) => handleRequestErrors(err));
  }, [formValues.month]);

  const handleSubmit = () => {
    setRequestLoading(true);

    const formData = {
      ...formValues,
      month: formValues.month.format('YYYY-MM'),
    };

    setMonthMidDay(formData)
      .then(() => {
        toast('Month mid day has been set successfully.', { type: 'success' });
        setTimeout(() => {
          window.location.replace(Pages.PaymentSetting.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const confirmationDialogContent = (
    <DialogContent dividers>
      <div>
        Are you sure you want to Update the mid day
        <br />
        of Month <b>{dayjs(formValues.month).format('YYYY-MM')}</b> to be day{' '}
        <b>{formValues.month_mid_day}</b> ?
      </div>
    </DialogContent>
  );

  return (
    <>
      <ConfirmationModal
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        requestLoading={requestLoading}
        actionHandler={handleSubmit}
        dialogTitle={'Update Month Mid Day'}
        dialogContent={confirmationDialogContent}
      />
      <div className="container my-4">
        <div style={{ maxWidth: '65rem' }}>
          <h3 className="mb-4 font-weight-normal">Payment Setting</h3>
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="card-title mb-0">
                <i className="fa fa-edit"></i> Set Invoices Month Mid Day
              </h5>
            </div>
            <div className="card-body mx-2">
              <div className="row mb-3">
                <div className="form-group col-lg-4 col-md-6">
                  <div className="mb-2">Select the desired Month</div>
                  <DatePicker
                    views={['year', 'month']}
                    openTo="month"
                    maxDate={dayjs().startOf('month')}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    value={formValues.month}
                    onChange={(date) =>
                      setFormValues({
                        ...formValues,
                        month: dayjs(date).startOf('month'),
                      })
                    }
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <div className="mb-2">Set Month Mid Day</div>
                  {dayUpdated && (
                    <TextField
                      select
                      fullWidth
                      value={formValues.month_mid_day}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          month_mid_day: e.target.value,
                        })
                      }
                    >
                      {[...Array(11).keys()]
                        .map((i) => i + 10)
                        .map((day) => {
                          return (
                            <MenuItem key={day} value={day}>
                              {day}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  )}
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <div className="mb-2">
                    <span style={{ visibility: 'hidden' }}>Space Holder</span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Set Month Mid Day"
                      style={{ width: '12rem' }}
                      className="btn btn-warning my-3"
                      onClick={() => setOpenConfirmationDialog(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
