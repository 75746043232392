import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import * as Requests from '../../../Helpers/Requests';
import { Pages } from '../../../Helpers/Constants';
import { toast } from 'react-toastify';

export default function PaymentAdjustmentManageReject({
  onClose,
  selectedValue,
  open,
  renderedPaymentAdjustmentRequest,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [editRequestFormValues, setEditRequestFormValues] = React.useState({
    ...renderedPaymentAdjustmentRequest,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditRequestFormValues({
      ...editRequestFormValues,
      [name]: value,
    });
  };

  const handleEditRequestSubmit = (e) => {
    setRequestLoading(true);

    e.preventDefault();

    const formData = { ...editRequestFormValues };

    Requests.formRequest(
      `paymentAdjustments/${renderedPaymentAdjustmentRequest.id}/refuse`,
      'put',
      formData,
    )
      .then(() => {
        toast('One Time Payment Rejected successfully.', { type: 'success' });
        setTimeout(() => {
          window.location.replace(Pages.PaymentAdjustmentManage.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  // Creating form fields functions.
  const createFormFields = (labelData, nameData, typeData, valueData) => {
    return {
      label: labelData,
      name: nameData,
      type: typeData,
      value: valueData,
    };
  };

  const fields = [
    createFormFields(
      'Rejection Notes',
      'comment',
      'text',
      renderedPaymentAdjustmentRequest?.comment,
    ),
  ];
  // Creating form fields functions.

  return (
    <Dialog onClose={handleClose} open={open}>
      <form onSubmit={handleEditRequestSubmit} style={{ width: '500px' }}>
        <DialogTitle>Reject One Time Payments Request</DialogTitle>
        <List sx={{ pt: 0 }}>
          <DialogContent>
            {fields.map((field) => (
              <TextField
                key={field.name}
                label={field.label}
                name={field.name}
                defaultValue={field.value}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                margin="dense"
              />
            ))}
          </DialogContent>
        </List>
        <DialogActions className="d-flex justify-content-between mx-2">
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
          <button
            type="submit"
            className="btn btn-danger"
            disabled={requestLoading}
          >
            Confirm Rejection
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
