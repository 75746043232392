import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as Helpers from '../../../../Helpers/Helpers';

import NursingScheduleManageApprove from './NursingScheduleManageApprove';

import * as Constants from '../../../../Helpers/Constants';

import * as NursingScheduleManageUtils from './NursingScheduleManageUtils';

import NursingScheduleManageReject from './NursingScheduleManageReject';
import NursingScheduleManageDelete from './NursingScheduleManageDelete';

export default function NursingScheduleManageShow({
  onClose,
  selectedValue,
  open,
  managedNursingScheduleRequest,
  currentTable,
}) {
  const [renderedNursingScheduleRequest, setRenderedNursingScheduleRequest] =
    React.useState({});

  const [openApproveRequestDialog, setOpenApproveRequestDialog] =
    React.useState(false);

  const [openRejectRequestDialog, setOpenRejectRequestDialog] =
    React.useState(false);

  const [openDeleteRequestDialog, setOpenDeleteRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Approve Request dialog //
  const handleApproveRequestOpen = () => setOpenApproveRequestDialog(true);
  const handleApproveRequestClose = () => setOpenApproveRequestDialog(false);
  // Approve Request dialog //

  // Reject Request dialog //
  const handleRejectRequestOpen = () => setOpenRejectRequestDialog(true);
  const handleRejectRequestClose = () => setOpenRejectRequestDialog(false);
  // Reject Request dialog //

  // Delete Request dialog //
  const handleDeleteRequestOpen = () => setOpenDeleteRequestDialog(true);
  const handleDeleteRequestClose = () => setOpenDeleteRequestDialog(false);
  // Delete Request dialog //

  // Component did update
  React.useEffect(() => {
    // Update nursingSchedule request.
    const _renderedNursingScheduleRequest =
      NursingScheduleManageUtils.originalToStyled([
        managedNursingScheduleRequest,
      ]);
    setRenderedNursingScheduleRequest(_renderedNursingScheduleRequest[0]);
  }, [managedNursingScheduleRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === Constants.TablesTypes.Pending) {
    dialogActions = (
      <>
        <div>
          {Helpers.can([Constants.PERMISSIONS.ApproveNursingSchedule]) && (
            <button
              className="btn btn-danger ms-2"
              onClick={() => handleRejectRequestOpen()}
            >
              Reject
            </button>
          )}
        </div>
        <div>
          <Button onClick={handleClose}>Close</Button>

          {Helpers.can([Constants.PERMISSIONS.ApproveNursingSchedule]) && (
            <button
              className="btn btn-success ms-2 me-2 mb-2"
              onClick={() => handleApproveRequestOpen()}
            >
              Approve
            </button>
          )}
        </div>
      </>
    );
  } else if (currentTable.type === Constants.TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="ms-auto me-2">
          <Button onClick={handleClose}>Close</Button>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedNursingScheduleRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <NursingScheduleManageApprove
              open={openApproveRequestDialog}
              onClose={handleApproveRequestClose}
              renderedNursingScheduleRequest={renderedNursingScheduleRequest}
            />
            <NursingScheduleManageReject
              open={openRejectRequestDialog}
              onClose={handleRejectRequestClose}
              renderedNursingScheduleRequest={renderedNursingScheduleRequest}
            />
            <NursingScheduleManageDelete
              open={openDeleteRequestDialog}
              onClose={handleDeleteRequestClose}
              renderedNursingScheduleRequest={renderedNursingScheduleRequest}
            />
            <DialogTitle>Child Care Request Details</DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>Request ID : {renderedNursingScheduleRequest.id}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>User Name : {renderedNursingScheduleRequest.user}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Email : {renderedNursingScheduleRequest.email}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Start Date : {renderedNursingScheduleRequest.start_date}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>End Date : {renderedNursingScheduleRequest.end_date}</>
                  }
                />
              </ListItem>
              {renderedNursingScheduleRequest.comment && (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Management Notes :{' '}
                        {renderedNursingScheduleRequest.comment || ''}
                      </>
                    }
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated At :{' '}
                      {renderedNursingScheduleRequest.updated_at || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <span>
                      Last Updated By :{' '}
                      {renderedNursingScheduleRequest.updated_by || ''}
                    </span>
                  }
                />
              </ListItem>
            </List>
            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
