import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import {
  DocumentCols,
  ExpiryCols,
  getColLabels,
  getExpiryRemainingDays,
  isDocumentExpiringSoon,
  isPhotosCountComplete,
  RequiredPhotosCount,
  UsersTableCols,
} from '.';
import { handleViewFile } from '../../../Helpers/Helpers';

export const renderListItem = ([key, value]) => {
  const formattedUserData = { [key]: value };

  if (value) {
    if (getColLabels(DocumentCols).includes(key)) {
      return (
        <ListItem key={key} style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={`${key} : `}
            style={{ flex: '1', marginRight: '0.5rem' }}
          />
          <button
            className="btn btn-link p-0"
            value={formattedUserData[key]}
            onClick={(e) => handleViewFile(e.target.value)}
          >
            View Document
          </button>
        </ListItem>
      );
    } else if (getColLabels(ExpiryCols).includes(key)) {
      const expiryRemainingDays = getExpiryRemainingDays(
        formattedUserData[key],
      );
      const BadgeClass = isDocumentExpiringSoon(expiryRemainingDays)
        ? 'bg-danger'
        : 'bg-success';
      return (
        <ListItem key={key} style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={`${key} : `}
            style={{ flex: '1', marginRight: '0.5rem' }}
          />
          <div
            className={`badge ${BadgeClass}`}
            style={{ padding: '0.30rem 0.5rem' }}
          >{`${expiryRemainingDays} Days Remaining`}</div>
        </ListItem>
      );
    } else if (key === UsersTableCols.PersonalPhotoCount.Label) {
      const BadgeClass = !isPhotosCountComplete(formattedUserData[key])
        ? 'bg-danger'
        : 'bg-success';
      const RemainingPhotosCount = RequiredPhotosCount - formattedUserData[key];
      const RemainingPhotosCountString =
        RemainingPhotosCount > 0 ? `( ${RemainingPhotosCount} Remaining )` : ``;
      return (
        <ListItem key={key} style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={`${key} : `}
            style={{ flex: '1', marginRight: '0.5rem' }}
          />
          <div
            className={`badge ${BadgeClass}`}
            style={{ padding: '0.30rem 0.5rem' }}
          >{`${formattedUserData[key]} Photos ${RemainingPhotosCountString}`}</div>
        </ListItem>
      );
    }
  }

  // Default case
  return (
    <ListItem key={key} style={{ display: 'flex', alignItems: 'center' }}>
      <ListItemText
        primary={`${key} : `}
        style={{ flex: '1', marginRight: '0.5rem' }}
      />
      {formattedUserData[key] ? (
        <div style={{ fontWeight: 500 }}>{`${formattedUserData[key]}`}</div>
      ) : (
        <div
          className={`badge bg-secondary`}
          style={{ padding: '0.30rem 0.5rem' }}
        >
          Unspecified
        </div>
      )}
    </ListItem>
  );
};
