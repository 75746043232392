import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as Helpers from '../../../Helpers/Helpers';

import PaymentAdjustmentManageApprove from './PaymentAdjustmentManageApprove';

import * as Constants from '../../../Helpers/Constants';

import * as PaymentAdjustmentManageUtils from './PaymentAdjustmentManageUtils';

import PaymentAdjustmentManageReject from './PaymentAdjustmentManageReject';

export default function PaymentAdjustmentManageShow({
  onClose,
  selectedValue,
  open,
  managedPaymentAdjustmentRequest,
  currentTable,
}) {
  const [
    renderedPaymentAdjustmentRequest,
    setRenderedPaymentAdjustmentRequest,
  ] = React.useState({});

  const [openApproveRequestDialog, setOpenApproveRequestDialog] =
    React.useState(false);

  const [openRejectRequestDialog, setOpenRejectRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Approve Request dialog //
  const handleApproveRequestOpen = () => setOpenApproveRequestDialog(true);
  const handleApproveRequestClose = () => setOpenApproveRequestDialog(false);
  // Approve Request dialog //

  // Reject Request dialog //
  const handleRejectRequestOpen = () => setOpenRejectRequestDialog(true);
  const handleRejectRequestClose = () => setOpenRejectRequestDialog(false);
  // Reject Request dialog //

  // Component did update
  React.useEffect(() => {
    // Update paymentAdjustment request.
    const _renderedPaymentAdjustmentRequest =
      PaymentAdjustmentManageUtils.originalToStyled([
        managedPaymentAdjustmentRequest,
      ]);
    setRenderedPaymentAdjustmentRequest(_renderedPaymentAdjustmentRequest[0]);
  }, [managedPaymentAdjustmentRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === Constants.TablesTypes.Pending) {
    dialogActions = (
      <>
        <div>
          {Helpers.can([Constants.PERMISSIONS.RefusePaymentAdjustment]) && (
            <button
              className="btn btn-danger ms-2"
              onClick={() => handleRejectRequestOpen()}
            >
              Reject
            </button>
          )}
        </div>
        <div>
          <Button onClick={handleClose}>Close</Button>

          {Helpers.can([Constants.PERMISSIONS.ApprovePaymentAdjustment]) && (
            <button
              className="btn btn-success ms-2 me-2 mb-2"
              onClick={() => handleApproveRequestOpen()}
            >
              Approve
            </button>
          )}
        </div>
      </>
    );
  } else if (currentTable.type === Constants.TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="ms-auto me-2">
          <Button onClick={handleClose}>Close</Button>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedPaymentAdjustmentRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <PaymentAdjustmentManageApprove
              open={openApproveRequestDialog}
              onClose={handleApproveRequestClose}
              renderedPaymentAdjustmentRequest={
                renderedPaymentAdjustmentRequest
              }
            />
            <PaymentAdjustmentManageReject
              open={openRejectRequestDialog}
              onClose={handleRejectRequestClose}
              renderedPaymentAdjustmentRequest={
                renderedPaymentAdjustmentRequest
              }
            />
            <DialogTitle>One Time Payment Request Details</DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>Request ID : {renderedPaymentAdjustmentRequest.id}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Requester :{' '}
                      {renderedPaymentAdjustmentRequest.requester.email}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Issued Employee :{' '}
                      {renderedPaymentAdjustmentRequest.employee.email}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                Type :
                <span
                  className={
                    'ms-2 ' + renderedPaymentAdjustmentRequest.type.class
                  }
                >
                  {renderedPaymentAdjustmentRequest.type.name || ''}
                </span>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Value : {renderedPaymentAdjustmentRequest.value}{' '}
                      <span className="text-muted">
                        {renderedPaymentAdjustmentRequest.type.unit}
                      </span>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>Reason : {renderedPaymentAdjustmentRequest.reason}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>Day : {renderedPaymentAdjustmentRequest.day || ''}</>
                  }
                />
              </ListItem>
              <ListItem>
                <div>Status :</div>
                <span
                  className={`
						ms-2 badge
						${renderedPaymentAdjustmentRequest.status.style.bg}
						${renderedPaymentAdjustmentRequest.status.style.text}
					`}
                >
                  {renderedPaymentAdjustmentRequest.status.name}
                </span>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Management Notes :{' '}
                      {renderedPaymentAdjustmentRequest.comment || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated At :{' '}
                      {renderedPaymentAdjustmentRequest.updated_at || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated By :{' '}
                      {renderedPaymentAdjustmentRequest.updated_by || ''}
                    </>
                  }
                />
              </ListItem>
            </List>

            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
