import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import VacationsManageApprove from './VacationsManageApprove';

import * as Requests from '../../../Helpers/Requests';
import * as Constants from '../../../Helpers/Constants';

import * as VacationRequestsUtils from './VacationsManageUtils';

import VacationsManageReject from './VacationsManageReject';
import { BALANCE_REQUEST_STATUSES, VACATIONTYPES } from '../Constants';
import VacationsManageDelete from './VacationsManageDelete';
import { userHasAnyRole } from '../../../Helpers/Helpers';

export default function VacationsManageShow({
  onClose,
  selectedValue,
  open,
  managedVacationRequest,
  currentTable,
}) {
  const [renderedVacationRequest, setRenderedVacationRequest] = React.useState(
    {},
  );
  const [openDeleteRequestDialog, setOpenDeleteRequestDialog] =
    React.useState(false);

  // user year vacations section.
  const [vacationTypesBalances, setVacationTypesBalances] = React.useState([]);
  const [vacationTypesBalancesLoaded, setVacationTypesBalancesLoaded] =
    React.useState(false);
  // user year vacations section.

  const [openApproveRequestDialog, setOpenApproveRequestDialog] =
    React.useState(false);

  const [openRejectRequestDialog, setOpenRejectRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
    setVacationTypesBalancesLoaded(false);
  };

  // Approve Request dialog //
  const handleApproveRequestOpen = () => setOpenApproveRequestDialog(true);
  const handleApproveRequestClose = () => setOpenApproveRequestDialog(false);
  // Approve Request dialog //

  // Reject Request dialog //
  const handleRejectRequestOpen = () => setOpenRejectRequestDialog(true);
  const handleRejectRequestClose = () => setOpenRejectRequestDialog(false);
  // Reject Request dialog //

  // Component did update
  React.useEffect(() => {
    // Update vacation request.
    const _renderedVacationRequest = VacationRequestsUtils.originalToStyled([
      managedVacationRequest,
    ]);
    setRenderedVacationRequest(_renderedVacationRequest[0]);

    // As business requires, if manager is showing a user request
    // show all user approved and pending as a taken vacations in counts.
    // so it will be more clear for manager to know how many days
    const RequiredStatuses =
      currentTable.type === Constants.TablesTypes.Pending
        ? [BALANCE_REQUEST_STATUSES.Approved, BALANCE_REQUEST_STATUSES.Pending]
        : [BALANCE_REQUEST_STATUSES.Approved];

    Requests.getUserVacationBalances(
      managedVacationRequest.user.id,
      RequiredStatuses,
    )
      .then((_vacationTypesBalances) => {
        // Add request total day to its corresponding type
        // to make it more understandable in the available
        // Balance cards if table type is pending
        if (currentTable.type === Constants.TablesTypes.Pending) {
          _vacationTypesBalances[
            _renderedVacationRequest[0].vacationType.name
          ].remaining = Number(
            (
              _vacationTypesBalances[
                _renderedVacationRequest[0].vacationType.name
              ].remaining + _renderedVacationRequest[0].total_days
            ).toFixed(2),
          );
          // subtract them from used vacations.
          _vacationTypesBalances[
            _renderedVacationRequest[0].vacationType.name
          ].used = Number(
            (
              _vacationTypesBalances[
                _renderedVacationRequest[0].vacationType.name
              ].used - _renderedVacationRequest[0].total_days
            ).toFixed(2),
          );
          // if vacation request type is urgent, do the modification on normal balance too.
          if (
            _renderedVacationRequest[0].vacationType.name ===
            VACATIONTYPES.Urgent.Name
          ) {
            _vacationTypesBalances[VACATIONTYPES.Normal.Name].remaining =
              Number(
                (
                  _vacationTypesBalances[VACATIONTYPES.Normal.Name].remaining +
                  _renderedVacationRequest[0].total_days
                ).toFixed(2),
              );
            _vacationTypesBalances[VACATIONTYPES.Normal.Name].used = Number(
              (
                _vacationTypesBalances[VACATIONTYPES.Normal.Name].used -
                _renderedVacationRequest[0].total_days
              ).toFixed(2),
            );
          }
        }
        setVacationTypesBalances(_vacationTypesBalances);
        setVacationTypesBalancesLoaded(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [managedVacationRequest]);
  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === Constants.TablesTypes.Pending) {
    dialogActions = (
      <>
        <div>
          <button
            className="btn btn-danger ms-2"
            onClick={() => handleRejectRequestOpen()}
          >
            Reject
          </button>
        </div>
        <div>
          <Button onClick={handleClose}>Close</Button>
          <button
            className="btn btn-success ms-2 me-2 mb-2"
            onClick={() => handleApproveRequestOpen()}
          >
            Approve
          </button>
        </div>
      </>
    );
  } else if (currentTable.type === Constants.TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="d-flex justify-content-between w-100">
          <div>
            <>
              {userHasAnyRole(Constants.SystemManagersRoles) && (
                <button
                  className="ms-2 mb-2 btn btn-danger"
                  onClick={() => setOpenDeleteRequestDialog(true)}
                >
                  Delete
                </button>
              )}
            </>
          </div>
          <div>
            <button
              className="ms-auto me-2 mb-2 btn btn-secondary"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedVacationRequest).length > 0 &&
        vacationTypesBalancesLoaded && (
          <>
            <Dialog
              onClose={handleClose}
              open={open}
              maxWidth="sm"
              fullWidth={true}
            >
              <VacationsManageApprove
                open={openApproveRequestDialog}
                onClose={handleApproveRequestClose}
                managedVacationRequest={managedVacationRequest}
              />
              <VacationsManageReject
                open={openRejectRequestDialog}
                onClose={handleRejectRequestClose}
                managedVacationRequest={managedVacationRequest}
              />
              <VacationsManageDelete
                openDeleteRequestDialog={openDeleteRequestDialog}
                setOpenDeleteRequestDialog={setOpenDeleteRequestDialog}
                renderedVacationRequest={renderedVacationRequest}
              />
              <DialogTitle>Vacation Request Details</DialogTitle>
              <DialogTitle className="text-center">
                User Vacations Summary of Year {new Date().getFullYear()}
              </DialogTitle>

              <div className="container-fluid">
                <div className="row mb-4 d-flex justify-content-evenly">
                  <div className="col-lg-3 col-6 text-center">
                    <div
                      className="mx-1 badge"
                      style={{
                        width: '95%',
                        backgroundColor: 'white',
                        color: 'green',
                        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <h5 className="mt-2 mb-3">Annual</h5>
                      <p className="balance-card h6 fw-normal text-dark">
                        Booked
                        {' : '}
                        {vacationTypesBalances[VACATIONTYPES.Normal.Name].used}
                      </p>
                      <p className="balance-card h6 fw-normal text-dark">
                        Available
                        {' : '}
                        {
                          vacationTypesBalances[VACATIONTYPES.Normal.Name]
                            .remaining
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6 text-center">
                    <div
                      className="mx-1 badge text-dark"
                      style={{
                        width: '95%',
                        backgroundColor: 'white',
                        color: '#737373',
                        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <h5 className="mt-2 mb-3">Unpaid</h5>
                      <p className="balance-card h6 fw-normal text-dark">
                        Booked
                        {' : '}
                        {vacationTypesBalances[VACATIONTYPES.Unpaid.Name].used}
                      </p>
                      <p className="balance-card h6 fw-normal text-dark">
                        Available
                        {' : '}
                        {
                          vacationTypesBalances[VACATIONTYPES.Unpaid.Name]
                            .remaining
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <List sx={{ pt: 0 }}>
                <ListItem>
                  <ListItemText
                    primary={<>Request ID : {renderedVacationRequest.id}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>User Name : {renderedVacationRequest.user.name}</>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Email : {renderedVacationRequest.user.email}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>Start Date : {renderedVacationRequest.start_date}</>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>End Date : {renderedVacationRequest.end_date}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Total Days : {renderedVacationRequest.total_days} Days
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <div>Type :</div>
                  <span
                    className={`
						ms-2 badge
						${renderedVacationRequest.vacationType.style.bg}
						${renderedVacationRequest.vacationType.style.text}
					`}
                  >
                    {renderedVacationRequest.vacationType.name}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Reason : {renderedVacationRequest.reason}</>}
                  />
                </ListItem>
                <ListItem>
                  <div>Status :</div>
                  <span
                    className={`
						ms-2 badge
						${renderedVacationRequest.status.style.bg}
						${renderedVacationRequest.status.style.text}
					`}
                  >
                    {renderedVacationRequest.status.name}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Management Notes :{' '}
                        {renderedVacationRequest.comment || ''}
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Last Updated At :{' '}
                        {renderedVacationRequest.updated_at || ''}
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Last Updated By :{' '}
                        {renderedVacationRequest.updated_by || ''}
                      </>
                    }
                  />
                </ListItem>
              </List>

              <DialogActions className="d-flex justify-content-between">
                {dialogActions}
              </DialogActions>
            </Dialog>
          </>
        )}
    </>
  );
}
