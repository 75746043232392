import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import {
  getManagedRequestsTables,
  TablesTypes,
} from '../../../Helpers/Constants';
import MenuItem from '@mui/material/MenuItem';
import AttendancesTable from './components/table';
import { getTableById, getTableByType } from '../../../Helpers/Helpers';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function AttendancesListPage() {
  const [attendances, setAttendances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCheckedIn, setIsCheckedIn] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        'http://localhost:8000/api/attendances',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`, // Add the Bearer token to the Authorization header
          },
        },
      );
      setAttendances(response.data.data); // Update the state with the data array
      // Determine if the user is currently checked in
      const latestAttendance = response.data.data.find((attendance) => !attendance.check_out_datetime);
      setIsCheckedIn(!!latestAttendance);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Define the columns for the table
  const columns = [
    {
      name: 'check_in_datetime',
      label: 'Check-In Time',
    },
    {
      name: 'check_in_ip',
      label: 'Check-In IP',
    },
    {
      name: 'check_in_location',
      label: 'Check-In Location',
    },
    {
      name: 'check_out_datetime',
      label: 'Check-Out Time',
      options: {
        customBodyRender: (value) => value || 'N/A', // Render "N/A" if check_out_datetime is null
      },
    },
    {
      name: 'check_out_ip',
      label: 'Check-Out IP',
      options: {
        customBodyRender: (value) => value || 'N/A', // Render "N/A" if check_out_ip is null
      },
    },
    {
      name: 'check_out_location',
      label: 'Check-Out Location',
      options: {
        customBodyRender: (value) => value || 'N/A', // Render "N/A" if check_out_location is null
      },
    },
    {
      name: 'user',
      label: 'User ID',
    },
  ];

  // Define options for the MUI DataTable
  const options = {
    filter: true,
    sort: true,
    selectableRows: 'none', // Disable row selection
    responsive: 'standard',
    download: true,
    print: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    textLabels: {
      body: {
        noMatch: loading ? 'Loading...' : 'No data available',
      },
    },
  };

  const handleCheckIn = async () => {
    const token = localStorage.getItem('userToken'); // Retrieve the token from local storage
    const checkInData = {
      location: 'Office - HQ',
    };

    try {
      const response = await axios.post(
        'http://localhost:8000/api/attendances/check-in',
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Bearer token to the Authorization header
            'Content-Type': 'application/json',
          },
        },
      );

      console.log('Check-In Successful:', response.data);

      await fetchData(); // Refresh the attendance data after a successful check-in
    } catch (error) {
      console.error('Error during check-in:', error);
      alert('Check-in failed!');
    }
  };

  const handleCheckOut = async () => {
    const token = localStorage.getItem('userToken'); // Retrieve the token from local storage
    const checkOutData = {
      location: 'Office - HQ',
    };

    try {
      const response = await axios.post(
        'http://localhost:8000/api/attendances/check-out',
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Bearer token to the Authorization header
            'Content-Type': 'application/json',
          },
        },
      );

      console.log('Check-Out Successful:', response.data);
      setIsCheckedIn(false); // Set checked-out state
      fetchData(); // Refresh the attendance data after a successful check-out
    } catch (error) {
      console.error('Error during check-out:', error);
      alert('Check-out failed!');
    }
  };

  return (
    <div>
      <h1>Attendances List</h1>
      <Box display="flex" gap={2} marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCheckIn}
          disabled={isCheckedIn} // Disable when user is already checked in
        >
          Check In
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCheckOut}
          disabled={!isCheckedIn} // Disable when user is not checked in
        >
          Check Out
        </Button>
      </Box>
      <MUIDataTable
        title="Attendances"
        data={attendances}
        columns={columns}
        options={options}
      />
    </div>
  );
}

// export default function AttendancesListPage() {
//   const [currentTable, setCurrentTable] = React.useState('');
//
//   React.useEffect(() => {
//     setCurrentTable(getTableByType(TablesTypes.Pending, 'Loans'));
//   }, []);
//
//   const handleTableChange = (event) => {
//     const selectedTableId = event.target.value;
//     setCurrentTable(getTableById(selectedTableId, 'Loans'));
//   };
//
//   return (
//     <>
//       {Object.keys(currentTable).length > 0 && (
//         <>
//           <FormControl
//             variant="standard"
//             sx={{ m: 1, minWidth: 120 }}
//             className="mb-4"
//           >
//             <InputLabel id="demo-simple-select-standard-label">
//               Currently Viewing
//             </InputLabel>
//             <Select
//               labelId="demo-simple-select-standard-label"
//               id="demo-simple-select-standard"
//               value={currentTable.id}
//               onChange={handleTableChange}
//               defaultValue={getTableByType(TablesTypes.Pending, 'Loans')}
//             >
//               {getManagedRequestsTables('Loans').map((table) => {
//                 return (
//                   <MenuItem key={table.id} value={table.id}>
//                     {table.selectName}
//                   </MenuItem>
//                 );
//               })}
//             </Select>
//           </FormControl>
//
//           <AttendancesTable currentTable={currentTable} />
//         </>
//       )}
//     </>
//   );
// }
