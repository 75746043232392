import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVacationsData } from '../../../store/slices/vacationsSlice';

export default function VacationsBalanceTable({
  vacationType,
  year,
  currentlyBooked,
  noBalance,
  setNoBalance,
  user,
}) {
  const vacations = useSelector((state) => state.vacations);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [balanceAfterBookedLeave, setBalanceAfterBookedLeave] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVacationsData({ user, year }));
  }, [dispatch, user, year]);

  useEffect(() => {
    const remainingBalance = vacations[vacationType].remaining;
    setAvailableBalance(remainingBalance);
    setBalanceAfterBookedLeave(
      Number((remainingBalance - currentlyBooked).toFixed(2)),
    );
  }, [vacations, vacationType]);

  useEffect(() => {
    if (!vacations.loading) {
      if (availableBalance > -0.5 || currentlyBooked > 0) {
        setBalanceAfterBookedLeave(
          Number((availableBalance - currentlyBooked).toFixed(2)),
        );
      } else {
        setBalanceAfterBookedLeave(0);
        setNoBalance(false);
      }
    }
  }, [availableBalance, currentlyBooked]);

  useEffect(() => {
    setNoBalance(balanceAfterBookedLeave < -0.5 && currentlyBooked > 0);
  }, [balanceAfterBookedLeave]);

  /* ******************************************************************** **/
  function createData(name, days) {
    return { name, days };
  }

  const rows = [
    createData('Available balance', vacations[vacationType].remaining),
    createData('Currently Booked', currentlyBooked),
    createData('Balance after booked leave', balanceAfterBookedLeave),
  ];

  return (
    <div className="position-relative">
      <TableContainer
        component={Paper}
        sx={{ maxWidth: 300, minHeight: 210 }}
        style={{ backgroundColor: '#f5f0f0', zIndex: 9999 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontWeight: 900 }}
              >{`As of year: ${year}`}</TableCell>
              <TableCell style={{ fontWeight: 900 }} align="right">
                Day&nbsp;(s)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ position: 'relative' }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: balanceAfterBookedLeave < -0.5 ? 'red' : 'inherit',
                  }}
                >
                  {row.days}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        color="red"
        style={{
          position: 'absolute',
          bottom: noBalance ? '-5rem' : '0rem',
          background: '#ce193b',
          padding: '2px 8px',
          borderRadius: '0px 0px 24px 24px',
          fontSize: '13px',
          color: 'white',
          width: '100%',
          height: '5rem',
          zIndex: '-1',
          transition: 'bottom 0.5s ease',
        }}
      >
        Current vacation balance is not sufficient to cover the requested
        amount. Please review balance and adjust the request accordingly.
      </div>
    </div>
  );
}
