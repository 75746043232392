import React from 'react';

import * as Requests from '../../../../Helpers/Requests';
import * as Helpers from '../../../../Helpers/Helpers';
import * as PayrollViewUtils from './utils';
import MUIDataTable from 'mui-datatables';
import { Button, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import styled from 'styled-components';

const HideChipCloseStyledDiv = styled.div`
  .MuiChip-deleteIcon {
    display: none;
  }
  .MuiChip-root {
    margin-right: 0.3rem;
  }
  [class*='MuiTableCell-root'] {
    padding: 10px;
  }
`;

export default function CurrentPayrollTable() {
  // Requests data variables
  const [originalPayrollView, setOriginalPayrollView] = React.useState([]);
  const [datatablePayrollView, setDatatablePayrollView] = React.useState([]);
  // Requests data variables

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [currentFilterFields, setCurrentFilterFields] = React.useState({
    month: dayjs().startOf('month').format('YYYY-MM'),
    half: dayjs().date() <= 15 ? 'first' : 'second',
  });
  // Table effects variables

  // Mui datatables variables
  const options = {
    pagination: false,
    selectableRows: 'none',
    count: 100,
    page: 0,
    rowsPerPage: 100,
    rowsPerPageOptions: [100],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: true,
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
    // controlling download content
    onDownload: (_buildHead, _buildBody, _columns, _data) => {
      Requests.getSelfPayroll(currentFilterFields)
        .then((report) => {
          const tableData = report.data;
          const printableTableData =
            PayrollViewUtils.originalToTransposed(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'My_Payroll.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    // setting Head cell style
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      style.fontWeight = 'bold';
      style.textTransform = 'uppercase';
      return style;
    },
  };
  // Mui datatables

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    setOriginalPayrollView([]);
    setIsLoading(true);

    Requests.getSelfPayroll(currentFilterFields)
      .then((report) => {
        setOriginalPayrollView(report.data);
        setCurrentColumns(
          PayrollViewUtils.getDatatableColumns(currentFilterFields),
        );
      })
      .catch((err) => Requests.handleRequestErrors(err))
      .finally(() => setIsLoading(false));
  }, [currentFilterFields]);
  // Component did mount.

  // update styled and datatable requests whenever fetched requests change
  React.useEffect(() => {
    const _datatablePayrollView =
      PayrollViewUtils.originalToTransposed(originalPayrollView);
    setDatatablePayrollView(_datatablePayrollView);
  }, [originalPayrollView]);
  // update styled and datatable requests whenever fetched requests change

  const handleFilterSubmit = (applyNewFilters) => {
    const filterFields = PayrollViewUtils.createFilterFields(
      { columns: currentColumns },
      applyNewFilters,
    );

    setCurrentFilterFields(filterFields);
  };

  return (
    <div className="d-flex justify-content-center">
      <div style={{ width: '35vw' }}>
        <HideChipCloseStyledDiv>
          <MUIDataTable
            title={'Recent Payrolls Details'}
            data={datatablePayrollView}
            columns={currentColumns}
            options={options}
          />
        </HideChipCloseStyledDiv>
      </div>
    </div>
  );
}
