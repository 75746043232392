(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("survey-core"));
	else if(typeof define === 'function' && define.amd)
		define("survey-react-ui", ["react", "react-dom", "survey-core"], factory);
	else if(typeof exports === 'object')
		exports["survey-react-ui"] = factory(require("react"), require("react-dom"), require("survey-core"));
	else
		root["SurveyReact"] = factory(root["React"], root["ReactDOM"], root["Survey"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE_survey_core__) {
return 