import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as VacationsViewUtils from './DashboardUtils.jsx';
import * as Constants from '../../Helpers/Constants';
import { getRoleInitialPendingStatuses } from '../Vacations/Constants.jsx';

export default function DashboardViewModal(props) {
  return (
    <Modal
      className="h-100 d-flex align-items-center justify-content-center"
      style={{ zIndex: '9999' }}
      show={props.showModal === 'view'}
      onHide={() => props.setShowModal(null)}
    >
      <div style={{ minWidth: '400px', maxWidth: '500px', width: '40vw' }}>
        <Modal.Header>
          <Modal.Title>View Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-horizontal">
            {VacationsViewUtils.getViewModalRowsData(props.currentVacation).map(
              (vacation) => {
                return (
                  <div className="form-group row" key={vacation.id}>
                    <label className="col-sm-3 mb-3 control-label">
                      {vacation.label}
                    </label>
                    <div className="col-sm-9">{vacation.content}</div>
                  </div>
                );
              },
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <div>
              {getRoleInitialPendingStatuses(
                Constants.userData.role.name,
              ).includes(props.currentVacation.status.id) && (
                <Button
                  variant="danger"
                  onClick={() => props.setShowModal('delete')}
                >
                  Delete Request
                </Button>
              )}
            </div>
            <div>
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => props.setShowModal(null)}
              >
                Close
              </Button>
              {/* {INITIAL_PENDING_STATUSES_VACATIONS.includes(
                props.currentVacation.status.id
              ) && (
                <Button
                  variant="warning"
                  onClick={() => props.setShowModal("edit")}
                >
                  Edit Request
                </Button>
              )} */}
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
