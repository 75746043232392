import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import * as Constants from '../../../Helpers/Constants';

import ExcuseRequestsTable from './ExcusesManageTable';
import { getTableById, getTableByType } from '../../../Helpers/Helpers';

export default function ExcusesManageMain() {
  const [currentTable, setCurrentTable] = React.useState('');

  React.useEffect(() => {
    setCurrentTable(getTableByType(Constants.TablesTypes.Pending, 'Excuse'));
  }, []);

  const handleTableChange = (event) => {
    const selectedTableId = event.target.value;
    setCurrentTable(getTableById(selectedTableId, 'Excuse'));
  };

  return (
    <>
      {Object.keys(currentTable).length > 0 && (
        <>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 120 }}
            className="mb-4"
          >
            <InputLabel id="demo-simple-select-standard-label">
              Currently Viewing
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={currentTable.id}
              onChange={handleTableChange}
              defaultValue={getTableByType(
                Constants.TablesTypes.Pending,
                'Excuse',
              )}
            >
              {Constants.getManagedRequestsTables('Excuse').map((table) => {
                return (
                  <MenuItem key={table.id} value={table.id}>
                    {table.selectName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <ExcuseRequestsTable currentTable={currentTable} />
        </>
      )}
    </>
  );
}
