import React from 'react';
import { Button } from '@mui/material';

import MUIDataTable from 'mui-datatables';
import {
  createFilterFields,
  getDatatableColumns,
  originalToPrintable,
  originalToStyled,
  styledToMuiDatatable,
} from './utils';
import Show from './components/show';
import {
  getLoanRequest,
  getRunningLoans,
  handleRequestErrors,
} from '../../../Helpers/Requests';
import { downloadBlob } from '../../../Helpers/Helpers';

export default function ManageRunningLoans() {
  const IdCellIndex = 0;

  // Requests data variables
  const [originalLoanRequests, setOriginalLoanRequests] = React.useState([]);
  const [datatableLoanRequests, setDatatableLoanRequests] = React.useState([]);
  // Requests data variables

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  let currentTableState = {};
  let currentFilters = [];

  const [currentFilterFields, setCurrentFilterFields] = React.useState({});
  // Table effects variables

  // Pagination variables
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 5,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (updatedRowsPerPageValue) => {
    setController({
      ...controller,
      rowsPerPage: updatedRowsPerPageValue,
      page: 0,
    });
  };
  // Pagination variables

  // Mui datatables variables
  const options = {
    selectableRows: 'none',
    count: requestsCount,
    page: controller.page,
    rowsPerPage: controller.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: true,
    filterType: 'textField',
    onRowClick: (row) => {
      handleManageRequestOpen(row[IdCellIndex]);
    },
    // controlling download content
    onDownload: (buildHead, buildBody, columns, data) => {
      getRunningLoans(null, null, currentFilterFields)
        .then((loanRequests) => {
          const tableData = loanRequests.data;
          const printableTableData = originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          downloadBlob(csv, 'Running_Loans.csv', 'text/csv;charset=utf-8;');
          return printableTableData;
        })
        .catch((err) => handleRequestErrors(err));

      return false;
    },
    // setting row style
    setRowProps: () => ({
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    }),
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    // setting Head cell style
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      return style;
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(newTableState.page);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(newTableState.rowsPerPage);
          break;
      }
      currentTableState = newTableState;
    },
    // Handle changing the filter from the chips
    onFilterChange: (column, newFilters, type) => {
      const applyFilters = () => newFilters;
      if (newFilters !== currentFilters) {
        handleFilterSubmit(applyFilters);
        currentFilters = newFilters;
      }

      // Reset the pagination to the first page
      setController((prev) => ({ ...prev, page: 0 }));
    },
  };
  // Mui datatables

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    getRunningLoans(
      controller.page,
      controller.rowsPerPage,
      currentFilterFields,
    )
      .then((requests) => {
        setOriginalLoanRequests(requests.data);
        setRequestsCount(requests.meta.total);
        setCurrentColumns(getDatatableColumns());
      })
      .catch((err) => handleRequestErrors(err));
  }, [controller]);
  // Component did mount.

  // update styled and datatable requests whenever fetched requests change
  React.useEffect(() => {
    const _styledLoanRequests = originalToStyled(originalLoanRequests);
    const _datatableLoanRequests = styledToMuiDatatable(
      _styledLoanRequests,
      handleManageRequestOpen,
    );
    setDatatableLoanRequests(_datatableLoanRequests);
  }, [originalLoanRequests]);
  // update styled and datatable requests whenever fetched requests change

  // Handling apply filters button action
  const handleFilterSubmit = (applyFilters) => {
    const filterFields = createFilterFields(currentTableState, applyFilters);

    setCurrentFilterFields(filterFields);

    getRunningLoans(controller.page, controller.rowsPerPage, filterFields)
      .then((filteredRequests) => {
        setOriginalLoanRequests(filteredRequests.data);
        setRequestsCount(filteredRequests.meta.total);
      })
      .catch((err) => handleRequestErrors(err));
  };
  // Handling apply filters button action

  // Manage Loan Request section
  const [managedRequestLoaded, setManagedRequestLoaded] = React.useState(false);
  const [openManageRequestDialog, setOpenManageRequestDialog] =
    React.useState(false);
  const [managedLoanRequest, setManagedLoanRequest] = React.useState({});

  const handleManageRequestOpen = (requestId) => {
    getLoanRequest(requestId)
      .then((loanRequest) => {
        setManagedLoanRequest(loanRequest);
        setManagedRequestLoaded(true);
        setOpenManageRequestDialog(true);
      })
      .catch((err) => handleRequestErrors(err));
  };
  const handleManageRequestClose = () => setOpenManageRequestDialog(false);
  // Manage Loan Request section

  return (
    <>
      {managedRequestLoaded && (
        <Show
          open={openManageRequestDialog}
          onClose={handleManageRequestClose}
          managedLoanRequest={managedLoanRequest}
        />
      )}
      <MUIDataTable
        title={'Manage Running Loans'}
        data={datatableLoanRequests}
        columns={currentColumns}
        options={options}
      />
    </>
  );
}
