import React from 'react';
import * as Requests from '../../../../Helpers/Requests';
import * as Helpers from '../../../../Helpers/Helpers';
import * as PayrollHistoryUtils from './utils';
import MUIDataTable from 'mui-datatables';
import { Button } from '@mui/material';
import { userData } from '../../../../Helpers/Constants';
import { injectTotalColumns } from '../utils';

export default function PayrollHistory() {
  const [originalPayrollHistoryData, setOriginalPayrollHistoryData] =
    React.useState([]);
  const [renderedData, setRenderedData] = React.useState([]);

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  let currentTableState = {};
  let currentFilters = [];

  const [currentFilterFields, setCurrentFilterFields] = React.useState({});

  // Pagination variables
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 5,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (updatedRowsPerPageValue) => {
    setController({
      ...controller,
      rowsPerPage: updatedRowsPerPageValue,
      page: 0,
    });
  };

  // MUI datatables options
  const options = {
    pagination: true,
    selectableRows: 'none',
    count: requestsCount,
    page: controller.page,
    rowsPerPage: controller.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: true,
    filterType: 'textField',
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(newTableState.page);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(newTableState.rowsPerPage);
          break;
      }
      currentTableState = newTableState;
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      Requests.listPayrollHistory(null, null, currentFilterFields, userData.id)
        .then((response) => {
          const tableData = injectTotalColumns(response.data);

          const printableTableData =
            PayrollHistoryUtils.originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'Payroll_History.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      return style;
    },
    onFilterChange: (column, newFilters, type) => {
      const applyFilters = () => newFilters;
      if (newFilters !== currentFilters) {
        handleFilterSubmit(applyFilters);
        currentFilters = newFilters;
      }

      // Reset the pagination to the first page
      setController((prev) => ({ ...prev, page: 0 }));
    },
  };

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    setOriginalPayrollHistoryData([]);

    Requests.listPayrollHistory(
      controller.page,
      controller.rowsPerPage,
      currentFilterFields,
      userData.id,
    )
      .then((response) => {
        setOriginalPayrollHistoryData(response.data);
        setRequestsCount(response.meta.total);
        setCurrentColumns(PayrollHistoryUtils.getDatatableColumns());
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [controller, currentFilterFields]);

  React.useEffect(() => {
    const _renderedData = PayrollHistoryUtils.originalToRendered(
      originalPayrollHistoryData,
    );
    setRenderedData(_renderedData);
  }, [originalPayrollHistoryData]);

  const handleFilterSubmit = (applyNewFilters) => {
    const filterFields = PayrollHistoryUtils.createFilterFields(
      { columns: currentColumns },
      applyNewFilters,
    );

    setCurrentFilterFields(filterFields);

    setController((prev) => ({ ...prev, page: 0 }));
  };

  return (
    <MUIDataTable
      title={'Finalized Payrolls Details'}
      data={renderedData}
      columns={currentColumns}
      options={options}
      className="mt-5"
    />
  );
}
