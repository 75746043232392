import React from 'react';
import Button from '@mui/material/Button';

import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';
import {
  deleteLoan,
  getCurrentUser,
  handleRequestErrors,
} from '../../../../Helpers/Requests';
import { Pages } from '../../../../Helpers/Constants';

export default function LoansDelete({
  openApproveRequestDialog,
  setOpenDeleteRequestDialog,
  renderedLoanRequest,
}) {
  const [requestLoading, setRequestLoading] = React.useState(false);

  const handleDeleteLoan = (e) => {
    e.preventDefault();

    setRequestLoading(true);

    getCurrentUser()
      .then((user) => {
        deleteLoan(user.id, renderedLoanRequest.id)
          .then(() => {
            toast('Loan Request was deleted successfully.', {
              type: 'success',
            });
            setTimeout(() => {
              window.location.replace(Pages.LoansList.path());
            }, 1000);
          })
          .catch((err) => {
            toast(err.response.data.message, { type: 'error' });
          })
          .finally(() => {
            setRequestLoading(false);
          });
      })
      .catch((err) => handleRequestErrors(err));
  };

  return (
    <>
      <Dialog
        onClose={() => setOpenDeleteRequestDialog(false)}
        open={openApproveRequestDialog}
      >
        <form onSubmit={handleDeleteLoan} style={{ width: '500px' }}>
          <DialogTitle>Delete Loans Request</DialogTitle>
          <List sx={{ pt: 0 }}>
            <DialogContent dividers>
              <div>Are you sure you want to delete this Loan Request?</div>
              <b> This action can not be undone.</b>
            </DialogContent>
          </List>
          <DialogActions className="d-flex justify-content-between mx-2">
            <button
              className="btn btn-secondary"
              onClick={() => setOpenDeleteRequestDialog(false)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              disabled={requestLoading}
            >
              Confirm Delete
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
