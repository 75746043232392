import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { getColLabels } from '../../../Users/helpers';
import { formatObjectKeys } from '../../../../Helpers/Helpers';
import { Button, DialogContent, Menu, MenuItem } from '@mui/material';
import Edit from './Edit';
import { WorklogsTableCols, hiddenCols } from '../../consts';

export default function Show({
  open,
  selectedValue,
  onClose,
  viewedWorklogRecord,
  tableUpdated,
  setTableUpdated,
}) {
  const [formattedWorklogRecord, setFormattedWorklogRecord] = React.useState(
    {},
  );

  const handleClose = () => onClose(selectedValue);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Edit Worklog dialog //
  const [openEditWorklogDialog, setOpenEditWorklogDialog] =
    React.useState(false);

  const handleEditWorklogOpen = () => {
    handleCloseMenu();
    setOpenEditWorklogDialog(true);
  };
  const handleEditWorklogClose = () => {
    handleClose();
    setOpenEditWorklogDialog(false);
  };
  // Edit Worklog dialog //

  React.useEffect(() => {
    if (viewedWorklogRecord) {
      // Format Object keys to get the displayable keys
      const _formattedWorklogRecord = formatObjectKeys(
        viewedWorklogRecord,
        WorklogsTableCols,
      );
      setFormattedWorklogRecord(_formattedWorklogRecord);
    }
  }, [viewedWorklogRecord]);

  const renderListItems = (formattedWorklogRecord) => {
    return getColLabels(Object.values(WorklogsTableCols)).map((colLabel) => {
      return renderListItem([colLabel, formattedWorklogRecord[colLabel]]);
    });
  };

  const renderListItem = ([key, value]) => {
    return (
      <>
        {!getColLabels(hiddenCols).includes(key) && (
          <ListItem key={key}>
            <ListItemText
              primary={`${key} : `}
              style={{ flex: 'none', marginRight: '0.5rem' }}
            />
            {formattedWorklogRecord[key] ? (
              <div>{`${formattedWorklogRecord[key]}`}</div>
            ) : (
              <div
                className={`badge bg-secondary`}
                style={{ padding: '0.30rem 0.5rem' }}
              >
                Unspecified
              </div>
            )}
          </ListItem>
        )}
      </>
    );
  };

  return (
    <>
      {Object.keys(viewedWorklogRecord).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <Edit
              open={openEditWorklogDialog}
              onClose={handleEditWorklogClose}
              viewedWorklogRecord={viewedWorklogRecord}
              tableUpdated={tableUpdated}
              setTableUpdated={setTableUpdated}
            />

            <div className="d-flex justify-content-between align-items-center me-2">
              <DialogTitle>Worklog Details</DialogTitle>
              <div className="adminpanel-button" onClick={handleOpenMenu}>
                <MoreVertIcon />
              </div>
            </div>

            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleEditWorklogOpen}>
                <EditIcon className="me-2" />
                <div className="me-3">Edit Worklog</div>
              </MenuItem>
            </Menu>
            <DialogContent dividers>
              <List sx={{ pt: 0 }}>
                {renderListItems(formattedWorklogRecord)}
              </List>
            </DialogContent>

            <DialogActions
              className="d-flex justify-content-end"
              sx={{
                position: 'sticky',
                bottom: 0,
                py: 2,
                px: 3,
                bgcolor: 'background.default',
              }}
            >
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
