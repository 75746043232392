import React from 'react';
import * as Constants from '../../../Helpers/Constants';

import RecurringPaymentsManageTable from './RecurringPaymentsManageTable';
import { getTableByType } from '../../../Helpers/Helpers';

export default function RecurringPaymentsManageMain() {
  const [currentTable, setCurrentTable] = React.useState('');

  React.useEffect(() => {
    setCurrentTable(
      getTableByType(Constants.TablesTypes.Finished, 'Recurring Payments'),
    );
  }, []);

  return (
    <>
      {Object.keys(currentTable).length > 0 && (
        <div className="pt-5">
          <RecurringPaymentsManageTable currentTable={currentTable} />
        </div>
      )}
    </>
  );
}
