import * as React from 'react';
import Button from '@mui/material/Button';

import * as Requests from '../../../Helpers/Requests';
import { Pages } from '../../../Helpers/Constants';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';
import { toast } from 'react-toastify';

export default function ExcusesViewDeleteModal({
  openApproveRequestDialog,
  setOpenDeleteRequestDialog,
  viewedExcuseRequest,
}) {
  const [requestLoading, setRequestLoading] = React.useState(false);

  const deleteOvertime = (e) => {
    e.preventDefault();

    setRequestLoading(true);

    Requests.getCurrentUser()
      .then((user) => {
        const overtimeId = viewedExcuseRequest.id;
        Requests.deleteExcuse(user.id, overtimeId)
          .then(() => {
            toast('Excuse Request was deleted successfully.', {
              type: 'success',
            });
            setTimeout(() => {
              window.location.replace(Pages.ExcusesView.path());
            }, 1000);
          })
          .catch((err) => {
            toast(err.response.data.message, {
              type: 'error',
            });
          })
          .finally(() => {
            setRequestLoading(false);
          });
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };

  return (
    <>
      <Dialog
        onClose={() => setOpenDeleteRequestDialog(false)}
        open={openApproveRequestDialog}
      >
        <form onSubmit={deleteOvertime} style={{ width: '500px' }}>
          <DialogTitle>Delete Excuse Request</DialogTitle>
          <List sx={{ pt: 0 }}>
            <DialogContent dividers>
              <div>Are you sure you want to delete this Excuse Request?</div>
              <b> This action can not be undone.</b>
            </DialogContent>
          </List>
          <DialogActions className="d-flex justify-content-between mx-2">
            <button
              className="btn btn-secondary"
              onClick={() => setOpenDeleteRequestDialog(false)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              disabled={requestLoading}
            >
              Confirm Delete
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
