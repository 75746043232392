import * as React from 'react';

import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import * as NursingHoursUtils from './NursingHoursUtils';
import MUIDataTable from 'mui-datatables';
import { Button } from '@mui/material';
import moment from 'moment';
import styled from 'styled-components';

const HideChipCloseStyledDiv = styled.div`
  .MuiChip-deleteIcon {
    display: none;
  }
  .MuiChip-root {
    margin-right: 0.3rem;
  }
`;

export default function NursingHoursTable() {
  // Requests data variables
  const [originalNursingHours, setOriginalNursingHours] = React.useState([]);
  const [datatableNursingHours, setDatatableNursingHours] = React.useState([]);
  // Requests data variables

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  let currentTableState = {};
  let currentFilters = [];

  const [currentFilterFields, setCurrentFilterFields] = React.useState({
    start_date: moment().clone().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().clone().endOf('month').format('YYYY-MM-DD'),
  });
  // Table effects variables

  // Pagination variables
  const [entriesCount, setEntriesCount] = React.useState(0);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (updatedRowsPerPageValue) => {
    setController({
      ...controller,
      rowsPerPage: updatedRowsPerPageValue,
      page: 0,
    });
  };
  // Pagination variables

  // Mui datatables variables
  const options = {
    selectableRows: 'none',
    count: entriesCount,
    page: controller.page,
    rowsPerPage: controller.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: true,
    filterType: 'textField',
    // controlling download content
    onDownload: (buildHead, buildBody, columns, data) => {
      Requests.getNursingHours(null, null, currentFilterFields)
        .then((report) => {
          const tableData = report.data;
          const printableTableData =
            NursingHoursUtils.originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'NursingHours_.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    // setting Head cell style
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      return style;
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(newTableState.page);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(newTableState.rowsPerPage);
          break;
      }
      currentTableState = newTableState;
    },
    // Handle changing the filter from the chips
    onFilterChange: (column, newFilters, type) => {
      const applyFilters = () => newFilters;
      if (newFilters !== currentFilters) {
        handleFilterSubmit(applyFilters);
        currentFilters = newFilters;
      }

      // Reset the pagination to the first page
      setController((prev) => ({ ...prev, page: 0 }));
    },
  };
  // Mui datatables

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    Requests.getNursingHours(
      controller.page,
      controller.rowsPerPage,
      currentFilterFields,
    )
      .then((report) => {
        setOriginalNursingHours(report.data);
        setEntriesCount(report.meta.total);
        setCurrentColumns(
          NursingHoursUtils.getDatatableColumns(currentFilterFields),
        );
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [controller, currentFilterFields]);
  // Component did mount.

  // update styled and datatable requests whenever fetched requests change
  React.useEffect(() => {
    const _datatableNursingHours =
      NursingHoursUtils.originalToMuiDatatable(originalNursingHours);
    setDatatableNursingHours(_datatableNursingHours);
  }, [originalNursingHours]);
  // update styled and datatable requests whenever fetched requests change

  // Handling apply filters button action
  const handleFilterSubmit = (applyFilters) => {
    const filterFields = NursingHoursUtils.createFilterFields(
      currentTableState,
      applyFilters,
    );

    setCurrentFilterFields(filterFields);

    Requests.getNursingHours(
      controller.page,
      controller.rowsPerPage,
      filterFields,
    )
      .then((filtered) => {
        setOriginalNursingHours(filtered.data);
        setEntriesCount(filtered.meta.total);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  // Handling apply filters button action

  return (
    <>
      <HideChipCloseStyledDiv>
        <MUIDataTable
          title={'Child Care Hours Report'}
          data={datatableNursingHours}
          columns={currentColumns}
          options={options}
        />
      </HideChipCloseStyledDiv>
    </>
  );
}
