import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { MuiFileInput } from 'mui-file-input';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import * as Requests from '../../Helpers/Requests';
import {
  DateCols,
  DocumentCols,
  DropDownCols,
  ExpiryCols,
  Sections,
  TextCols,
  UsersTableCols,
  getColNames,
} from './helpers';
import { Autocomplete, ListItem } from '@mui/material';
import _ from 'lodash';
import {
  filterObjNullValues,
  formatUserDataToForm,
  handleDateFieldsChange,
  handleViewFile,
  nullifyObjectValues,
} from '../../Helpers/Helpers';
import { toast } from 'react-toastify';

export default function EditUser({
  onClose,
  selectedValue,
  open,
  managedUserData,
  tableUpdated,
  setTableUpdated,
  usersData,
  chosenSection,
}) {
  // const RoleIdBackendKey = "role_id";
  // const RoleObjIdKey = "id";

  const disabledFields = [];

  const hiddenFields = [
    UsersTableCols.ID.Name,
    UsersTableCols.Team.Name,
    UsersTableCols.Role.Name,
  ];

  // const [roles, setRoles] = React.useState([]);
  // const [selectedRole, setSelectedRole] = React.useState();

  // const [managers, setManagers] = React.useState([]);
  // const [filteredManagers, setFilteredManagers] = React.useState([]);
  // const [selectedManager, setSelectedManager] = React.useState();

  // const [teams, setTeams] = React.useState([]);
  // const [selectedTeam, setSelectedTeam] = React.useState();

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);
  const [editUserFormValues, setEditUserFormValues] = React.useState({});

  //   const getHigherManagers = (managers, currentUserRoleId) => {
  //     let higherManagers = managers.filter(
  //       (manager) => manager.role.id < currentUserRoleId
  //     );
  //     return higherManagers;
  //   };

  const handleClose = () => {
    onClose(selectedValue);
    setEditUserFormValues(nullifyObjectValues({ ...editUserFormValues }));
    // if (managedUserData.role) {
    //   setSelectedRole(managedUserData.role.id);
    // }
    // if (managedUserData.role) {
    //   setFilteredManagers(managers, managedUserData.role.id);
    // }
    // if (managedUserData.role) {
    //   setSelectedManager(managedUserData.manager.id);
    // }
  };

  // Setting initial values of the form object
  React.useEffect(() => {
    let initialFormValues = filterObjNullValues(managedUserData);
    initialFormValues = formatUserDataToForm(initialFormValues);
    setEditUserFormValues(initialFormValues);
  }, [managedUserData]);

  // Injecting fetched dropdown menus options
  React.useEffect(() => {
    // Adding Manager dropdown options using usersData.
    if (usersData) {
      const managerDropDownColOptions = {};
      usersData.forEach((userData) => {
        managerDropDownColOptions[userData[UsersTableCols.Name.Name]] = {
          id: userData[UsersTableCols.ID.Name],
          name: userData[UsersTableCols.Name.Name],
        };
      });

      const managerColIndex = _.findIndex(DropDownCols, {
        Name: UsersTableCols.Manager.Name,
      });
      DropDownCols[managerColIndex].Options = managerDropDownColOptions;
    }
  }, [usersData]);

  // React.useEffect(() => {
  //   if (managedUserData.role) {
  //     setSelectedRole(managedUserData.role.id);
  //   }
  //   if (managedUserData.manager) {
  //     setSelectedRole(managedUserData.manager.id);
  //   }
  //   if (managedUserData.team) {
  //     setSelectedRole(managedUserData.team.id);
  //   }

  //   Requests.getRoles()
  //     .then((roles) => {
  //       roles.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
  //       setRoles([...roles]);
  //     })
  //     .catch((err) => Requests.handleRequestErrors(err));
  //   Requests.getManagers()
  //     .then((managers) => {
  //       managers.sort((b, a) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
  //       setManagers([...managers]);
  //       let higherManagers = getHigherManagers(
  //         managers,
  //         managedUserData.role ? managedUserData.role.id : null
  //       );
  //       setFilteredManagers(higherManagers);
  //     })
  //     .catch((err) => Requests.handleRequestErrors(err));
  //   Requests.getTeams()
  //     .then((teams) => {
  //       teams.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
  //       setTeams([...teams]);
  //     })
  //     .catch((err) => Requests.handleRequestErrors(err));
  // }, [managedUserData]);

  const handleInputChange = (e, ele, fieldMaxLength = null) => {
    if (fieldMaxLength) {
      e.target.value = e.target.value.substring(0, fieldMaxLength);
    }
    const { name, value } = e.target;
    setEditUserFormValues({
      ...editUserFormValues,
      [name]: value,
    });
  };

  const handleDropDownChange = (colName, newValueObj) => {
    setEditUserFormValues({
      ...editUserFormValues,
      [colName]: newValueObj?.id || '',
    });
  };

  // const handleRoleFieldChange = (e) => {
  //   setSelectedRole(e.target.value);

  //   // update managers list and selected Value
  //   let updatedManagers = getHigherManagers(managers, e.target.value);
  //   setFilteredManagers([...updatedManagers]);
  //   setSelectedManager(updatedManagers[0].id);
  // };

  // const handleManagerFieldChange = (e) => {
  //   setSelectedManager(e.target.value);
  // };

  // const handleTeamFieldChange = (e) => {
  //   setSelectedTeam(e.target.value);
  // };

  const handleFileChange = (file, fieldName) => {
    const _editUserFormValues = { ...editUserFormValues };
    if (file) {
      setEditUserFormValues({
        ..._editUserFormValues,
        [fieldName]: file,
      });
    } else {
      delete _editUserFormValues[fieldName];

      setEditUserFormValues({
        ..._editUserFormValues,
      });
    }
  };

  const handleEditUserSubmit = () => {
    setRequestLoading(true);

    // Clean up form values before request
    const formValues = { ...editUserFormValues };
    // formValues = filterObjNullValues(formValues);

    Object.keys(editUserFormValues).forEach((key) => {
      // Unset unchanged file fields.
      // Note : Empty string as a legit value as it is
      // how we reset the file field.
      if (
        getColNames(DocumentCols).includes(key) &&
        editUserFormValues[key] &&
        typeof editUserFormValues[key] === 'string'
      ) {
        delete formValues[key];
      }
    });

    // Use FormData to allow for file parameters
    const formData = new FormData();

    // Backend required parameter
    formData.append('_method', 'PUT');

    for (const [key, value] of Object.entries(formValues)) {
      formData.append(key, value);
    }

    // Handling special fields
    // formData.role_id = selectedRole;
    // formData.manager_id = selectedManager;
    // formData.team_id = selectedTeam;
    // formData.append(UsersTableCols.IdentityCard.Name, idCardFile);

    Requests.updateUserInfo(managedUserData.id, formData)
      .then((response) => {
        toast(response.data.message, { type: 'success' });

        setTableUpdated(!tableUpdated);
        handleClose();
      })
      .catch((error) => {
        Requests.handleRequestErrors(error);
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const resetFileField = (e, fieldName) => {
    setEditUserFormValues({
      ...editUserFormValues,
      [fieldName]: '',
    });
  };

  const renderDateField = (field) => {
    return (
      <div style={{ width: `${field.Width}rem` }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={editUserFormValues[field.Name]}
            onChange={(date) =>
              handleDateFieldsChange(
                date,
                field.Name,
                DateCols,
                setInvalidDateValue,
                editUserFormValues,
                setEditUserFormValues,
              )
            }
            inputFormat="DD-MM-YYYY"
            label={field.Label}
            renderInput={(params) => <TextField fullWidth {...params} />}
            className="mb-1 mt-3 d-block"
          />
        </LocalizationProvider>
      </div>
    );
  };

  const renderFileInputField = (field, fieldValue) => {
    return (
      <ListItem key={field.Name} className="p-0 d-flex align-items-center">
        <MuiFileInput
          className="mb-1 mt-3 d-flex"
          label={field.Label}
          placeholder={
            fieldValue && typeof fieldValue === 'string'
              ? fieldValue?.slice(0, 40).concat('...')
              : ''
          }
          value={
            fieldValue && typeof fieldValue !== 'string' ? fieldValue : null
          }
          onChange={(file) => handleFileChange(file, field.Name)}
        />
        <div className="d-flex flex-column ms-4 mt-3">
          {fieldValue && typeof fieldValue === 'string' && (
            <>
              <button
                className="btn btn-link p-0"
                value={fieldValue}
                onClick={(e) => handleViewFile(e.target.value)}
              >
                View Original
              </button>
              <div
                className="link-primary text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={(e) => resetFileField(e, field.Name)}
              >
                Reset
              </div>
            </>
          )}
        </div>
      </ListItem>
    );
  };

  const renderEditFormSections = (
    managedUserData,
    editUserFormValues,
    chosenSection,
  ) => {
    if (chosenSection) {
      return (
        <>
          <div
            key={chosenSection}
            className="h6 mt-4"
            style={{ fontSize: '1.25rem' }}
          >
            {chosenSection.Label}
          </div>
          <div>
            {getColNames(chosenSection.Columns).map((sectionColKey) => {
              return renderEditFormField([
                sectionColKey,
                managedUserData,
                editUserFormValues,
              ]);
            })}
          </div>
        </>
      );
    } else {
      return Object.values(Sections).map((section, index) => {
        return (
          <>
            <div
              key={index}
              className="h6 mt-4"
              style={{ fontSize: '1.25rem' }}
            >
              {section.Label}
            </div>
            <div>
              {getColNames(section.Columns).map((sectionColKey) => {
                return renderEditFormField([
                  sectionColKey,
                  managedUserData,
                  editUserFormValues,
                ]);
              })}
            </div>
          </>
        );
      });
    }
  };

  const renderEditFormField = ([
    fieldName,
    managedUserData,
    editUserFormValues,
  ]) => {
    if (getColNames(DocumentCols).includes(fieldName)) {
      const documentCol = _.find(DocumentCols, { Name: fieldName });
      return renderFileInputField(
        documentCol,
        editUserFormValues[documentCol.Name],
      );
    } else if (getColNames(TextCols).includes(fieldName)) {
      const textCol = _.find(TextCols, { Name: fieldName });
      return (
        <TextField
          key={textCol.Name}
          label={textCol.Label}
          name={textCol.Name}
          type={textCol.Type}
          defaultValue={editUserFormValues[textCol.Name]}
          onChange={(e, ele) => handleInputChange(e, ele, textCol.MaxLength)}
          fullWidth
          margin="dense"
          variant="standard"
          disabled={disabledFields.includes(textCol.Name)}
          hidden={hiddenFields.includes(textCol.Name)}
        />
      );
    } else if (getColNames([...ExpiryCols, ...DateCols]).includes(fieldName)) {
      const dateCol = _.find([...ExpiryCols, ...DateCols], {
        Name: fieldName,
      });
      return renderDateField(dateCol);
    } else if (getColNames(DropDownCols).includes(fieldName)) {
      const dropDownCol = _.find(DropDownCols, { Name: fieldName });
      return (
        <div
          key={dropDownCol.Name}
          style={{ width: `${dropDownCol.Width}rem` }}
        >
          <Autocomplete
            getOptionLabel={(option) => {
              if (typeof option === 'object') {
                return String(option.name);
              } else {
                const optionObj = _.find(dropDownCol.Options, {
                  id: option,
                });
                return optionObj ? String(optionObj.name) : option;
              }
            }}
            isOptionEqualToValue={(optionObj, valueObj) => {
              return optionObj.id == valueObj;
            }}
            value={editUserFormValues[dropDownCol.Name] || null}
            options={
              dropDownCol.Options ? Object.values(dropDownCol.Options) : []
            }
            onChange={(e, newValue) =>
              handleDropDownChange(dropDownCol.Name, newValue)
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={dropDownCol.Label}
                  className="mb-1 mt-3 d-block"
                  fullWidth
                />
              );
            }}
          />
        </div>
      );
    }
    // Default Case
    return <></>;
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        {chosenSection ? `Edit ${chosenSection.Label}` : 'Edit User'}
      </DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {renderEditFormSections(
            managedUserData,
            editUserFormValues,
            chosenSection,
          )}
          {/* <TextField
            label="Team"
            defaultValue={managedUserData.team.id}
            onChange={handleTeamFieldChange}
            select
            name="team_id"
            className="mt-4"
          >
            {teams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Role"
            defaultValue={managedUserData.role.id}
            onChange={handleRoleFieldChange}
            select
            name="role_id"
            className="mt-4"
          >
            {roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="manager"
            onChange={handleManagerFieldChange}
            select
            name="manager_id"
            className="mt-4 d-block"
            value={selectedManager}
          >
            {filteredManagers.map((manager) => (
              <MenuItem key={manager.id} value={manager.id}>
                {manager.name}
              </MenuItem>
            ))}
          </TextField> */}
        </List>
      </DialogContent>
      <DialogActions
        className="d-flex justify-content-between"
        sx={{
          position: 'sticky',
          bottom: 0,
          py: 2,
          px: 3,
          bgcolor: 'background.default',
        }}
      >
        <button
          onClick={handleEditUserSubmit}
          disabled={requestLoading || invalidDateValue}
          className="btn btn-warning me-3"
        >
          Confirm Changes
        </button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
