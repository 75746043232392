import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { can } from '../../Helpers/Helpers';
import { PERMISSIONS } from '../../Helpers/Constants';

export default function HolidayViewModal(props) {
  return (
    <>
      <Modal
        style={{ marginTop: '20vh' }}
        show={props.showModal === 'view'}
        onHide={() => props.setShowModal(null)}
      >
        <Modal.Header>
          <Modal.Title>View Holiday</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-horizontal">
            <div className="form-group row">
              <label htmlFor="holiday-name" className="col-sm-3 control-label">
                Name
              </label>
              <div className="col-sm-9">
                <p>{props.currentHoliday.name}</p>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="min-date" className="col-sm-3 control-label">
                Duration
              </label>
              <div className="col-sm-9">
                <div className="input-group input-daterange">
                  <p>
                    {props.currentHoliday.start_date} <small>to </small>{' '}
                    {props.currentHoliday.end_date}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            {can([PERMISSIONS.ManageHolidays]) && (
              <>
                <Button
                  variant="danger"
                  onClick={() => props.setShowModal('delete')}
                >
                  Delete
                </Button>
              </>
            )}
            <div className="d-flex justify-content-end w-100">
              {can([PERMISSIONS.ManageHolidays]) && (
                <>
                  <Button
                    className="me-2"
                    variant="warning"
                    onClick={() => props.setShowModal('edit')}
                  >
                    Edit Holiday
                  </Button>
                </>
              )}
              <Button
                variant="secondary"
                onClick={() => props.setShowModal(null)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
