import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { TablesTypes } from '../../../../Helpers/Constants';
import { originalToStyled } from '../utils';
import LoansDelete from './delete';
import LoanInstallments, {
  LoanInstallmentsBgColors,
  LoanInstallmentsComponentTypes,
} from '../../Request/components/LoanInstallments';
import { DialogContent } from '@mui/material';

export default function LoansShow({
  onClose,
  selectedValue,
  open,
  viewedLoanRequest,
  currentTable,
}) {
  const [renderedLoanRequest, setRenderedLoanRequest] = React.useState({});
  const [openDeleteRequestDialog, setOpenDeleteRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Component did update
  React.useEffect(() => {
    // Update Loans request.
    const _renderedLoanRequest = originalToStyled([viewedLoanRequest]);
    setRenderedLoanRequest(_renderedLoanRequest[0]);
  }, [viewedLoanRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === TablesTypes.Pending) {
    dialogActions = (
      <>
        <div className="d-flex justify-content-between w-100">
          <div>
            <button
              className="ms-2 mb-2 btn btn-danger"
              onClick={() => setOpenDeleteRequestDialog(true)}
            >
              Delete Request
            </button>
          </div>
          <div>
            <button
              className="ms-auto me-2 mb-2 btn btn-secondary"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </>
    );
  } else if (currentTable.type === TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="ms-auto me-2">
          <Button onClick={handleClose}>Close</Button>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedLoanRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <LoansDelete
              openApproveRequestDialog={openDeleteRequestDialog}
              setOpenDeleteRequestDialog={setOpenDeleteRequestDialog}
              renderedLoanRequest={renderedLoanRequest}
            />
            <DialogTitle>Loan Request Details</DialogTitle>
            <DialogContent dividers>
              <List sx={{ pt: 0 }}>
                <ListItem>
                  <ListItemText
                    primary={<>Request ID : {renderedLoanRequest.id}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Reason : {renderedLoanRequest.reason}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Value : {renderedLoanRequest.value} (LE)</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Installments Count :{' '}
                        {renderedLoanRequest.installments_count}
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <div>Status :</div>
                  <span
                    className={`
						ms-2 badge
						${renderedLoanRequest.status.style.bg}
						${renderedLoanRequest.status.style.text}
					`}
                  >
                    {renderedLoanRequest.status.name}
                  </span>
                </ListItem>
                {renderedLoanRequest.comment && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          Management Notes : {renderedLoanRequest.comment || ''}
                        </>
                      }
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Last Updated At : {renderedLoanRequest.updated_at || ''}
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span>
                        Last Updated By : {renderedLoanRequest.updated_by || ''}
                      </span>
                    }
                  />
                </ListItem>
                <LoanInstallments
                  rowsData={viewedLoanRequest.installments}
                  displayType={LoanInstallmentsComponentTypes.View}
                  backgroundColor={LoanInstallmentsBgColors.White}
                />
              </List>
            </DialogContent>
            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
