import * as React from 'react';
import Calendar from 'rc-year-calendar';

import 'tippy.js/dist/tippy.css'; // optional for styling
import tippy from 'tippy.js';
import 'tippy.js/themes/light.css';

import structuredClone from '@ungap/structured-clone';

import './Holidays.css';
import HolidayCreateModal from './HolidayCreateModal.jsx';
import HolidayViewModal from './HolidayViewModal.jsx';

import * as HolidaysUtils from './HolidaysUtils.jsx';
import HolidayEditModal from './HolidayEditModal.jsx';
import HolidayDeleteModal from './HolidayDeleteModal.jsx';

import * as Requests from '../../Helpers/Requests.jsx';
import { can } from '../../Helpers/Helpers.jsx';
import { PERMISSIONS } from '../../Helpers/Constants.jsx';

export default function Holidays() {
  // Holidays Data
  const [showModal, setShowModal] = React.useState(null);
  const [originalData, setOriginalData] = React.useState([]);
  const [renderedData, setRenderedData] = React.useState([]);

  const [currentHoliday, setCurrentHoliday] = React.useState({});
  const [holidaysUpdated, setHolidaysUpdated] = React.useState(false);

  const HolidaysColor = 'green';

  let tooltip = null;

  React.useEffect(() => {
    // Fetching holidays
    Requests.getHolidays()
      .then((holidays) => {
        setOriginalData(holidays);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [holidaysUpdated]);

  React.useEffect(() => {
    // Convert date format to match the calender library requirement.
    const _renderedData = HolidaysUtils.originalToRendered(originalData);
    setRenderedData(_renderedData);
  }, [originalData]);

  const handleDayEnter = (e) => {
    if (e.events.length > 0) {
      const content = `
          <div class="event-tooltip-content">
            <div
              class="holiday-name"
              style="color: ${HolidaysColor};"
			/>
              ${e.events[0].name}
            </div>
          </div>
        `;

      tooltip = tippy(e.element, {
        allowHTML: true,
        placement: 'right',
        content,
        animateFill: false,
        animation: 'shift-away',
        arrow: true,
        theme: 'light',
      });
      tooltip.show();
    }
  };

  const handleDayLeave = () => {
    if (tooltip) {
      tooltip.destroy();
      tooltip = null;
    }
  };

  const handleDayClick = (e) => {
    let _currentHoliday = HolidaysUtils.createEmptyOriginalObj();
    if (e.events.length) {
      setShowModal('view');
      _currentHoliday = {
        ...structuredClone(e.events[0]),
      };
    } else {
      if (can([PERMISSIONS.ManageHolidays])) {
        setShowModal('create');
        _currentHoliday = {
          ..._currentHoliday,
          start_date: e.date,
          end_date: e.date,
        };
      }
    }
    setCurrentHoliday(HolidaysUtils.renderedToOriginal([_currentHoliday])[0]);
  };

  const customRenderEvent = (div, dateString, event) => {
    div.style.borderRadius = '0px';
    div.style.backgroundColor = HolidaysColor;
  };

  return (
    renderedData && (
      <div
        id="holidays_main"
        className="d-flex justify-content-center align-items-center h-100 flex-column"
      >
        <div className="w-100 h1 d-flex justify-content-center mt-2 mb-5">
          Holidays Calender
        </div>
        <Calendar
          dataSource={renderedData}
          enableRangeSelection={true}
          enableContextMenu={false}
          displayWeekNumber={true}
          weekStart={6}
          // allowOverlap={false}
          style="custom"
          customDataSourceRenderer={(div, dateString, event) =>
            customRenderEvent(div, dateString, event)
          }
          onDayClick={(e) => handleDayClick(e)}
          onDayEnter={(e) => handleDayEnter(e)}
          onDayLeave={() => handleDayLeave()}
          //   onRangeSelected={(e) => setCurrentHoliday({ start_date: e.start_date, end_date: e.end_date })}
        />
        {currentHoliday.start_date && (
          <>
            <HolidayViewModal
              showModal={showModal}
              setShowModal={setShowModal}
              currentHoliday={currentHoliday}
            />
            <HolidayCreateModal
              originalData={originalData}
              setOriginalData={setOriginalData}
              showModal={showModal}
              setShowModal={setShowModal}
              currentHoliday={currentHoliday}
              setCurrentHoliday={setCurrentHoliday}
              setHolidaysUpdated={setHolidaysUpdated}
              holidaysUpdated={holidaysUpdated}
            />
            <HolidayEditModal
              originalData={originalData}
              setOriginalData={setOriginalData}
              showModal={showModal}
              setShowModal={setShowModal}
              currentHoliday={currentHoliday}
              setCurrentHoliday={setCurrentHoliday}
              setHolidaysUpdated={setHolidaysUpdated}
              holidaysUpdated={holidaysUpdated}
            />
            <HolidayDeleteModal
              showModal={showModal}
              setShowModal={setShowModal}
              originalData={originalData}
              setOriginalData={setOriginalData}
              currentHoliday={currentHoliday}
              setHolidaysUpdated={setHolidaysUpdated}
              holidaysUpdated={holidaysUpdated}
            />
          </>
        )}
      </div>
    )
  );
}
