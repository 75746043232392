import React from 'react';
import MUIDataTable from 'mui-datatables';
import { TextField } from '@mui/material';
import styled, { createGlobalStyle } from 'styled-components';

export const LoanInstallmentsBgColors = {
  Gray: 'gray',
  White: 'white',
};

export const LoanInstallmentsComponentTypes = {
  View: 'view',
  EditValuesOnly: 'edit-values-only',
  EditDatesOnly: 'edit-dates-only',
  EditAll: 'edit-all',
};

const GrayBgHeader = styled.div`
  & .MuiTableHead-root,
  & .MuiTableHead-root * {
    background-color: ${(props) => props.color} !important;
  }

  & .MuiTableRow-root {
    background-color: ${(props) => props.color} !important;
  }
`;

const LoanInstallments = ({
  rowsData,
  displayType,
  backgroundColor,
  changeInstallmentsItem,
  installmentMissing,
  control,
}) => {
  const BgHexColor =
    backgroundColor === LoanInstallmentsBgColors.Gray ? '#e9e9e9' : '#f6f5f5';

  const GlobalStyle = createGlobalStyle`
	[class*='MUIDataTable-responsiveScrollMaxHeight'] {
  		max-height: 300px !important;
  		height: 300px !important;
 		background-color: ${BgHexColor} !important;
	}
  `;

  const shouldRenderValuesViewMode =
    displayType === LoanInstallmentsComponentTypes.View ||
    displayType === LoanInstallmentsComponentTypes.EditDatesOnly;

  const shouldRenderValuesEditMode =
    (displayType === LoanInstallmentsComponentTypes.EditValuesOnly ||
      displayType === LoanInstallmentsComponentTypes.EditAll) &&
    typeof changeInstallmentsItem !== 'undefined';

  const shouldRenderDatesViewMode =
    displayType === LoanInstallmentsComponentTypes.View ||
    displayType === LoanInstallmentsComponentTypes.EditValuesOnly;

  const shouldRenderDatesEditMode =
    (displayType === LoanInstallmentsComponentTypes.EditDatesOnly ||
      displayType === LoanInstallmentsComponentTypes.EditAll) &&
    typeof changeInstallmentsItem !== 'undefined';

  const columns = [
    {
      name: 'value',
      label: 'Installments',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, options) => {
          if (shouldRenderValuesViewMode) {
            return <div>{value}</div>;
          } else if (shouldRenderValuesEditMode) {
            return (
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => changeInstallmentsItem(e, options)}
                value={value}
                // error={indexOfInputError === options.rowIndex}
                // helperText={indexOfInputError === options.rowIndex ? `this installment field not correct ` : null}
              />
            );
          } else {
            return <div>wrong component config</div>;
          }
        },
      },
    },
    {
      name: 'day',
      label: 'Deduction Day',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, options) => {
          if (shouldRenderDatesViewMode) {
            return <div>{value}</div>;
          } else if (shouldRenderDatesEditMode) {
            return (
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => changeInstallmentsItem(e, options)}
                value={value}
                // error={indexOfInputError === options.rowIndex}
                // helperText={indexOfInputError === options.rowIndex ? `this installment field not correct ` : null}
              />
            );
          } else {
            return <div>wrong component config</div>;
          }
        },
      },
    },
  ];

  const BorderColor =
    typeof installmentMissing == 'undefined'
      ? ''
      : installmentMissing !== 0
        ? 'border border-danger'
        : 'border border-success';

  const TotalTextColor =
    typeof installmentMissing == 'undefined'
      ? ''
      : installmentMissing !== 0
        ? 'text-danger'
        : 'text-success';

  return (
    <>
      <GlobalStyle />
      <GrayBgHeader color={BgHexColor}>
        <MUIDataTable
          className={`shadow-none ${BorderColor}`}
          data={rowsData}
          columns={columns}
          options={{
            rowsPerPage: 999999999,
            filter: false,
            download: false,
            print: false,
            selectableRowsOnClick: false,
            search: false,
            viewColumns: false,
            selectableRowsHideCheckboxes: true,
            customFooter: () => <></>,
            responsive: 'scrollMaxHeight',
          }}
        />
      </GrayBgHeader>
      {control && (
        <div
          className={` w-100 px-3 py-2 d-flex justify-content-end align-items-center ${BorderColor}`}
          style={{ backgroundColor: BgHexColor }}
        >
          <div className="d-flex gap-2 align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <h5>Required Total :</h5>
              <h6>{control._formValues.value || 0}</h6>
            </div>
            <div
              className={`d-flex gap-2 align-items-center ms-4 ${TotalTextColor}`}
            >
              <h5>Current Total :</h5>
              <h6>
                {Number(installmentMissing) +
                  Number(control._formValues.value || 0)}
              </h6>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoanInstallments;
