import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as Requests from '../../../../Helpers/Requests';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';
import { Pages } from '../../../../Helpers/Constants';

export default function NursingScheduleManageDelete({
  onClose,
  selectedValue,
  open,
  renderedNursingScheduleRequest,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const [requestLoading, setRequestLoading] = React.useState(false);

  const handleDeleteNursingSchedule = (e) => {
    setRequestLoading(true);
    e.preventDefault();

    Requests.deleteNursingSchedule(
      renderedNursingScheduleRequest.user_id,
      renderedNursingScheduleRequest.id,
    )
      .then(() => {
        toast('Child Care was deleted successfully.', {
          type: 'success',
        });
        setTimeout(() => {
          window.location.replace(Pages.NursingScheduleManage.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleDeleteNursingSchedule} style={{ width: '500px' }}>
          <DialogTitle>Delete Child Care Request</DialogTitle>
          <DialogContent dividers>
            <List sx={{ pt: 0 }}>
              <div className="my-2">
                <div>
                  Are you sure you want to delete this Child Care request?
                </div>
                <b> This action can not be undone.</b>
              </div>
            </List>
          </DialogContent>
          <DialogActions className="d-flex justify-content-between mx-2">
            <button className="btn btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              disabled={requestLoading}
            >
              Confirm Delete
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
