import * as React from 'react';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import {
  getUsersMinimalInfo,
  handleRequestErrors,
  loginAsRequest,
} from '../../Helpers/Requests';
import { toast } from 'react-toastify';
export default function LoginAs() {
  const [usersData, setUsersData] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [requestLoading, setRequestLoading] = React.useState(false);

  React.useEffect(() => {
    getUsersMinimalInfo()
      .then((_usersData) => {
        setUsersData(_usersData);
      })
      .catch((err) => handleRequestErrors(err));
  }, []);

  const handleDropDownChange = (colName, newValueObj) => {
    setSelectedUser({
      [colName]: newValueObj?.id || '',
    });
  };

  const handleSubmit = () => {
    setRequestLoading(true);

    loginAsRequest(selectedUser.id)
      .then((response) => {
        if (response.data.data) {
          toast('login as user succeeded.', { type: 'success' });
          localStorage.setItem(
            'userToken',
            response.data.data.token.access_token,
          );
          setTimeout(() => {
            window.location.href = `/dashboard`;
          }, 1000);
        }
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <div className="ms-3">
        <div className="h4 mb-5 w-50 d-flex align-items-center">
          Login As User
        </div>
        {Boolean(usersData.length) && (
          <>
            <div style={{ width: `50rem` }}>
              <Autocomplete
                getOptionLabel={(option) => {
                  if (typeof option === 'object') {
                    return String(option.email);
                  } else {
                    const optionObj = _.find(usersData, {
                      id: option,
                    });
                    return optionObj ? String(optionObj.email) : option;
                  }
                }}
                isOptionEqualToValue={(optionObj, valueObj) => {
                  return optionObj.id == valueObj;
                }}
                value={selectedUser.id || null}
                options={usersData ? Object.values(usersData) : []}
                onChange={(e, newValue) => handleDropDownChange('id', newValue)}
                sx={{ width: 300 }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="User Name"
                      className="mb-1 mt-3 d-block"
                      fullWidth
                    />
                  );
                }}
              />
            </div>

            <div className="w-100 d-flex align-items-center mt-5">
              <button
                type="submit"
                className="btn btn-success ms-auto me-5"
                onClick={handleSubmit}
                disabled={requestLoading}
              >
                Login As
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
