import { configureStore } from '@reduxjs/toolkit';
import vacationsReducer from './slices/vacationsSlice';
import excusesReducer from './slices/excusesSlice';
import userSlice from './slices/userSlice';

const store = configureStore({
  reducer: {
    vacations: vacationsReducer,
    excuses: excusesReducer,
    currentUser: userSlice,
  },
});

export default store;
