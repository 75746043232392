import { ROLES, UnityLeadersRoles } from '../../Helpers/Constants';

export const STATUSES_EXCUSES = {
  Approved: 1,

  HrPendingApproval: 20,
  HrRejected: 21,

  PlanswiftPendingManagerApproval: 30,
  PlanswiftRejectedByManager: 31,

  RevitPendingManagerApproval: 40,
  RevitRejectedByManager: 41,

  UnityPendingTeamManagerApproval: 50,
  UnityRejectedByTeamManager: 51,
  UnityPendingLeaderApproval: 52,
  UnityRejectedByLeader: 53,

  DigitizingPendingManagerApproval: 60,
  DigitizingRejectedByManager: 61,
};

export const PENDING_STATUSES_EXCUSES = [
  STATUSES_EXCUSES.PlanswiftPendingManagerApproval,
  STATUSES_EXCUSES.RevitPendingManagerApproval,
  STATUSES_EXCUSES.UnityPendingTeamManagerApproval,
  STATUSES_EXCUSES.UnityPendingLeaderApproval,
  STATUSES_EXCUSES.DigitizingPendingManagerApproval,
  STATUSES_EXCUSES.DigitizingRejectedByManager,
  STATUSES_EXCUSES.HrPendingApproval,
];

export const INITIAL_PENDING_STATUSES_EXCUSES = [
  STATUSES_EXCUSES.PlanswiftPendingManagerApproval,
  STATUSES_EXCUSES.RevitPendingManagerApproval,
  STATUSES_EXCUSES.UnityPendingLeaderApproval,
  STATUSES_EXCUSES.DigitizingPendingManagerApproval,
];

export const FINISHED_STATUSES_EXCUSES = [
  STATUSES_EXCUSES.PlanswiftRejectedByManager,
  STATUSES_EXCUSES.RevitRejectedByManager,
  STATUSES_EXCUSES.UnityRejectedByTeamManager,
  STATUSES_EXCUSES.UnityRejectedByLeader,
  STATUSES_EXCUSES.DigitizingRejectedByManager,
  STATUSES_EXCUSES.HrRejected,
  STATUSES_EXCUSES.Approved,
];

export const getRoleInitialPendingStatuses = (roleName) => {
  const initialPendingStatuses = [...INITIAL_PENDING_STATUSES_EXCUSES];
  if (UnityLeadersRoles.includes(roleName)) {
    initialPendingStatuses.push(
      STATUSES_EXCUSES.UnityPendingTeamManagerApproval,
    );
  }
  if ([ROLES.HR].includes(roleName)) {
    initialPendingStatuses.push(STATUSES_EXCUSES.HrPendingApproval);
  }
  return initialPendingStatuses;
};

export const RequestsTableExcuseStatusesConstants = [
  {
    id: STATUSES_EXCUSES.Approved,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },

  {
    id: STATUSES_EXCUSES.HrPendingApproval,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
  {
    id: STATUSES_EXCUSES.HrRejected,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_EXCUSES.PlanswiftPendingManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_EXCUSES.PlanswiftRejectedByManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_EXCUSES.RevitPendingManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_EXCUSES.RevitRejectedByManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_EXCUSES.UnityPendingTeamManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_EXCUSES.UnityRejectedByTeamManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
  {
    id: STATUSES_EXCUSES.UnityPendingLeaderApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_EXCUSES.UnityRejectedByLeader,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },

  {
    id: STATUSES_EXCUSES.DigitizingPendingManagerApproval,
    style: {
      text: 'text-dark',
      bg: 'bg-warning',
    },
  },
  {
    id: STATUSES_EXCUSES.DigitizingRejectedByManager,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
];
