export const NonNumericCols = [
  'User ID',
  'User Email',
  'User ATL Name',
  'Created By',
  'Created At',
  'month',
  'half',
];

export const ColsToHide = [
  'User ID',
  'User Email',
  'User ATL Name',
  'Backfill (hrs)',
  'Grants (hrs)',
  'Added Missed Hours (hrs)',
  'Removed Extra Hours (hrs)',
  'Performance Bonus (le)',
  'Other Dues (le)',
  'Medical Insurance (le)',
  'Loans (le)',
  'Created By',
];

const removeHiddenColumns = (paymentObjects) => {
  return paymentObjects.map((paymentObject) => {
    for (const key in paymentObject) {
      if (ColsToHide.includes(key)) {
        delete paymentObject[key];
      }
    }

    return paymentObject;
  });
};

export const injectTotalColumns = (paymentObjects) => {
  return paymentObjects.map((paymentObject) => {
    if (
      paymentObject &&
      Array.isArray(paymentObject) &&
      paymentObject.length === 0
    ) {
      return paymentObject;
    } else {
      paymentObject = removeHiddenColumns([paymentObject])[0];

      let hrsSum = 0;
      let leSum = 0;

      for (const key in paymentObject) {
        if (NonNumericCols.includes(key)) continue;

        const value = parseFloat(paymentObject[key]);
        if (isNaN(value)) continue;

        if (key.includes('(le)')) {
          leSum += value;
        }
        if (key.includes('(hrs)')) {
          hrsSum += value;
        }
      }

      paymentObject['Total (hrs)'] = hrsSum.toFixed(2);

      // Move created at key to the end of the object if it exists
      if (paymentObject['Created At']) {
        const createdAt = paymentObject['Created At'];
        delete paymentObject['Created At'];
        paymentObject['Created At'] = createdAt;
      }

      return paymentObject;
    }
  });
};
