import React from 'react';
import _ from 'lodash';
import { getColNames } from '../../Users/helpers';

export const STATUSES_OVERTIME = {
  Approved: 1,
  Rejected: 2,
  Pending: 3,
};

export const FINISHED_STATUSES_OVERTIME = [
  STATUSES_OVERTIME.Approved,
  STATUSES_OVERTIME.Rejected,
];

export const RequestsTableOvertimeStatusesConstants = [
  {
    id: STATUSES_OVERTIME.Approved,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },
  {
    id: STATUSES_OVERTIME.Pending,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
  {
    id: STATUSES_OVERTIME.Rejected,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
];

export const TimeFields = {
  RequestedHours: {
    Name: 'requested_hours',
    Title: 'HH',
    MaxValue: 99,
    MaxLength: 2,
  },
  RequestedMinutes: {
    Name: 'requested_minutes',
    Title: 'MM',
    MaxValue: 59,
    MaxLength: 2,
  },
  ApprovedHours: {
    Name: 'approved_hours',
    Title: 'HH',
    MaxValue: 99,
    MaxLength: 2,
  },
  ApprovedMinutes: {
    Name: 'approved_minutes',
    Title: 'MM',
    MaxValue: 59,
    MaxLength: 2,
  },
};

export const overtimeRequestFormFields = {
  RequestedDay: {
    Name: 'requested_day',
    Title: 'Requested Day',
    PlaceHolder: 'Day',
  },
  RequestedDuration: {
    Name: 'requested_duration',
    Title: 'Requested Duration',
  },
  Reason: {
    Name: 'reason',
    Title: 'Reason (optional)',
    PlaceHolder: 'Type a Reason',
  },
  Type: {
    Name: 'type',
    Title: 'Type',
    PlaceHolder: 'type',
  },
};

export const overtimeManageFormFields = {
  ...overtimeRequestFormFields,
  ApprovedDay: {
    Name: 'approved_day',
    Title: 'Approved Day',
    PlaceHolder: 'Day',
  },
  ApprovedDuration: {
    Name: 'approved_duration',
    Title: 'Approved Duration',
  },
  Comment: {
    Name: 'comment',
    Title: 'Management Notes',
    PlaceHolder: 'Comment',
  },
  Type: {
    Name: 'type',
    Title: 'Type',
    PlaceHolder: 'type',
  },
};

export const overtimeRequestDateFields = {
  RequestedDay: overtimeManageFormFields.RequestedDay,
};

export const overtimeManageDateFields = {
  ...overtimeRequestDateFields,
  ApprovedDay: overtimeManageFormFields.ApprovedDay,
};

export const handleOvertimeInputFieldChange = (
  e,
  fieldName,
  timeFieldsData,
  setTimeFieldsData,
  formData,
  setFormData,
) => {
  if (getColNames(Object.values(TimeFields)).includes(fieldName)) {
    const fieldObj =
      _.find(Object.values(TimeFields), { Name: fieldName }) || {};

    e.target.value = e.target.value
      .replace(/[^0-9]/g, '')
      .slice(0, fieldObj.MaxLength);

    if (parseInt(e.target.value) > fieldObj.MaxValue)
      e.target.value = timeFieldsData[fieldName];

    setTimeFieldsData({
      ...timeFieldsData,
      [fieldName]: parseInt(e.target.value || 0),
    });
  }
  setFormData({
    ...formData,
    [fieldName]: e.target.value,
  });
};

export const overtimeTypes = [
  {
    id: 1,
    name: 'Overtime',
    class: 'badge bg-primary',
  },
  {
    id: 2,
    name: 'Backfill',
    class: 'badge bg-light border text-dark',
  },
];

export const createOvertimeTypeDiv = (typeId) => {
  const type =
    _.find(overtimeTypes, {
      id: typeId,
    }) || {};
  return <span className={`${type.class}`}>{type.name}</span>;
};

export const getOvertimeTypeStyleClass = (typeId) => {
  const type =
    _.find(overtimeTypes, {
      id: typeId,
    }) || {};
  return type.class;
};
