import React from 'react';
import _ from 'lodash';

export const MonthFirstDay = 5;

export const RecurringPaymentDateFields = {
  StartDate: 'start_date',
  EndDate: 'end_date',
};

export const STATUSES_RECURRING_PAYMENT = {
  Approved: 1,
  Rejected: 2,
  Pending: 3,
};

export const FINISHED_STATUSES_RECURRING_PAYMENT = [
  STATUSES_RECURRING_PAYMENT.Approved,
  STATUSES_RECURRING_PAYMENT.Rejected,
];

export const PENDING_STATUSES_RECURRING_PAYMENT = [
  STATUSES_RECURRING_PAYMENT.Pending,
];

export const RequestsTableRecurringPaymentStatusesConstants = [
  {
    id: STATUSES_RECURRING_PAYMENT.Approved,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },
  {
    id: STATUSES_RECURRING_PAYMENT.Rejected,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
  {
    id: STATUSES_RECURRING_PAYMENT.Pending,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
];

export const handleRecurringPaymentInputFieldChange = (
  e,
  fieldName,
  formData,
  setFormData,
) => {
  if (fieldName === 'value') {
    e.target.value = e.target.value
      .replace(/[^0-9.-]/g, '') // Allow digits, period, and negative sign
      .replace(/(?!^)-/g, '') // Remove any negative signs that are not at the beginning
      .replace(/(\..*)\./g, '$1') // Remove any extra periods than a single one
      .slice(0, 8); // Limit to the first 8 characters
  }

  setFormData({
    ...formData,
    [fieldName]: e.target.value,
  });
};

export const FrequencyTypes = {
  Monthly: {
    id: 1,
    label: 'Monthly',
    value: 'Monthly',
    class: 'badge bg-info text-dark',
  },
};

export const RecurringPaymentsTypes = {
  PrivateMedicalInsurance: {
    label: 'Private Medical Insurance',
    value: 'Private Medical Insurance',
  },
};

export const NegativeRecurringPaymentTypes = [
  RecurringPaymentsTypes.PrivateMedicalInsurance,
];

export const createRecurringPaymentTypeDiv = (typeId) => {
  const type =
    _.find(Object.values(FrequencyTypes), {
      id: typeId,
    }) || {};
  return <span className={`${type.class}`}>{type.label}</span>;
};

export const addRecurringPaymentTypeStylingAttributes = (type) => {
  const typeStyling =
    _.find(Object.values(FrequencyTypes), {
      id: type.id,
    }) || {};

  return { ...typeStyling, ...type };
};

export const getRecurringPaymentTypeStyleClass = (typeId) => {
  const type =
    _.find(FrequencyTypes, {
      id: typeId,
    }) || {};
  return type.class;
};
