import React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { Pages } from '../../../../Helpers/Constants';
import { toast } from 'react-toastify';
import { formRequest } from '../../../../Helpers/Requests';

export default function Approve({
  onClose,
  selectedValue,
  open,
  renderedLoanRequest,
}) {
  const [isEditFormFieldsLoaded, setIsEditFormFieldsLoaded] =
    React.useState(false);

  const [requestLoading, setRequestLoading] = React.useState(false);

  const [editRequestFormValues, setEditRequestFormValues] = React.useState({});

  const handleClose = () => {
    onClose(selectedValue);

    // Reset component status
    setEditRequestFormValues(getFormInitialValues());
  };

  const getFormInitialValues = () => {
    return {
      ...renderedLoanRequest,
    };
  };

  React.useEffect(() => {
    setEditRequestFormValues(getFormInitialValues());
    setIsEditFormFieldsLoaded(true);
  }, [renderedLoanRequest]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditRequestFormValues({
      ...editRequestFormValues,
      [name]: value,
    });
  };

  const handleEditRequestSubmit = (e) => {
    setRequestLoading(true);

    e.preventDefault();

    const formData = { ...editRequestFormValues };

    formRequest(`loans/${renderedLoanRequest.id}/approve`, 'put', formData)
      .then(() => {
        toast('Loan Approved successfully.', { type: 'success' });
        setTimeout(() => {
          window.location.replace(Pages.LoansManageRequests.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <form onSubmit={handleEditRequestSubmit} style={{ width: '500px' }}>
        <DialogTitle>Approve One Time Payment Request</DialogTitle>
        <List sx={{ pt: 0 }}>
          <DialogContent>
            <div className="d-flex mt-4">
              <div
                className="me-3 d-flex align-items-center"
                style={{ width: '30%' }}
              >
                Comment :
              </div>
              <div className="d-flex w-100">
                <TextField
                  key="comment"
                  label="Comment"
                  name="comment"
                  defaultValue={renderedLoanRequest.comment}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  fullWidth
                  margin="dense"
                />
              </div>
            </div>
          </DialogContent>
        </List>
        <DialogActions className="d-flex justify-content-between">
          <Button onClick={handleClose}>Close</Button>
          <button
            type="submit"
            className="btn btn-success me-3"
            disabled={requestLoading}
          >
            Confirm Approval
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
