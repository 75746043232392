import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default function Loading({
  size = '4rem',
  border = '1rem',
  color = '#000000',
  classes = '',
}) {
  return (
    <>
      <div className={`spinner-container ${classes}`}>
        <div
          className="loading-spinner"
          style={{
            width: size,
            height: size,
            border: `${border} solid #ffffff`,
            borderTop: `${border} solid ${color}`,
          }}
        ></div>
      </div>
    </>
  );
}

Loading.propTypes = {
  size: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.string,
};
