import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import * as Constants from '../../Helpers/Constants';
import logoWide from '../../assets/images/logo_wide.png';

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  const logoutUser = () => {
    handleCloseUserMenu();
    localStorage.removeItem('userToken');
    setLoggedIn(false);
    window.location.href = '/login';
  };

  useEffect(() => {
    const localUserData = Constants.userData || '';
    setUserData(localUserData);
    setLoggedIn(!!localUserData);
  }, [loggedIn]);

  return (
    <AppBar position="fixed">
      <Container maxWidth="xxl" style={{ padding: '0.3rem 5rem' }}>
        <Toolbar disableGutters>
          <Typography
            component={NavLink}
            to="/login"
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logoWide} alt="logo" style={{ width: '15rem' }} />
          </Typography>
          <Typography
            component={NavLink}
            to="/login"
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logoWide} alt="logo" style={{ width: '15rem' }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            {loggedIn ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircle
                      sx={{ color: 'white', fontSize: '2.5rem' }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <p className="mx-4">{userData.email || ''}</p>
                  <MenuItem
                    onClick={handleCloseUserMenu}
                    component={NavLink}
                    to="/dashboard"
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem
                    onClick={handleCloseUserMenu}
                    component={NavLink}
                    to="/profile"
                  >
                    profile
                  </MenuItem>
                  <MenuItem
                    onClick={logoutUser}
                    component={NavLink}
                    to="/login"
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button component={NavLink} to="/login" color="inherit">
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
