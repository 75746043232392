import React, { useRef, useState } from 'react';
import { useEffectAsync } from '../../Helpers/Helpers';
import { useSearchParams } from 'react-router-dom';
import { getHierarchy } from '../../Helpers/Requests';
import styled from 'styled-components';

const ChartContainer = styled.div`
  height: calc(100vh - (1rem + 64px));
  text-align: center;
  overflow: auto;
  position: relative;
  margin: -2rem -3rem -0rem -3rem;

  .orgchart .node {
    padding: 0px 10px;
    margin: 0px -8px 21px -8px;
  }
  .orgchart .node .title,
  .orgchart .node .content {
    width: 220px;
  }

  .orgchart .hierarchy::before {
    border-top: 2px solid rgba(0, 102, 153, 0.8);
  }

  .orgchart .node:not(:only-child)::after {
    background-color: rgba(0, 102, 153, 0.8);
  }

  .orgchart > ul > li > ul li > .node::before {
    background-color: rgba(0, 102, 153, 0.8);
  }

  .orgchart .node .title {
    background-color: rgba(0, 102, 153, 0.8);
    height: initial;
  }

  .orgchart .node .content {
    height: initial;
    border: 1px solid rgba(0, 102, 153, 0.8);
    line-height: 15px;
    padding-bottom: 3px;
  }

  .orgchart .nodes.vertical .hierarchy::after,
  .orgchart .nodes.vertical .hierarchy::before {
    border-color: rgba(0, 102, 153, 0.8);
  }

  .orgchart .node .title .parentNodeSymbol {
    display: none;
  }

  .orgchart {
    background-image: none;
  }
`;

export default function HierarchyPage() {
  const chartContainerRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [treeType, setTreeType] = useState(
    searchParams.get('treeType') || 'users',
  );

  useEffectAsync(async () => {
    const teamHierarchy = await getHierarchy(treeType);

    const orgChartInstance = window.$(chartContainerRef.current).orgchart({
      data: teamHierarchy,
      nodeTitle: 'title', // the data key that contains the title
      nodeContent: 'content', // the data key that contains the content
      pan: false,
      zoom: false,
      zoominLimit: 2,
      zoomoutLimit: 0.9,
    });

    // Cleanup on component unmount
    return () => {
      orgChartInstance.orgchart('destroy');
      window.$(chartContainerRef.current).empty();
    };
  }, [treeType]);

  return <ChartContainer ref={chartContainerRef} />;
}
