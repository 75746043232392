import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import Delete from './delete';
import { originalToStyled } from '../utils';
import { can } from '../../../../Helpers/Helpers';
import { PERMISSIONS } from '../../../../Helpers/Constants';
import Edit from './Edit';
import { DialogContent } from '@mui/material';
import LoanInstallments, {
  LoanInstallmentsBgColors,
  LoanInstallmentsComponentTypes,
} from '../../Request/components/LoanInstallments';

export default function Show({
  onClose,
  selectedValue,
  open,
  managedLoanRequest,
}) {
  const [renderedLoanRequest, setRenderedLoanRequest] = React.useState({});

  const [openDeleteRequestDialog, setOpenDeleteRequestDialog] =
    React.useState(false);

  const [openEditRequestDialog, setOpenEditRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Delete Request dialog //
  const handleDeleteRequestOpen = () => setOpenDeleteRequestDialog(true);
  const handleDeleteRequestClose = () => setOpenDeleteRequestDialog(false);
  // Delete Request dialog //

  // Edit Request dialog //
  const handleEditRequestOpen = () => setOpenEditRequestDialog(true);
  const handleEditRequestClose = () => setOpenEditRequestDialog(false);
  // Edit Request dialog //

  // Component did update
  React.useEffect(() => {
    // Update Loan request.
    const _renderedLoanRequest = originalToStyled([managedLoanRequest]);
    setRenderedLoanRequest(_renderedLoanRequest[0]);
  }, [managedLoanRequest]);

  return (
    <>
      {Object.keys(renderedLoanRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <Delete
              open={openDeleteRequestDialog}
              onClose={handleDeleteRequestClose}
              renderedLoanRequest={renderedLoanRequest}
            />
            <Edit
              open={openEditRequestDialog}
              onClose={handleEditRequestClose}
              renderedLoanRequest={renderedLoanRequest}
            />
            <DialogTitle>Loan Details</DialogTitle>
            <DialogContent dividers>
              <List sx={{ pt: 0 }}>
                <ListItem>
                  <ListItemText primary={<>ID : {renderedLoanRequest.id}</>} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>User Name : {renderedLoanRequest.user}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Email : {renderedLoanRequest.email}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Reason : {renderedLoanRequest.reason}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Value (LE) : {renderedLoanRequest.value}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>Approved at : {renderedLoanRequest.updated_at || ''}</>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span>
                        Approved By : {renderedLoanRequest.updated_by || ''}
                      </span>
                    }
                  />
                </ListItem>
                <LoanInstallments
                  rowsData={managedLoanRequest.installments}
                  displayType={LoanInstallmentsComponentTypes.View}
                  backgroundColor={LoanInstallmentsBgColors.White}
                />
              </List>
            </DialogContent>
            <DialogActions className="d-flex justify-content-between">
              <div className="d-flex justify-content-between w-100 mx-1 mb-1">
                {can([PERMISSIONS.DeleteLoans]) && (
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteRequestOpen()}
                  >
                    Delete
                  </button>
                )}
                <div className="d-flex justify-content-end w-100">
                  {/* {can([PERMISSIONS.UpdateLoans]) && (
                    <button
                      className="btn btn-warning"
                      onClick={() => handleEditRequestOpen()}
                    >
                      Edit Loan
                    </button>
                  )} */}
                  <button
                    className="btn btn-secondary ms-3 mb-1"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
