import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  extractShownUserData,
  UsersTableCols,
} from '../../components/Users/helpers';
import { formatFileLink, formatObjectKeys } from '../../Helpers/Helpers';
import { getUserById } from '../../Helpers/Requests';

export const getCurrentUser = createAsyncThunk(
  'user/getCurrentUser',
  async (userId) => {
    let userData = await getUserById(userId);
    userData = formatFileLink(userData);
    return userData;
  },
);

const initialState = {
  userData: {},
  formattedUserData: {},
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        const data = action.payload;
        let _formattedUserData = extractShownUserData(data);
        _formattedUserData = formatObjectKeys(
          _formattedUserData,
          UsersTableCols,
        );
        return {
          ...state,
          userData: data,
          formattedUserData: _formattedUserData,
          loading: false,
          error: null,
        };
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
