import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import * as NursingScheduleManageUtils from './NursingScheduleViewUtils';
import { TablesTypes } from '../../../../Helpers/Constants';
import NursingScheduleViewDeleteModal from './NursingScheduleDeleteModal';

export default function NursingScheduleViewShow({
  onClose,
  selectedValue,
  open,
  viewedNursingScheduleRequest,
  currentTable,
}) {
  const [renderedNursingScheduleRequest, setRenderedNursingScheduleRequest] =
    React.useState({});
  const [openDeleteRequestDialog, setOpenDeleteRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Component did update
  React.useEffect(() => {
    // Update nursingSchedule request.
    const _renderedNursingScheduleRequest =
      NursingScheduleManageUtils.originalToStyled([
        viewedNursingScheduleRequest,
      ]);
    setRenderedNursingScheduleRequest(_renderedNursingScheduleRequest[0]);
  }, [viewedNursingScheduleRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === TablesTypes.Pending) {
    dialogActions = (
      <>
        <div className="d-flex justify-content-between w-100">
          <div>
            <button
              className="ms-2 mb-2 btn btn-danger"
              onClick={() => setOpenDeleteRequestDialog(true)}
            >
              Delete Request
            </button>
          </div>
          <div>
            <button
              className="ms-auto me-2 mb-2 btn btn-secondary"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </>
    );
  } else if (currentTable.type === TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="ms-auto me-2">
          <Button onClick={handleClose}>Close</Button>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedNursingScheduleRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <NursingScheduleViewDeleteModal
              openApproveRequestDialog={openDeleteRequestDialog}
              setOpenDeleteRequestDialog={setOpenDeleteRequestDialog}
              renderedNursingScheduleRequest={renderedNursingScheduleRequest}
            />
            <DialogTitle>Child Care Request Details</DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>Request ID : {renderedNursingScheduleRequest.id}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>User Name : {renderedNursingScheduleRequest.user}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Email : {renderedNursingScheduleRequest.email}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Start Date : {renderedNursingScheduleRequest.start_date}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>End Date : {renderedNursingScheduleRequest.end_date}</>
                  }
                />
              </ListItem>
              {renderedNursingScheduleRequest.comment && (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Management Notes :{' '}
                        {renderedNursingScheduleRequest.comment || ''}
                      </>
                    }
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated At :{' '}
                      {renderedNursingScheduleRequest.updated_at || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <span>
                      Last Updated By :{' '}
                      {renderedNursingScheduleRequest.updated_by || ''}
                    </span>
                  }
                />
              </ListItem>
            </List>
            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
