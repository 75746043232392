export const STATUSES_LOANS = {
  Approved: 1,
  Rejected: 2,
  Pending: 3,
};

export const FINISHED_STATUSES_LOANS = [
  STATUSES_LOANS.Approved,
  STATUSES_LOANS.Rejected,
];

export const PENDING_STATUSES_LOANS = [STATUSES_LOANS.Pending];

export const RequestsTableLoanStatusesConstants = [
  {
    id: STATUSES_LOANS.Approved,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },
  {
    id: STATUSES_LOANS.Rejected,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
  {
    id: STATUSES_LOANS.Pending,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
];
