import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { FormControl, TextField } from '@mui/material';

import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import ExcusesBalanceTable from './ExcusesBalanceTable';
import { toast } from 'react-toastify';
export default function ExcusesRequestMain() {
  const [formData, setFormData] = React.useState({
    reason: '',
  });
  const [noBalance, setNoBalance] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [currentlyBooked, setCurrentlyBooked] = React.useState(0);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);

  const dateFields = {
    Date: {
      Name: 'date',
    },
  };

  // Initializing component
  React.useEffect(() => {
    setCurrentlyBooked(1);
    setFormData({
      ...formData,
      date: dayjs().format('YYYY-MM-DD'),
    });
  }, []);

  const handleReasonChange = (e) => {
    setFormData({
      ...formData,
      reason: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setRequestLoading(true);

    e.preventDefault();
    const defaultFormDataReason = formData.reason
      ? formData.reason
      : 'Reason Not Specified';
    const updatedFormData = {
      ...formData,
      reason: defaultFormDataReason,
    };
    Requests.getCurrentUser()
      .then((user) => {
        Requests.formRequest(
          `users/${user.id}/excuses`,
          'post',
          updatedFormData,
        )
          .then(() => {
            toast('Excuse Request Created Successfully.', { type: 'success' });
            setTimeout(() => {
              window.location.href = `/excuses`;
            }, 1000);
          })
          .catch((err) => {
            toast(err.response.data.message, { type: 'error' });
          })
          .finally(() => {
            setRequestLoading(false);
          });
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };

  return (
    <>
      {
        <>
          <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
            Request an Excuse
          </div>
          <FormControl className="w-100" variant="standard">
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="d-flex flex-column w-100">
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Reason (optional) :
                  </div>
                  <TextField
                    label="Type a Reason"
                    value={formData.reason}
                    onChange={handleReasonChange}
                    className="w-25"
                  ></TextField>
                </div>
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Date :
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={formData.date}
                      onChange={(date) =>
                        Helpers.handleDateFieldsChange(
                          date,
                          'date',
                          dateFields,
                          setInvalidDateValue,
                          formData,
                          setFormData,
                        )
                      }
                      inputFormat="DD-MM-YYYY"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <ExcusesBalanceTable
                selectedDay={formData.date}
                currentlyBooked={currentlyBooked}
                noBalance={noBalance}
                setNoBalance={setNoBalance}
                setRequestLoading={setRequestLoading}
              />
            </div>
            <div className="w-100 d-flex align-items-center mt-5 pt-5">
              <button
                type="submit"
                className="btn btn-success mt-4 ms-auto"
                onClick={(e) => handleSubmit(e)}
                disabled={
                  noBalance ||
                  !currentlyBooked ||
                  requestLoading ||
                  invalidDateValue
                }
              >
                Submit Request
              </button>
            </div>
          </FormControl>
        </>
      }
    </>
  );
}
