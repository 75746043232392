import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FormControl, TextField } from '@mui/material';

// Mui datatables Utils

export const getDatatableColumns = () => {
  const datatableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'code',
      label: 'Code',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Email : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Email"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'From',
      name: 'start_date',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `From : ${reversedDate}`;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'To',
      name: 'end_date',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `To : ${reversedDate}`;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'team',
      label: 'Team',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'normal_used',
      label: 'Total Paid (used)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'normal_remaining',
      label: 'Total Paid (remaining)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'urgent_used',
      label: 'Urgent (used)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'urgent_remaining',
      label: 'Urgent (remaining)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'sick_used',
      label: 'Sick (used)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'sick_remaining',
      label: 'Sick (remaining)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'unpaid_used',
      label: 'Unpaid (used)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'unpaid_remaining',
      label: 'Unpaid (remaining)',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'terminated',
      label: 'Terminated',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];

  return datatableColumns;
};

export const originalToMuiDatatable = (renderedReport) => {
  let datatableReport = [];
  datatableReport = renderedReport.map((element) => {
    let _updatedReport = {};
    _updatedReport = {
      id: element.id,
      code: element.code,
      email: element.email,
      team: element.team.name,
      role: element.role.display_name,
      normal_used: element.vacations_balance.normal.used,
      normal_remaining: element.vacations_balance.normal.remaining,
      urgent_used: element.vacations_balance.urgent.used,
      urgent_remaining: element.vacations_balance.urgent.remaining,
      sick_used: element.vacations_balance.sick.used,
      sick_remaining: element.vacations_balance.sick.remaining,
      unpaid_used: element.vacations_balance.unpaid.used,
      unpaid_remaining: element.vacations_balance.unpaid.remaining,
      terminated: element.terminated,
    };
    return _updatedReport;
  });

  return datatableReport;
};
// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array

  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};

export const originalToPrintable = (originalReport) => {
  const printableReport = originalReport.map((element) => {
    return {
      ID: element.id,
      Code: element.code,
      Name: element.name,
      Email: element.email,
      Team: element.team.name,
      Role: element.role.display_name,
      'Total Paid (Used)': element.vacations_balance.normal.used,
      'Total Paid (Remaining)': element.vacations_balance.normal.remaining,
      'Urgent Used': element.vacations_balance.urgent.used,
      'Urgent Remaining': element.vacations_balance.urgent.remaining,
      'Sick Used': element.vacations_balance.sick.used,
      'Sick Remaining': element.vacations_balance.sick.remaining,
      'Unpaid Used': element.vacations_balance.unpaid.used,
      'Unpaid Remaining': element.vacations_balance.unpaid.remaining,
      Terminated: element.terminated,
    };
  });
  return printableReport;
};
