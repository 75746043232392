import * as React from 'react';
import { Outlet } from 'react-router-dom';
import NoPermission from '../components/ErrorPages/NoPermission';
import { userHasAnyRole } from '../Helpers/Helpers';

export default function RoleProtectedRoutes({ roles }) {
  if (userHasAnyRole(roles)) {
    return <Outlet />;
  } else {
    return <NoPermission />;
  }
}
