import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Autocomplete, FormControl, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-toastify';

import * as Requests from '../../../Helpers/Requests';
import _ from 'lodash';
import {
  catchRequestError,
  handleDateFieldsChange,
  useEffectAsync,
} from '../../../Helpers/Helpers';
import dayjs from 'dayjs';
import { Pages } from '../../../Helpers/Constants';
import {
  FrequencyTypes,
  handleRecurringPaymentInputFieldChange,
  MonthFirstDay,
  NegativeRecurringPaymentTypes,
  RecurringPaymentDateFields,
  RecurringPaymentsTypes,
} from '../helpers/Constants';

export default function RecurringPaymentsRequestMain() {
  const [formData, setFormData] = React.useState({
    start_date: dayjs().date(MonthFirstDay).format('YYYY-MM-DD'),
    end_date: dayjs().date(MonthFirstDay).format('YYYY-MM-DD'),
    frequency: FrequencyTypes.Monthly.value,
    reason: RecurringPaymentsTypes.PrivateMedicalInsurance.value,
  });
  const [invalidFormFields, setInvalidFormFields] = React.useState(true);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});

  useEffectAsync(async () => {
    setUsersData(await catchRequestError(Requests.getStaff()));
  }, []);

  React.useEffect(() => {
    if (Object.keys(formData).length) {
      const RequiredFormFields = [
        'value',
        'start_date',
        'end_date',
        'frequency',
        'reason',
      ];

      const isInvalid = RequiredFormFields.some((field) => !formData[field]);

      setInvalidFormFields(isInvalid);
    }
  }, [formData]);

  const handleMonthChange = (date, fieldName) => {
    handleDateFieldsChange(
      date.date(MonthFirstDay), // set day to a safe day in the beginning of the month
      fieldName,
      RecurringPaymentDateFields,
      setInvalidDateValue,
      formData,
      setFormData,
    );
  };

  const handleDropDownChange = (colName, newValueObj) => {
    setSelectedUser({
      [colName]: newValueObj?.id || '',
    });
    setFormData({
      ...formData,
      employee_id: newValueObj?.id || '',
    });
  };

  const handleSubmit = (e) => {
    setRequestLoading(true);
    e.preventDefault();

    const formValues = { ...formData };
    // always negative input values if adjustment type is negative (ex: deduction)
    const NegativeAdjustmentTypesValues = NegativeRecurringPaymentTypes.map(
      (type) => type.value,
    );

    formValues.value = NegativeAdjustmentTypesValues.includes(formData.reason)
      ? -Math.abs(formValues.value)
      : Math.abs(formValues.value);

    Requests.requestRecurringPayment(formValues, formValues.employee_id)
      .then(() => {
        toast('Recurring Payment Requested Successfully.', {
          type: 'success',
        });
        setTimeout(() => {
          window.location.href = Pages.RecurringPaymentsManage.path();
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      {formData?.frequency && (
        <>
          <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
            Request Recurring Payment
          </div>
          <FormControl className="w-100" variant="standard">
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="d-flex flex-column w-100">
                <div className="d-flex">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Select Recurring Payment Type :
                  </div>
                  <TextField
                    select
                    label="Select a type"
                    value={formData.reason}
                    onChange={(e) =>
                      handleRecurringPaymentInputFieldChange(
                        e,
                        'reason',
                        formData,
                        setFormData,
                      )
                    }
                    className="w-25"
                  >
                    {Object.values(RecurringPaymentsTypes).map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Select Payment Frequency :
                  </div>
                  <TextField
                    select
                    label="Select a frequency"
                    value={formData.frequency}
                    onChange={(e) =>
                      handleRecurringPaymentInputFieldChange(
                        e,
                        'value',
                        formData,
                        setFormData,
                      )
                    }
                    className="w-25"
                  >
                    {Object.values(FrequencyTypes).map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                {formData.frequency === FrequencyTypes.Monthly.value && (
                  <>
                    <div className="d-flex mt-5">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        Start Month (included):
                      </div>
                      <DatePicker
                        label="Select a month"
                        views={['year', 'month']}
                        value={formData.start_date}
                        onChange={(date) =>
                          handleMonthChange(date, 'start_date')
                        }
                        inputFormat="MM/YYYY"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                    <div className="d-flex mt-5">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        End Month (included):
                      </div>
                      <DatePicker
                        label="Select a month"
                        views={['year', 'month']}
                        value={formData.end_date}
                        onChange={(date) => handleMonthChange(date, 'end_date')}
                        inputFormat="MM/YYYY"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </>
                )}
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Employee :
                  </div>
                  <Autocomplete
                    getOptionLabel={(option) => {
                      if (typeof option === 'object') {
                        return String(option.email);
                      } else {
                        const optionObj = _.find(usersData, {
                          id: option,
                        });
                        return optionObj ? String(optionObj.email) : option;
                      }
                    }}
                    isOptionEqualToValue={(optionObj, valueObj) => {
                      return optionObj.id == valueObj;
                    }}
                    value={selectedUser.id || null}
                    options={usersData ? Object.values(usersData) : []}
                    onChange={(e, newValue) =>
                      handleDropDownChange('id', newValue)
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={'Email'}
                          className="mb-1 d-block"
                          fullWidth
                        />
                      );
                    }}
                  />
                </div>
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Value (LE) :
                  </div>
                  <TextField
                    label={!formData.value ? 'Type a Value' : ''}
                    value={formData.value}
                    onChange={(e) =>
                      handleRecurringPaymentInputFieldChange(
                        e,
                        'value',
                        formData,
                        setFormData,
                      )
                    }
                    className="w-25"
                  ></TextField>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex align-items-center mt-5">
              <button
                type="submit"
                className="btn btn-success ms-auto me-5"
                onClick={(e) => handleSubmit(e)}
                disabled={
                  invalidFormFields || invalidDateValue || requestLoading
                }
              >
                Submit Request
              </button>
            </div>
          </FormControl>
        </>
      )}
    </>
  );
}
