import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FormControl, TextField } from '@mui/material';

// Mui datatables Utils

export const getDatatableColumns = (currentFilterFields) => {
  const datatableColumns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Email : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Email"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'From',
      name: 'start_date',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `From : ${reversedDate}`;
          },
          update: (filterList, filterPos, index) => filterList,
        },
        filterList: [currentFilterFields.start_date],
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'To',
      name: 'end_date',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `To : ${reversedDate}`;
          },
          update: (filterList, filterPos, index) => filterList,
        },
        filterList: [currentFilterFields.end_date],
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'team',
      label: 'Team',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'number_of_days_with_6_hrs_or_more',
      label: 'Days With 6 Hours Or More',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];

  return datatableColumns;
};

export const originalToMuiDatatable = (renderedReport) => {
  let datatableReport = [];
  datatableReport = renderedReport.map((element) => {
    let _updatedReport = {};
    _updatedReport = {
      user_id: element.user_id,
      email: element.email,
      role: element.user.roles[0].display_name,
      team: element.team.name,
      number_of_days_with_6_hrs_or_more:
        element.number_of_days_with_6_hrs_or_more,
    };
    return _updatedReport;
  });

  return datatableReport;
};
// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array

  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};

export const originalToPrintable = (originalReport) => {
  const printableReport = originalReport.map((element) => {
    return {
      'user id': element.user.id,
      'user email': element.user.email,
      'user role': element.user.roles[0].display_name,
      'user team': element.user.roles[0].team.name,
      'days with 6 hours or more': element.number_of_days_with_6_hrs_or_more,
    };
  });

  return printableReport;
};
