import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as VacationsViewUtils from './VacationsViewUtils.jsx';

export default function VacationViewModal(props) {
  return (
    <>
      <Modal
        className="h-100 d-flex align-items-center justify-content-center"
        show={props.showModal === 'view'}
        onHide={() => props.setShowModal(null)}
      >
        <div style={{ minWidth: '400px', maxWidth: '500px', width: '40vw' }}>
          <Modal.Header>
            <Modal.Title>View Vacation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal">
              {VacationsViewUtils.getViewModalRowsData(
                props.currentVacation,
              ).map((vacation) => {
                return (
                  <div className="form-group row" key={vacation.id}>
                    <label className="col-sm-3 mb-3 control-label">
                      {vacation.label}
                    </label>
                    <div className="col-sm-9">{vacation.content}</div>
                  </div>
                );
              })}
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="me-2"
              variant="secondary"
              onClick={() => props.setShowModal(null)}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
