import React from 'react';
import Login from '../components/Auth/Login';
import Profile from '../components/Auth/Profile';
import DashboardMain from '../components/Dashboard/DashboardMain';
import NotFound from '../components/ErrorPages/NotFound';
import HolidaysMain from '../components/Holidays/HolidaysMain';
import ListUsers from '../components/Users/ListUsers';
import ListStatusUpdates from '../components/StatusUpdates/ListStatusUpdates';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileViewer from '../components/Files/FileViewer';
import OvertimeManageMain from '../components/Overtime/manage';
import VacationsViewMain from '../components/Vacations/VacationsView/VacationsViewMain';
import VacationsManageMain from '../components/Vacations/VacationsManage/VacationsManageMain';
import VacationsReportTable from '../components/Vacations/VacationsReport/VacationsReportTable';
import ExcusesRequestMain from '../components/Excuses/ExcusesRequest/ExcusesRequestMain';
import ExcusesViewMain from '../components/Excuses/ExcusesView/ExcusesViewMain';
import ExcusesManageMain from '../components/Excuses/ExcusesManage/ExcusesManageMain';
import OvertimeViewMain from '../components/Overtime/view';
import LoginAs from '../components/LoginAs';
import WorkLogsPage from '../components/WorkLogs/Index';
import PaymentAdjustmentViewMain from '../components/PaymentAdjustment/PaymentAdjustmentView/PaymentAdjustmentViewMain';
import PaymentAdjustmentRequestMain from '../components/PaymentAdjustment/PaymentAdjustmentRequest/PaymentAdjustmentRequestMain';
import PaymentAdjustmentManageMain from '../components/PaymentAdjustment/PaymentAdjustmentManage/PaymentAdjustmentManageMain';
import PaymentAdjustmentRequestedMain from '../components/PaymentAdjustment/PaymentAdjustmentRequested/PaymentAdjustmentRequestedMain';
import RecurringPaymentsRequestMain from '../components/RecurringPayments/RecurringPaymentsRequest/RecurringPaymentsRequestMain';
import RecurringPaymentsViewMain from '../components/RecurringPayments/RecurringPaymentsView/RecurringPaymentsViewMain';
import RecurringPaymentsManageMain from '../components/RecurringPayments/RecurringPaymentsManage/RecurringPaymentsManageMain';
import LoansRequestPage from '../components/Loans/Request';
import LoansListPage from '../components/Loans/List';
import ManageRunningLoans from '../components/Loans/ManageRunning';
import NursingHoursTable from '../components/Nursing/NursingHours/NursingHoursTable';
import ManageLoanRequests from '../components/Loans/ManageRequests';
import NursingScheduleViewMain from '../components/Nursing/NursingSchedule/NursingScheduleView/NursingScheduleViewMain';
import NursingScheduleRequestMain from '../components/Nursing/NursingSchedule/NursingScheduleRequest/NursingScheduleRequestMain';
import NursingScheduleManageMain from '../components/Nursing/NursingSchedule/NursingScheduleManage/NursingScheduleManageMain';
import PaymentReportPage from '../components/Reports/Payment';
import PaymentSettingPage from '../components/Setting/Payment';
import PayrollViewTable from '../components/Payroll/PayrollView';
import VacationsCreateMain from '../components/Vacations/VacationsCreate/VacationsCreateMain';
import PaymentAdjustmentCreateMain from '../components/PaymentAdjustment/PaymentAdjustmentCreate/PaymentAdjustmentCreateMain';
import { UsersList } from '../components/Users/helpers';
import PayrollHistory from '../components/Payroll/PayrollHistory';
import OvertimeCreateMain from '../components/Overtime/create';
import Evaluations from '../components/Evaluations';
import EvaluationTake from '../components/Evaluations/take';
import { EVALUATION_SHOW_ACTIONS } from '../components/Evaluations/helpers/consts';
import AttendancesListPage from '../components/Attendances/List';
import HierarchyPage from '../components/Hierarchy';
import YearlyJournalReportPage from '../components/Reports/Vacations/YearlyJournal';

// User data
// eslint-disable-next-line no-var
export var userData = {};
export const setCurrentUser = (updatedUserData) => {
  userData = updatedUserData;
  userData.token = updatedUserData.token.access_token;

  // setting user name
  const namesArray = userData.name.split(' ');
  userData.name =
    (namesArray[0] || '') +
    (namesArray[0] && namesArray[1] ? ' ' : '') +
    (namesArray[1] || '');

  // setting user title
  userData.title = `${userData.role.display_name} - ${userData.team.name}`;
};

export const sidebarWidth = '250px';

export const TEAMS = {
  AdminTeam: { Label: 'Admin Team', Value: 1 },
  HrTeam: { Label: 'HR Team', Value: 2 },
  Planswift: { Label: 'Estimating Team', Value: 3 },
  Revit: { Label: 'Revit Team', Value: 4 },
  Unity: { Label: 'Unity Team', Value: 5 },
  Accounting: { Label: 'Accounting Team', Value: 6 },
  ItTeam: { Label: 'IT Team', Value: 7 },
  OfficeTeam: { Label: 'Office Team', Value: 8 },
  Digitizing: { Label: 'Digitizing Team', Value: 9 },
};

export const PERMISSIONS = {
  LoginAsAdminActions: 'login-as-admin-actions',
  ManageUsers: 'manage-users',
  ViewAnyUsers: 'view-any-users',
  ViewUsers: 'view-users',
  CreateUsers: 'create-users',
  UpdateUsers: 'update-users',
  DeleteUsers: 'delete-users',
  ManageStatusUpdates: 'manage-status-updates',
  ViewAnyStatusUpdates: 'view-any-status-updates',
  ViewStatusUpdates: 'view-status-updates',
  CreateStatusUpdates: 'create-status-updates',
  UpdateStatusUpdates: 'update-status-updates',
  DeleteStatusUpdates: 'delete-status-updates',
  ManageVacations: 'manage-vacations',
  ViewAnyVacations: 'view-any-vacations',
  ViewVacations: 'view-vacations',
  CreateVacations: 'create-vacations',
  UpdateVacations: 'update-vacations',
  DeleteVacations: 'delete-vacations',
  ApproveVacations: 'approve-vacations',
  RefuseVacations: 'refuse-vacations',
  ReportVacations: 'report-vacations',
  ManageHolidays: 'manage-holidays',
  ViewAnyHolidays: 'view-any-holidays',
  ViewHolidays: 'view-holidays',
  CreateHolidays: 'create-holidays',
  UpdateHolidays: 'update-holidays',
  DeleteHolidays: 'delete-holidays',
  ManageOvertime: 'manage-overtime',
  ViewAnyOvertime: 'view-any-overtime',
  ViewOvertime: 'view-overtime',
  CreateOvertime: 'create-overtime',
  UpdateOvertime: 'update-overtime',
  DeleteOvertime: 'delete-overtime',
  ApproveOvertime: 'approve-overtime',
  RefuseOvertime: 'refuse-overtime',
  ManageWorkLogsExtraInfo: 'manage-worklogs-extra-info',
  ViewAnyWorkLogsExtraInfo: 'view-any-worklogs-extra-info',
  ViewWorkLogsExtraInfo: 'view-worklogs-extra-info',
  CreateWorkLogsExtraInfo: 'create-worklogs-extra-info',
  UpdateWorkLogsExtraInfo: 'update-worklogs-extra-info',
  DeleteWorkLogsExtraInfo: 'delete-worklogs-extra-info',
  ManagePaymentAdjustment: 'manage-payment-adjustments',
  ViewAnyPaymentAdjustment: 'view-any-payment-adjustments',
  ViewPaymentAdjustment: 'view-payment-adjustments',
  CreatePaymentAdjustment: 'create-payment-adjustments',
  UpdatePaymentAdjustment: 'update-payment-adjustments',
  DeletePaymentAdjustment: 'delete-payment-adjustments',
  ApprovePaymentAdjustment: 'approve-payment-adjustments',
  RefusePaymentAdjustment: 'refuse-payment-adjustments',
  RequestForOthersPaymentAdjustment: 'request-for-others-payment-adjustments',
  ManageRecurringPayments: 'manage-recurring-payments',
  ViewAnyRecurringPayments: 'view-any-recurring-payments',
  ViewRecurringPayments: 'view-recurring-payments',
  CreateRecurringPayments: 'create-recurring-payments',
  UpdateRecurringPayments: 'update-recurring-payments',
  DeleteRecurringPayments: 'delete-recurring-payments',
  ApproveRecurringPayments: 'approve-recurring-payments',
  RefuseRecurringPayments: 'refuse-recurring-payments',
  ManageNursingSchedule: 'manage-nursing-schedule',
  ViewAnyNursingSchedule: 'view-any-nursing-schedule',
  ViewNursingSchedule: 'view-nursing-schedule',
  CreateNursingSchedule: 'create-nursing-schedule',
  UpdateNursingSchedule: 'update-nursing-schedule',
  DeleteNursingSchedule: 'delete-nursing-schedule',
  ApproveNursingSchedule: 'approve-nursing-schedule',
  RefuseNursingSchedule: 'refuse-nursing-schedule',
  ViewAnyNursingHours: 'view-any-nursing-hours',
  ViewNursingHours: 'view-nursing-hours',
  ManageLoans: 'manage-loans',
  ViewAnyLoans: 'view-any-loans',
  ViewLoans: 'view-loans',
  CreateLoans: 'create-loans',
  UpdateLoans: 'update-loans',
  DeleteLoans: 'delete-loans',
  ApproveLoans: 'approve-loans',
  RefuseLoans: 'refuse-loans',
  ViewPaymentReport: 'view-payment-report',
  ManagePaymentReport: 'manage-payment-report-setting',
  ViewPayrollHistory: 'view-payroll-history',
  ViewAnyPayrollHistory: 'view-any-payroll-history',
  CreatePayrollHistory: 'create-payroll-history',
  ManagePayrollHistory: 'manage-payroll-history',
  ViewCompanyHierarchy: 'view-company-hierarchy',
  ViewYearlyJournalReportVacations: 'view-yearly-journal-report-vacations',
};

export const ROLES = {
  Admin: 'admin',
  HR: 'hr',
  Accounting: 'accounting',
  IT: 'it',
  Staff: 'staff',
  ExecutiveManager: 'executive_manager',

  PlanswiftTeamManager: 'planswift_team_manager',
  PlanswiftTestingTeam: 'planswift_testing_team',
  PlanswiftReviewHead: 'planswift_review_head',
  PlanswiftEstHead: 'planswift_est_head',
  PlanswiftPerformanceAnalyst: 'planswift_performance_analyst',
  PlanswiftTrainingManager: 'planswift_training_manager',
  PlanswiftTrainer: 'planswift_trainer',
  PlanswiftEstimatorPt: 'planswift_estimator_pt',
  PlanswiftSwitched: 'planswift_switched',
  PlanswiftDivisionHead: 'planswift_division_head',
  PlanswiftRevLeader: 'planswift_rev_leader',
  PlanswiftEstLeader: 'planswift_est_leader',
  PlanswiftTerminated: 'planswift_terminated',
  PlanswiftReviewer: 'planswift_reviewer',
  PlanswiftEstimator: 'planswift_estimator',
  PlanswiftEstimatorElite: 'planswift_estimator_elite',
  PlanswiftTrainee: 'planswift_trainee',
  PlanswiftQaMaterialCalc: 'planswift_qa_material_calc',

  RevitTeamLeader: 'revit_team_leader',
  RevitManager: 'revit_manager',
  RevitAssignedGroupHead: 'revit_assigned_group_head',
  RevitTrainer: 'revit_trainer',
  RevitGroupHead: 'revit_group_head',
  RevitReviewerSenior: 'revit_reviewer_senior',
  RevitReviewerTrainee: 'revit_reviewer_trainee',
  RevitModeler: 'revit_modeler',
  RevitReviewer1: 'revit_reviewer_1',
  RevitReviewer2: 'revit_reviewer_2',
  RevitNewTrainee: 'revit_new_trainee',

  UnityTeamManager: 'unity_team_manager',
  UnityManagerAssistant: 'unity_manager_assistant',
  UnityReviewerLeader: 'unity_reviewer_leader',
  UnityArtistLeader: 'unity_artist_leader',
  UnityReviewer2: 'unity_reviewer_2',
  UnityReviewer1: 'unity_reviewer_1',
  UnityReviewerTrainee: 'unity_reviewer_trainee',
  UnityArtist2: 'unity_artist_2',
  UnityArtist1: 'unity_artist_1',
  UnityArtistTrainee: 'unity_artist_trainee',

  DigitizingTeamManager: 'digitizing_team_manager',
  DigitizingReviewerSenior: 'digitizing_reviewer_senior',
  DigitizingReviewer: 'digitizing_reviewer',
  DigitizingDigitizer: 'digitizing_digitizer',
  DigitizingTerminated: 'digitizing_terminated',
};

export const SystemManagersRoles = [
  ROLES.HR,
  ROLES.Admin,
  ROLES.ExecutiveManager,
];

export const TeamsHighManagers = [
  ROLES.PlanswiftTeamManager,
  ROLES.RevitManager,
  ROLES.UnityTeamManager,
  ROLES.DigitizingTeamManager,
];

export const PlanswiftManagersRoles = [
  ROLES.PlanswiftTeamManager,
  ROLES.PlanswiftDivisionHead,
  ROLES.PlanswiftTrainingManager,
];

export const PlanswiftUnderManagersRoles = [
  ROLES.PlanswiftDivisionHead,
  ROLES.PlanswiftTrainingManager,
];

export const RevitManagersRoles = [
  ROLES.ExecutiveManager,
  ROLES.RevitManager,
  ROLES.RevitTeamLeader,
  ROLES.RevitReviewerSenior,
];

export const UnityLeadersRoles = [
  ROLES.UnityArtistLeader,
  ROLES.UnityReviewerLeader,
];

export const UnityManagersRoles = [
  ROLES.UnityTeamManager,
  ROLES.UnityManagerAssistant,
];

export const DigitizingManagersRoles = [
  ROLES.DigitizingTeamManager,
  ROLES.DigitizingReviewerSenior,
];

export const TablesTypes = {
  Finished: 'finished',
  Pending: 'pending',
};

// Requests Tables section
export const getManagedRequestsTables = (title, actionType = 'Requests') => {
  return [
    {
      id: 0,
      selectName: `Pending ${actionType}`,
      titleName: `Pending ${title} ${actionType}`,
      type: TablesTypes.Pending,
    },
    {
      id: 1,
      selectName: `Finished ${actionType}`,
      titleName: `Finished ${title} ${actionType}`,
      type: TablesTypes.Finished,
    },
  ];
};

export const CurrentYear = new Date().getFullYear();
export const CurrentYearDate = `${CurrentYear}-01-01`;
export const NextYearDate = `${CurrentYear + 1}-01-01`;

export const Pages = {
  Dashboard: {
    Title: 'Dashboard',
    path: () => `/dashboard`,
    Element: <DashboardMain />,
    sidebarProps: {
      displayText: 'Dashboard',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  VacationsView: {
    Title: 'My Vacations',
    path: () => `/vacations`,
    Element: <DashboardMain />,
    sidebarProps: {
      displayText: 'My Vacations',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  RequestVacation: {
    Title: 'Request Vacation',
    path: () => `/vacations/request`,
    Element: <VacationsCreateMain />,
    sidebarProps: {
      displayText: 'Request Vacation',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  CreateVacation: {
    Title: 'Create Vacation for User',
    path: () => `/vacations/create`,
    Element: <VacationsCreateMain isActionOnUserBehalf={true} />,
    sidebarProps: {
      displayText: 'Create Vacation for User',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  Holidays: {
    Title: 'View Holidays',
    path: () => `/holidays`,
    Element: <HolidaysMain />,
    sidebarProps: {
      displayText: 'View Holidays',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  VacationsManage: {
    Title: 'Manage Vacations',
    path: () => `/vacations/requests`,
    Element: <VacationsManageMain />,
    sidebarProps: {
      displayText: 'Manage Vacations',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  UserVacations: {
    Title: 'Vacations',
    path: (userId) => `/users/${userId}/vacations`,
    Element: <VacationsViewMain />,
  },
  ExcusesView: {
    Title: 'My Excuses',
    path: () => `/excuses`,
    Element: <ExcusesViewMain />,
    sidebarProps: {
      displayText: 'My Excuses',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  RequestExcuse: {
    Title: 'Request Excuse',
    path: () => `/excuses/request`,
    Element: <ExcusesRequestMain />,
    sidebarProps: {
      displayText: 'Request Excuse',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  ExcusesManage: {
    Title: 'Manage Excuses',
    path: () => `/excuses/requests`,
    Element: <ExcusesManageMain />,
    sidebarProps: {
      displayText: 'Manage Excuses',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  UserExcuses: {
    Title: 'Excuses',
    path: (userId) => `/users/${userId}/excuses`,
    Element: <ExcusesViewMain />,
  },
  OvertimeView: {
    Title: 'My Extra Hours',
    path: () => `/extra-hours`,
    Element: <OvertimeViewMain />,
    sidebarProps: {
      displayText: 'My Extra Hours',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  RequestOvertime: {
    Title: 'Request Extra Hours',
    path: () => `/extra-hours/request`,
    Element: <OvertimeCreateMain />,
    sidebarProps: {
      displayText: 'Request Extra Hours',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  CreateOvertime: {
    Title: 'Create Extra Hours for User',
    path: () => `/extra-hours/create`,
    Element: <OvertimeCreateMain isActionOnUserBehalf={true} />,
    sidebarProps: {
      displayText: 'Create Extra Hours for User',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  OvertimeManage: {
    Title: 'Manage Extra Hours',
    path: () => `/extra-hours/requests`,
    Element: <OvertimeManageMain />,
    sidebarProps: {
      displayText: 'Manage Extra Hours',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  OneTimePaymentsView: {
    Title: 'My One Time Payments',
    path: () => `/one-time-payments`,
    Element: <PaymentAdjustmentViewMain />,
    sidebarProps: {
      displayText: 'My One Time Payments',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  OneTimePaymentsRequested: {
    Title: 'Follow Requested One Time Payments',
    path: () => `/one-time-payments/requested`,
    Element: <PaymentAdjustmentRequestedMain />,
    sidebarProps: {
      displayText: 'Follow Requested One Time Payments',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  PaymentAdjustmentRequest: {
    Title: 'Request One Time Payment',
    path: () => `/one-time-payments/request`,
    Element: <PaymentAdjustmentRequestMain />,
    sidebarProps: {
      displayText: 'Request One Time Payment',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  PaymentAdjustmentCreate: {
    Title: 'Create One Time Payment',
    path: () => `/one-time-payments/create`,
    Element: <PaymentAdjustmentCreateMain />,
    sidebarProps: {
      displayText: 'Create One Time Payment',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  PaymentAdjustmentManage: {
    Title: 'Manage One Time Payments',
    path: () => `/one-time-payments/requests`,
    Element: <PaymentAdjustmentManageMain />,
    sidebarProps: {
      displayText: 'Manage One Time Payments',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  RecurringPaymentsView: {
    Title: 'My Recurring Payments',
    path: () => `/recurring-payments`,
    Element: <RecurringPaymentsViewMain />,
    sidebarProps: {
      displayText: 'My Recurring Payments',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  RecurringPaymentsRequest: {
    Title: 'Request Recurring Payment',
    path: () => `/recurring-payments/request`,
    Element: <RecurringPaymentsRequestMain />,
    sidebarProps: {
      displayText: 'Request Recurring Payment',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  RecurringPaymentsManage: {
    Title: 'Manage Recurring Payments',
    path: () => `/recurring-payments/requests`,
    Element: <RecurringPaymentsManageMain />,
    sidebarProps: {
      displayText: 'Manage Recurring Payments',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  LoansList: {
    Title: 'My Loans',
    path: () => `/loans`,
    Element: <LoansListPage />,
    sidebarProps: {
      displayText: 'My Loans',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  LoansRequest: {
    Title: 'Request Loan',
    path: () => `/loans/request`,
    Element: <LoansRequestPage />,
    sidebarProps: {
      displayText: 'Request Loan',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  LoansManageRunning: {
    Title: 'Manage Running Loans',
    path: () => `/loans/running`,
    Element: <ManageRunningLoans />,
    sidebarProps: {
      displayText: 'Manage Running Loans',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  LoansManageRequests: {
    Title: 'Manage Loans Requests',
    path: () => `/loans/requests`,
    Element: <ManageLoanRequests />,
    sidebarProps: {
      displayText: 'Manage Loan Requests',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  WorkLogsView: {
    Title: 'My Work Logs',
    path: () => `/worklogs`,
    Element: <WorkLogsPage />,
    sidebarProps: {
      displayText: 'My Work Logs',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  UsersAll: {
    Title: 'Active Users',
    path: () => `/all-users`,
    Element: <ListUsers UsersList={UsersList.ALL} />,
    sidebarProps: {
      displayText: 'Active Users',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  UsersTerminated: {
    Title: 'Terminated Users',
    path: () => `/terminated-users`,
    Element: <ListUsers UsersList={UsersList.TERMINATED} />,
    sidebarProps: {
      displayText: 'Terminated Users',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  VacationsReport: {
    Title: 'Vacations',
    path: () => `/vacations/report`,
    Element: <VacationsReportTable />,
    sidebarProps: {
      displayText: 'Vacations',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  NursingHours: {
    Title: 'Child Care Hours',
    path: () => `/child-care-hours/report`,
    Element: <NursingHoursTable />,
    sidebarProps: {
      displayText: 'Child Care Hours',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  PayrollView: {
    Title: 'My Payrolls',
    path: () => `/payroll`,
    Element: <PayrollViewTable />,
    sidebarProps: {
      displayText: 'My Payroll',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  VacationsYearlyJournal: {
    Title: 'Vacations Yearly Journal',
    path: () => `/vacations/yearly-journal`,
    Element: <YearlyJournalReportPage />,
    sidebarProps: {
      displayText: 'Vacations Yearly Journal',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  PaymentReport: {
    Title: 'Payroll Invoice',
    path: () => `/payment/report`,
    Element: <PaymentReportPage />,
    sidebarProps: {
      displayText: 'Payroll',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  PayrollHistory: {
    Title: 'Payroll History',
    path: () => `/payroll-history`,
    Element: <PayrollHistory />,
    sidebarProps: {
      displayText: 'Payroll History',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  NursingScheduleRequested: {
    Title: 'Follow Requested Child Care Hours',
    path: () => `/child-care-hours/requested`,
    Element: <NursingScheduleViewMain />,
    sidebarProps: {
      displayText: 'Follow Requested Child Care Hours',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  NursingScheduleRequest: {
    Title: 'Request Child Care Hours',
    path: () => `/child-care-hours/request`,
    Element: <NursingScheduleRequestMain />,
    sidebarProps: {
      displayText: 'Request Child Care Hours',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  NursingScheduleManage: {
    Title: 'Manage Child Care Hours Requests',
    path: () => `/child-care-hours/requests`,
    Element: <NursingScheduleManageMain />,
    sidebarProps: {
      displayText: 'Manage Child Care Hours Requests',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  PaymentSetting: {
    Title: 'Payment',
    path: () => `/setting/report`,
    Element: <PaymentSettingPage />,
    sidebarProps: {
      displayText: 'Payment',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  Hierarchy: {
    Title: 'Hierarchy',
    path: () => `/hierarchy`,
    Element: <HierarchyPage />,
    sidebarProps: {
      displayText: 'Hierarchy',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  Evaluations: {
    Title: 'Appraisals',
    path: () => `/appraisals`,
    Element: <Evaluations />,
  },
  EvaluationTake: {
    Title: 'Take Appraisal',
    path: (evaluationId) => `/appraisals/${evaluationId}/take`,
    Element: <EvaluationTake mode={EVALUATION_SHOW_ACTIONS.Take} />,
  },
  EvaluationView: {
    Title: 'View Appraisal',
    path: (evaluationId) => `/appraisals/${evaluationId}/view`,
    Element: <EvaluationTake mode={EVALUATION_SHOW_ACTIONS.Show} />,
  },
  EvaluationEdit: {
    Title: 'Edit Appraisal',
    path: (evaluationId) => `/appraisals/${evaluationId}/edit`,
    Element: <EvaluationTake mode={EVALUATION_SHOW_ACTIONS.Edit} />,
  },
  StatusUpdates: {
    Title: 'Status Updates',
    path: (userId) => `/users/${userId}/status-updates`,
    Element: <ListStatusUpdates />,
    sidebarProps: {
      displayText: 'Status Updates',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  Profile: {
    Title: 'Profile',
    path: () => `/profile`,
    Element: <Profile />,
    sidebarProps: {
      displayText: 'Profile',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  LoginAs: {
    Title: 'Login As',
    path: () => `/login-as`,
    Element: <LoginAs />,
    sidebarProps: {
      displayText: 'Login As',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  Files: {
    Title: 'Files',
    path: () => `/files/:folder/:file`,
    Element: <FileViewer />,
  },
  Login: {
    Title: 'Login',
    path: () => `/login`,
    Element: <Login />,
  },
  NotFound: {
    Title: 'Not Found',
    path: () => `*`,
    Element: <NotFound />,
  },
  Attendances: {
    Title: 'My Attendances',
    path: () => `/attendances`,
    Element: <AttendancesListPage />,
    sidebarProps: {
      displayText: 'My Attendances',
      icon: <FileDownloadOutlinedIcon />,
    },
  },
};

export const CallerComponent = {
  View: 'view',
  Manage: 'manage',
};
