import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';

export default function Edit({
  onClose,
  open,
  renderedLoanRequest,
  selectedValue,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const [requestLoading, setRequestLoading] = React.useState(false);

  const handleUpdateLoan = (e) => {
    setRequestLoading(true);
    e.preventDefault();
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleUpdateLoan} style={{ width: '500px' }}>
          <DialogTitle>Edit Loan</DialogTitle>
          <DialogContent dividers>
            <List sx={{ pt: 0 }}>
              <div className="my-2">
                <div>Edit code goes here </div>
              </div>
            </List>
          </DialogContent>
          <DialogActions className="d-flex justify-content-between mx-2">
            <button
              type="button"
              className="btn btn-secondary"
              disabled={requestLoading}
              onClick={handleClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-warning"
              disabled={requestLoading}
            >
              Confirm Edit
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
