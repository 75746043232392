import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

export const getDatatableColumns = (currentFilterFields) => {
  const datatableColumns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Email : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Email"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'Month',
      name: 'month',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Month : ${value}`,
          update: (filterList, filterPos, index) => filterList,
        },
        filterList: [currentFilterFields.month],
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={['year', 'month']}
                    label="Month and Year"
                    openTo="month"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      const formattedDate = date
                        ? date.format('YYYY-MM')
                        : null;
                      filterList[index][0] = formattedDate;
                      onChange(filterList[index], index, column);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'Half',
      name: 'half',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Half : ${value}`,
          update: (filterList, filterPos, index) => filterList,
        },
        filterList: [currentFilterFields.half],
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <InputLabel id="half-select-label">Half</InputLabel>
                <Select
                  labelId="half-select-label"
                  id="half-select"
                  value={filterList[index][0] || ''}
                  label="Age"
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  <MenuItem value="first">First</MenuItem>
                  <MenuItem value="second">Second</MenuItem>
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'User ID',
      label: 'User ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'User Email',
      label: 'User Email',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'ATL (hrs)',
      label: 'ATL (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Paid Vacations (hrs)',
      label: 'Paid Vacations (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'PTO (hrs)',
      label: 'PTO (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'OT (hrs)',
      label: 'OT (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Backfill (hrs)',
      label: 'Backfill (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Monthly Bonus (hrs)',
      label: 'Monthly Bonus (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Grants (hrs)',
      label: 'Grants (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Added Missed Hours (hrs)',
      label: 'Added Missed Hours (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Removed Extra Hours (hrs)',
      label: 'Removed Extra Hours (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Performance Bonus (le)',
      label: 'Performance Bonus (le)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Other Dues (le)',
      label: 'Other Dues (le)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Medical Insurance (le)',
      label: 'Medical Insurance (le)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Loans (le)',
      label: 'Loans (le)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Deductions (le)',
      label: 'Deductions (le)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Child Care Hours (hrs)',
      label: 'Child Care Hours (hrs)',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Created At',
      label: 'Created At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Created By',
      label: 'Created By',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return datatableColumns;
};

export const originalToPrintable = (originalRequests) => {
  return originalRequests.map((element) => {
    return {
      'User ID': element['User ID'],
      'User Email': element['User Email'],

      'ATL (hrs)': element['ATL (hrs)'],
      'Paid Vacations (hrs)': element['Paid Vacations (hrs)'],
      'PTO (hrs)': element['PTO (hrs)'],
      'OT (hrs)': element['OT (hrs)'],
      'Backfill (hrs)': element['Backfill (hrs)'],
      'Monthly Bonus (hrs)': element['Monthly Bonus (hrs)'],
      'Grants (hrs)': element['Grants (hrs)'],
      'Added Missed Hours (hrs)': element['Added Missed Hours (hrs)'],
      'Removed Extra Hours (hrs)': element['Removed Extra Hours (hrs)'],
      'Performance Bonus (le)': element['Performance Bonus (le)'],
      'Other Dues (le)': element['Other Dues (le)'],
      'Medical Insurance (le)': element['Medical Insurance (le)'],
      'Loans (le)': element['Loans (le)'],
      'Deductions (le)': element['Deductions (le)'],
      'Child Care Hours (hrs)': element['Child Care Hours (hrs)'],

      'Created At': element['Created At'],
      'Created By': element['Created By'],
    };
  });
};

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array

  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};
