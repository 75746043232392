import * as React from 'react';
import { Button } from '@mui/material';

import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import * as RecurringPaymentsViewUtils from './RecurringPaymentsViewUtils';
import RecurringPaymentsViewShow from './RecurringPaymentsViewShow';

import MUIDataTable from 'mui-datatables';
import { userData } from '../../../Helpers/Constants';

export default function RecurringPaymentsViewTable({ currentTable }) {
  const IdCellIndex = 0;

  // Requests data variables
  const [
    originalRecurringPaymentRequests,
    setOriginalRecurringPaymentRequests,
  ] = React.useState([]);
  const [
    datatableRecurringPaymentRequests,
    setDatatableRecurringPaymentRequests,
  ] = React.useState([]);
  // Requests data variables

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  let currentTableState = {};
  let currentFilters = [];

  const [currentFilterFields, setCurrentFilterFields] = React.useState({});
  // Table effects variables

  // Pagination variables
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 5,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (updatedRowsPerPageValue) => {
    setController({
      ...controller,
      rowsPerPage: updatedRowsPerPageValue,
      page: 0,
    });
  };
  // Pagination variables

  // Mui datatables variables
  const options = {
    selectableRows: 'none',
    count: requestsCount,
    page: controller.page,
    rowsPerPage: controller.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: false,
    filterType: 'textField',
    onRowClick: (row) => {
      handleViewedRequestOpen(row[IdCellIndex]);
    },
    // controlling download content
    onDownload: (buildHead, buildBody, columns, data) => {
      Requests.getRecurringPaymentRequests(
        currentTable.type,
        null,
        null,
        currentFilterFields,
        userData.id,
      )
        .then((recurringPaymentRequests) => {
          const tableData = recurringPaymentRequests.data;
          const printableTableData =
            RecurringPaymentsViewUtils.originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'Recurring_Payment_Requests.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
    // setting row style
    setRowProps: () => ({
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    }),
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    // setting Head cell style
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      return style;
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(newTableState.page);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(newTableState.rowsPerPage);
          break;
      }
      currentTableState = newTableState;
    },
    // Handle changing the filter from the chips
    onFilterChange: (column, newFilters, type) => {
      const applyFilters = () => newFilters;
      if (newFilters !== currentFilters) {
        handleFilterSubmit(applyFilters);
        currentFilters = newFilters;
      }

      // Reset the pagination to the first page
      setController((prev) => ({ ...prev, page: 0 }));
    },
  };
  // Mui datatables

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    Requests.getRecurringPaymentRequests(
      currentTable.type,
      controller.page,
      controller.rowsPerPage,
      currentFilterFields,
      userData.id,
    )
      .then((requests) => {
        setOriginalRecurringPaymentRequests(requests.data);
        setRequestsCount(requests.meta.total);
        setCurrentColumns(RecurringPaymentsViewUtils.getDatatableColumns());
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [controller, currentTable]);
  // Component did mount.

  // update styled and datatable requests whenever fetched requests change
  React.useEffect(() => {
    const _styledRecurringPaymentRequests =
      RecurringPaymentsViewUtils.originalToStyled(
        originalRecurringPaymentRequests,
      );
    const _datatableRecurringPaymentRequests =
      RecurringPaymentsViewUtils.styledToMuiDatatable(
        _styledRecurringPaymentRequests,
        handleViewedRequestOpen,
      );
    setDatatableRecurringPaymentRequests(_datatableRecurringPaymentRequests);
  }, [originalRecurringPaymentRequests]);
  // update styled and datatable requests whenever fetched requests change

  // Handling apply filters button action
  const handleFilterSubmit = (applyFilters) => {
    const filterFields = RecurringPaymentsViewUtils.createFilterFields(
      currentTableState,
      applyFilters,
    );

    setCurrentFilterFields(filterFields);

    Requests.getRecurringPaymentRequests(
      currentTable.type,
      controller.page,
      controller.rowsPerPage,
      filterFields,
      userData.id,
    )
      .then((filteredRequests) => {
        setOriginalRecurringPaymentRequests(filteredRequests.data);
        setRequestsCount(filteredRequests.meta.total);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  // Handling apply filters button action

  // View RecurringPayment Request section
  const [viewedRequestLoaded, setViewedRequestLoaded] = React.useState(false);
  const [openViewedRequestDialog, setOpenViewedRequestDialog] =
    React.useState(false);
  const [viewedRecurringPaymentRequest, setViewedRecurringPaymentRequest] =
    React.useState({});

  const handleViewedRequestOpen = (requestId) => {
    Requests.getRecurringPaymentRequest(requestId)
      .then((recurringPaymentRequest) => {
        setViewedRecurringPaymentRequest(recurringPaymentRequest);
        setViewedRequestLoaded(true);
        setOpenViewedRequestDialog(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  const handleViewedRequestClose = () => setOpenViewedRequestDialog(false);
  // View RecurringPayment Request section

  return (
    <>
      {viewedRequestLoaded && (
        <RecurringPaymentsViewShow
          open={openViewedRequestDialog}
          onClose={handleViewedRequestClose}
          viewedRecurringPaymentRequest={viewedRecurringPaymentRequest}
          currentTable={currentTable}
        />
      )}
      <MUIDataTable
        title={'My Recurring Payments'}
        data={datatableRecurringPaymentRequests}
        columns={currentColumns}
        options={options}
      />
    </>
  );
}
