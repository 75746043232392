import React from 'react';
import styled from 'styled-components';
import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import * as PayrollHistoryUtils from './utils';
import MUIDataTable from 'mui-datatables';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

const HideChipCloseStyledDiv = styled.div`
  .MuiChip-deleteIcon {
    display: none;
  }
  .MuiChip-root {
    margin-right: 0.3rem;
  }
`;

export default function PayrollHistory() {
  const [originalPayrollHistoryData, setOriginalPayrollHistoryData] =
    React.useState([]);

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);

  const [currentFilterFields, setCurrentFilterFields] = React.useState({
    month: dayjs().startOf('month').format('YYYY-MM'),
    half: dayjs().date() <= 15 ? 'first' : 'second',
  });

  // Pagination variables
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 5,
  });

  // MUI datatables options
  const options = {
    pagination: true,
    selectableRows: 'none',
    count: requestsCount,
    page: controller.page,
    rowsPerPage: controller.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: true,
    filterType: 'textField',
    onChangePage: (page) => {
      setController((prev) => ({ ...prev, page }));
    },
    onChangeRowsPerPage: (rowsPerPage) => {
      setController((prev) => ({ ...prev, rowsPerPage, page: 0 }));
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      Requests.listPayrollHistory(null, null, currentFilterFields)
        .then((response) => {
          const tableData = response.data;
          const printableTableData =
            PayrollHistoryUtils.originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'Payroll_History.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      return style;
    },
  };

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    setOriginalPayrollHistoryData([]);
    Requests.listPayrollHistory(
      controller.page,
      controller.rowsPerPage,
      currentFilterFields,
    )
      .then((response) => {
        setOriginalPayrollHistoryData(response.data);
        setRequestsCount(response.meta.total);
        setCurrentColumns(
          PayrollHistoryUtils.getDatatableColumns(currentFilterFields),
        );
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [controller, currentFilterFields]);

  const handleFilterSubmit = (applyNewFilters) => {
    const filterFields = PayrollHistoryUtils.createFilterFields(
      { columns: currentColumns },
      applyNewFilters,
    );

    setCurrentFilterFields(filterFields);

    setController((prev) => ({ ...prev, page: 0 }));
  };

  return (
    <HideChipCloseStyledDiv>
      <MUIDataTable
        title={'Payroll History Details'}
        data={originalPayrollHistoryData}
        columns={currentColumns}
        options={options}
      />
    </HideChipCloseStyledDiv>
  );
}
