import React from 'react';
import { Dialog, DialogActions, DialogTitle, List } from '@mui/material';

export default function ConfirmationModal({
  openConfirmationDialog,
  setOpenConfirmationDialog,
  requestLoading,
  actionHandler,
  dialogTitle,
  dialogContent,
}) {
  return (
    <>
      <Dialog
        onClose={() => setOpenConfirmationDialog(false)}
        open={openConfirmationDialog}
      >
        <div style={{ width: '500px' }}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <List sx={{ pt: 0 }}>{dialogContent}</List>
          <DialogActions className="d-flex justify-content-between mx-1">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setOpenConfirmationDialog(false)}
            >
              Close
            </button>
            <button
              className="btn btn-warning"
              disabled={requestLoading}
              onClick={actionHandler}
            >
              Confirm
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
