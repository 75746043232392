export const IdCellIndex = 0;

export const WorklogsTableCols = {
  ID: { Name: 'id', Label: 'ID', Type: 'text' },
  Task: { Name: 'task', Label: 'Task', Type: 'text' },
  Description: {
    Name: 'description',
    Label: 'Description',
    Type: 'text',
    Width: 18.5,
  },
  DurationDecimal: {
    Name: 'duration_decimal',
    Label: 'Duration (Decimal)',
    Type: 'text',
  },
  Stage: {
    Name: 'stage',
    Label: 'Stage',
    Options: {
      InitialModel: { id: 'Initial Model', name: 'Initial Model' },
      DesignOptions: { id: 'Design Options', name: 'Design Options' },
      Phases: { id: 'Phases', name: 'Phases' },
    },
  },

  // Hidden Columns
  User: { Name: 'user', Label: 'User', Type: 'text' },
  Email: { Name: 'email', Label: 'Email', Type: 'text' },
  TeamId: { Name: 'team_id', Label: 'Team ID', Type: 'text' },
  Project: { Name: 'project', Label: 'Project', Type: 'text' },
  Tags: { Name: 'tags', Label: 'Tags', Type: 'text' },
  StartDate: { Name: 'start_date', Label: 'Start Date', Type: 'text' },
  CreatedAt: { Name: 'created_at', Label: 'Created At', Type: 'text' },
  UpdatedAt: { Name: 'updated_at', Label: 'Updated At', Type: 'text' },
};

export const hiddenCols = [
  WorklogsTableCols.ID,
  WorklogsTableCols.User,
  WorklogsTableCols.Email,
  WorklogsTableCols.TeamId,
  WorklogsTableCols.Project,
  WorklogsTableCols.Tags,
  WorklogsTableCols.StartDate,
  WorklogsTableCols.CreatedAt,
  WorklogsTableCols.UpdatedAt,
];

export const nonEditableCols = [
  WorklogsTableCols.ID,
  WorklogsTableCols.Task,
  WorklogsTableCols.Description,
  WorklogsTableCols.DurationDecimal,

  ...hiddenCols,
];

export const DropDownCols = [WorklogsTableCols.Stage];

export const muiTableColsConf = [
  {
    colDetails: WorklogsTableCols.ID,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.Task,
    isFilterable: false,
    isSortable: true,
    isShown: true,
  },
  {
    colDetails: WorklogsTableCols.Description,
    isFilterable: false,
    isSortable: true,
    isShown: true,
  },
  {
    colDetails: WorklogsTableCols.DurationDecimal,
    isFilterable: false,
    isSortable: true,
    isShown: true,
  },
  {
    colDetails: WorklogsTableCols.Stage,
    isFilterable: false,
    isSortable: true,
    isShown: true,
  },
  {
    colDetails: WorklogsTableCols.User,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.Email,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.TeamId,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.Project,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.Tags,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.StartDate,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.CreatedAt,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
  {
    colDetails: WorklogsTableCols.UpdatedAt,
    isFilterable: false,
    isSortable: false,
    isShown: false,
  },
];

export const getTableOptions = (handleRowClick) => {
  return {
    selectableRowsHideCheckboxes: true,
    print: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25],
    filterType: 'multiselect',
    responsive: 'standard',
    downloadOptions: {
      filename: 'worklogs_extra_info.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onRowClick: (row) => {
      handleRowClick(row);
    },
    setRowProps: () => ({
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    }),
  };
};
