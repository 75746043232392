import React, { useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Pages, userData } from '../../../../Helpers/Constants';
import * as Requests from '../../../../Helpers/Requests';
import { toast } from 'react-toastify';
import LoanInstallments, {
  LoanInstallmentsBgColors,
  LoanInstallmentsComponentTypes,
} from './LoanInstallments';

const ValuesValidationError = {
  invalid_type_error: 'Value must be a positive number',
};

const Loans = () => {
  const [firstPaymentDueMonth, setFirstPaymentDueMonth] = useState(
    dayjs()
      .month(dayjs().month() + 1)
      .date(5),
  );
  const [halfOfMonth, setHalfOfMonth] = useState(1);
  const [rowsData, setRowsData] = useState([]);
  const [indexOfInputError, setIndexOfInputError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [installmentMissing, setInstallmentMissing] = useState(0);

  const schema = z
    .object({
      reason: z
        .string({
          errorMap: (issue, _ctx) => {
            return { message: 'Reason is required' };
          },
        })
        .min(1),
      value: z.number(ValuesValidationError),
      installmentsCount: z
        .number(ValuesValidationError)
        .positive(ValuesValidationError)
        .int(ValuesValidationError),
    })
    .required({
      reason: true,
      value: true,
      installmentsCount: true,
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });
  watch((data) => {
    if (data.value && data.installmentsCount) {
      handleInstallmentsCount(data, null, halfOfMonth);
    }
  });

  const selectCurrentHalf = (index, half) => {
    const isFirstHalf = half === 1;

    if (isFirstHalf) return index % 2 === 0 ? 5 : 20;
    else if (!isFirstHalf) return index % 2 === 0 ? 20 : 5;
  };

  function generateDates(currentDate, loopCount, half) {
    if (typeof loopCount !== 'number' || loopCount <= 0) {
      throw new Error('Invalid loop count. Expected a positive integer.');
    }

    // eslint-disable-next-line prefer-const
    let currentDay = dayjs(currentDate, 'YYYY-MM-DD');

    // Generate loop using Day.js functionality
    const dates = [];
    for (let i = 0; i < loopCount; i++) {
      currentDay = currentDay.date(selectCurrentHalf(i, half));
      dates.push(currentDay.format('YYYY-MM-DD'));
      if (selectCurrentHalf(i, half) === 20) {
        currentDay = currentDay.add(1, 'month');
      }
    }
    return dates;
  }
  const handleInstallmentsCount = (
    { value, installmentsCount },
    installmentDay,
    half,
  ) => {
    const singleInstallment = Math.round(value / installmentsCount);
    const installmentCollection = [];
    // eslint-disable-next-line prefer-const
    let currentMonth = dayjs(installmentDay || firstPaymentDueMonth).format(
      'YYYY-MM-DD',
    );
    const dateResult = generateDates(currentMonth, installmentsCount, half);

    for (let i = 0; i < dateResult.length; i++) {
      installmentCollection.push({
        day: dateResult[i],
        value: singleInstallment,
      });
    }
    installmentCollection[0].value =
      singleInstallment - (singleInstallment * installmentsCount - value);
    setRowsData(installmentCollection);
    setIndexOfInputError(null);
    setInstallmentMissing(0);
  };

  const handleDate = (e) => {
    const day = halfOfMonth === 1 ? 5 : 20;
    const date = dayjs(e).date(day).valueOf();
    setFirstPaymentDueMonth(date);
    if (control._formValues.value && control._formValues.installmentsCount) {
      handleInstallmentsCount(control._formValues, date, halfOfMonth);
    }
  };

  const handleHalfOfMonth = (e) => {
    setHalfOfMonth(e.target.value);
    const day = e.target.value === 1 ? 5 : 20;
    let date;
    setFirstPaymentDueMonth((prev) => {
      date = dayjs(prev).date(day);
      return date;
    });
    if (control._formValues.value && control._formValues.installmentsCount) {
      handleInstallmentsCount(control._formValues, date, e.target.value);
    }
  };

  const changeInstallmentsItem = (e, { tableData, rowData, rowIndex }) => {
    rowData[0] = Number(e.target.value);

    const addKeyToList = tableData.map((item) => ({
      value: item[0],
      day: item[1],
    }));
    setRowsData(addKeyToList);
    const checkTotalInstallments = tableData.reduce(
      (acc, obj) => acc + obj[0],
      0,
    );
    if (checkTotalInstallments !== control._formValues.value) {
      setIndexOfInputError(rowIndex);
      setInstallmentMissing(checkTotalInstallments - control._formValues.value);
    } else {
      setIndexOfInputError(null);
      setInstallmentMissing(0);
    }
  };

  const onSubmit = ({ reason, value }) => {
    if (userData.id) {
      setIsLoading(true);
      Requests.requestLoans(
        { reason, value, installments: rowsData },
        userData.id,
      )
        .then(() => {
          toast('Loan Requested Successfully.', { type: 'success' });
          setTimeout(() => {
            window.location.replace(Pages.LoansList.path());
          }, 1000);
        })
        .catch((err) => {
          toast(err.response.data.message, { type: 'error' });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Loan Value"
            variant="outlined"
            min={1}
            error={errors?.value?.message}
            helperText={errors?.value?.message}
            {...register('value', { valueAsNumber: true })}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            inputMode="numeric"
            label="Installments Count"
            variant="outlined"
            error={errors?.installmentsCount?.message}
            helperText={errors?.installmentsCount?.message}
            {...register('installmentsCount', { valueAsNumber: true })}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            label="First Payment Due Month"
            views={['year', 'month']}
            openTo="month"
            renderInput={(params) => <TextField {...params} fullWidth />}
            value={firstPaymentDueMonth}
            onChange={handleDate}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            label="Select Month Half"
            fullWidth
            value={halfOfMonth}
            onChange={handleHalfOfMonth}
          >
            <MenuItem value={1}>First half of the Month</MenuItem>
            <MenuItem value={2}>Second half of the Month</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Reason"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            error={errors?.reason?.message}
            helperText={errors?.reason?.message}
            {...register('reason')}
          />
        </Grid>
        <Grid item xs={12}>
          <LoanInstallments
            rowsData={rowsData}
            displayType={LoanInstallmentsComponentTypes.EditValuesOnly}
            backgroundColor={LoanInstallmentsBgColors.Gray}
            changeInstallmentsItem={changeInstallmentsItem}
            installmentMissing={installmentMissing}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="w-100 d-flex align-items-center my-4">
            <button
              type="submit"
              className="btn btn-success ms-auto me-5"
              disabled={indexOfInputError != null || isLoading}
            >
              Submit Request
            </button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Loans;
