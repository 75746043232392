import * as React from 'react';

import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import EditStatusUpdate from './EditStatusUpdate';
import { DialogContent, Menu, MenuItem } from '@mui/material';
import { can } from '../../Helpers/Helpers';
import { PERMISSIONS } from '../../Helpers/Constants';
import { StatusUpdatesTableCols } from './helpers';
import * as Requests from '../../Helpers/Requests';
import { toast } from 'react-toastify';

export default function ManageStatusUpdate({
  open,
  onClose,
  requestedUser,
  selectedValue,
  managedStatusUpdateData,
  formattedStatusUpdateData,
  tableUpdated,
  setTableUpdated,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const disabledFields = [StatusUpdatesTableCols.User.Name];
  const hiddenFields = [StatusUpdatesTableCols.ID.Label];

  // Edit StatusUpdate dialog //
  const [openEditStatusUpdateDialog, setOpenEditStatusUpdateDialog] =
    React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleEditStatusUpdateOpen = () => {
    handleCloseMenu();
    setOpenEditStatusUpdateDialog(true);
  };

  const handleEditStatusUpdateClose = () => {
    handleClose();
    setOpenEditStatusUpdateDialog(false);
  };
  // Edit StatusUpdate dialog //

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    setRequestLoading(true);

    Requests.deleteStatusUpdate(
      managedStatusUpdateData.user.id,
      managedStatusUpdateData.id,
    )
      .then((response) => {
        toast(response.data.message, { type: 'success' });
        setTableUpdated(!tableUpdated);
        handleClose();
        handleCloseDeleteDialog();
      })
      .catch((error) => {
        Requests.handleRequestErrors(error);
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
      <EditStatusUpdate
        open={openEditStatusUpdateDialog}
        onClose={handleEditStatusUpdateClose}
        managedStatusUpdateData={managedStatusUpdateData}
        tableUpdated={tableUpdated}
        setTableUpdated={setTableUpdated}
      />
      <div className="d-flex justify-content-between align-items-center me-2">
        <DialogTitle>Status Update Data</DialogTitle>
        <div className="adminpanel-button" onClick={handleOpenMenu}>
          <MoreVertIcon />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {can([PERMISSIONS.UpdateStatusUpdates]) && (
          <MenuItem onClick={handleEditStatusUpdateOpen}>
            <EditIcon className="me-2" />
            <div className="me-3">Edit Info</div>
          </MenuItem>
        )}

        {can([PERMISSIONS.DeleteStatusUpdates]) && (
          <MenuItem onClick={handleOpenDeleteDialog}>
            <DeleteOutlineIcon className="me-2" />
            <div className="me-3">Delete</div>
          </MenuItem>
        )}
      </Menu>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {Object.keys(formattedStatusUpdateData).map((key) => {
            return (
              <>
                {!hiddenFields.includes(key) && (
                  <ListItem key={key}>
                    <ListItemText
                      primary={`${key} : `}
                      style={{ flex: 'none', marginRight: '0.5rem' }}
                    />
                    {formattedStatusUpdateData[key] ? (
                      <div>{`${formattedStatusUpdateData[key]}`}</div>
                    ) : (
                      <div
                        className={`badge bg-secondary`}
                        style={{ padding: '0.30rem 0.5rem' }}
                      >
                        Unspecified
                      </div>
                    )}
                  </ListItem>
                )}
              </>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions
        className="d-flex justify-content-end"
        sx={{
          position: 'sticky',
          bottom: 0,
          py: 2,
          px: 3,
          bgcolor: 'background.default',
        }}
      >
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete this record.
        </DialogContent>
        <DialogActions
          className="d-flex justify-content-between"
          sx={{
            position: 'sticky',
            bottom: 0,
            py: 2,
            px: 3,
            bgcolor: 'background.default',
          }}
        >
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            disabled={requestLoading}
            onClick={handleDelete}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}
