import React from 'react';
import CurrentPayrollTable from './current';
import PayrollHistory from './history';

export default function PayrollViewTable() {
  return (
    <>
      <CurrentPayrollTable />
      <PayrollHistory />
    </>
  );
}
