import React from 'react';

import Loans from './components/Loans';

export default function LoansRequestPage() {
  return (
    <>
      <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
        Request A Loan
      </div>
      <Loans />
    </>
  );
}
