import { muiTableColsConf } from './consts';

export const getTableCols = () => {
  return createTableCols(muiTableColsConf);
};

export const getColsNames = () => {
  return getTableCols().map((tableCol) => {
    return tableCol.name;
  });
};

const createTableCols = (tableCols) => {
  return tableCols.map((tableCol) => {
    return createTableCol(...Object.values(tableCol));
  });
};

const createTableCol = (
  colDetails,
  isFilterable,
  isSortable,
  isShown,
  setCellProps,
  setCellHeaderProps,
) => {
  return {
    name: colDetails.Name,
    label: colDetails.Label,
    options: {
      filter: isFilterable,
      sort: isSortable,
      display: isShown,
      setCellProps,
      setCellHeaderProps,
    },
  };
};

export const mergeColsNamesWithRowData = (colsNames, data) => {
  const dataObj = {};

  colsNames.forEach((colName, colIndex) => {
    dataObj[colName] = data[colIndex];
  });

  return dataObj;
};
