import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as Requests from '../../Helpers/Requests';
import { toast } from 'react-toastify';

export default function DashboardDeleteModal(props) {
  const [requestLoading, setRequestLoading] = React.useState(false);

  const deleteVacation = () => {
    setRequestLoading(true);

    Requests.getCurrentUser()
      .then((user) => {
        const vacationId = props.currentVacation.id;
        Requests.deleteVacation(user.id, vacationId)
          .then(() => {
            props.setVacationsUpdated(!props.vacationsUpdated);
            toast('Vacation was deleted successfully.', { type: 'success' });
            props.setShowModal(null);
          })
          .catch((err) => {
            toast(err.response.data.message, { type: 'error' });
          })
          .finally(() => {
            setRequestLoading(false);
          });
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };

  return (
    <>
      <Modal
        className="h-100 d-flex align-items-center justify-content-center"
        show={props.showModal === 'delete'}
        onHide={() => props.setShowModal('view')}
      >
        <div style={{ minWidth: '400px', maxWidth: '500px', width: '40vw' }}>
          <Modal.Header>
            <Modal.Title>Delete Vacation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to delete this vacation?</div>
            <b> This action can not be undone.</b>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="me-2"
              variant="secondary"
              onClick={() => props.setShowModal('view')}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => deleteVacation()}
              disabled={requestLoading}
            >
              Confirm Delete
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
