import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import {
  getEvaluation,
  handleRequestErrors,
  submitEvaluationAnswers,
} from '../../Helpers/Requests';
import { useEffectAsync } from '../../Helpers/Helpers';
import { StyledSurveyContainer } from '../surveyJs/consts';
import { toast } from 'react-toastify';
import { Pages } from '../../Helpers/Constants';
import NoPermission from '../ErrorPages/NoPermission';
import { EVALUATION_SHOW_ACTIONS } from './helpers/consts';

export default function EvaluationTake({ mode }) {
  const navigate = useNavigate();
  const { evaluationId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [notPermitted, setNotPermitted] = useState(false);

  useEffectAsync(async () => {
    if (evaluationId) {
      try {
        const surveyJson = await getEvaluation(evaluationId, mode);

        const surveyModel = new Model(surveyJson);
        surveyModel.showCompleteButton = false;

        // Add navigation items based on mode
        let btnTitle = '';
        let btnClass = '';
        let btnActionFunction = () => {};

        if (mode === EVALUATION_SHOW_ACTIONS.Take) {
          btnTitle = 'Submit';
          btnClass = 'btn-success';
          btnActionFunction = async () =>
            await handleEvaluationSubmit(surveyModel);
        } else if (mode === EVALUATION_SHOW_ACTIONS.Edit) {
          btnTitle = 'Submit Edit';
          btnClass = 'btn-warning';
          btnActionFunction = async () =>
            await handleEvaluationSubmit(surveyModel);
        } else if (mode === EVALUATION_SHOW_ACTIONS.Show) {
          btnTitle = 'Edit';
          btnClass = 'btn-warning';
          btnActionFunction = () => {
            navigate(Pages.EvaluationEdit.path(evaluationId));
            window.scrollTo(0, 0);
          };
        }

        surveyModel.addNavigationItem({
          title: btnTitle,
          css: `ms-auto me-0`,
          innerCss: `survey-btn-main sd-btn btn ${btnClass}`,
          action: btnActionFunction,
        });

        setSurvey(surveyModel);
      } catch (error) {
        if (error.response.status === 403) {
          setNotPermitted(true);
        } else {
          handleRequestErrors(error);
        }
      }
    }
  }, [evaluationId, mode]);

  async function handleEvaluationSubmit(surveyModel) {
    // Validate the survey
    const isValid = surveyModel.validate();
    if (!isValid) {
      toast('Form not filled correctly. Please check questions for issues', {
        type: 'error',
      });
      return;
    }

    // Submit the form
    const formMainButton = document.querySelector('.survey-btn-main');
    formMainButton.disabled = true;

    try {
      await submitEvaluationAnswers(evaluationId, surveyModel.data, mode);

      toast('Form Submitted Successfully.', { type: 'success' });

      setTimeout(() => {
        window.location.replace(Pages.Evaluations.path());
      }, 1000);
    } catch (err) {
      toast(err.response.data.message, { type: 'error' });
    }

    formMainButton.disabled = false;
  }

  return (
    <>
      {notPermitted ? (
        <NoPermission />
      ) : (
        <>
          {survey && (
            <StyledSurveyContainer>
              <Survey model={survey} />
            </StyledSurveyContainer>
          )}
        </>
      )}
    </>
  );
}
