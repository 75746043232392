import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as Constants from '../../Helpers/Constants';
import * as EvaluationManageUtils from './helpers/utils';
import { useNavigate } from 'react-router-dom';

export default function EvaluationManageShow({
  onClose,
  selectedValue,
  open,
  managedEvaluationRequest,
  currentTable,
}) {
  const navigate = useNavigate();

  const [renderedEvaluationRequest, setRenderedEvaluationRequest] =
    React.useState({});

  const handleClose = () => onClose(selectedValue);

  // Component did update
  React.useEffect(() => {
    // Update evaluation request.
    const _renderedEvaluationRequest = EvaluationManageUtils.originalToStyled([
      managedEvaluationRequest,
    ]);
    setRenderedEvaluationRequest(_renderedEvaluationRequest[0]);
  }, [managedEvaluationRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === Constants.TablesTypes.Pending) {
    dialogActions = (
      <>
        <div className="d-flex justify-content-between w-100">
          <Button onClick={handleClose}>Close</Button>

          <button
            className="btn btn-success ms-2 me-2 mb-2"
            onClick={() =>
              navigate(
                Constants.Pages.EvaluationTake.path(
                  managedEvaluationRequest.id,
                ),
              )
            }
          >
            Take
          </button>
        </div>
      </>
    );
  } else if (currentTable.type === Constants.TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="d-flex justify-content-between w-100">
          <Button onClick={handleClose}>Close</Button>

          <div>
            <button
              className="btn btn-warning ms-2 me-2 mb-2"
              onClick={() =>
                navigate(
                  Constants.Pages.EvaluationEdit.path(
                    managedEvaluationRequest.id,
                  ),
                )
              }
            >
              Edit
            </button>
            <button
              className="btn btn-primary ms-2 me-2 mb-2"
              onClick={() =>
                navigate(
                  Constants.Pages.EvaluationView.path(
                    managedEvaluationRequest.id,
                  ),
                )
              }
            >
              View
            </button>
          </div>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedEvaluationRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle>Appraisal Details</DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText
                  primary={<>Appraisal ID : {renderedEvaluationRequest.id}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Title : {renderedEvaluationRequest.title}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>Evaluated User : {renderedEvaluationRequest.evaluated}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Role : {renderedEvaluationRequest.role}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Team : {renderedEvaluationRequest.team}</>}
                />
              </ListItem>
            </List>
            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
