import dayjs from 'dayjs';

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>//
// >>>>>>>>> Date Format conversion to match calender library date >>>>>>>>//
import * as Helpers from '../../Helpers/Helpers';

export const isoToLong = (date) => new Date(date);

export const longToIso = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const originalToRendered = (originalFormat) => {
  if (originalFormat) {
    const renderedFormat = originalFormat.map((element) => {
      return {
        ...element,
        color: element.vacationType.color,
        startDate: isoToLong(element.start_date),
        endDate: isoToLong(element.end_date),
      };
    });
    return renderedFormat;
  }
};

export const renderedToOriginal = (renderedFormat) => {
  if (renderedFormat) {
    const originalFormat = renderedFormat.map((element) => {
      delete element.color;
      return {
        ...element,
        start_date: longToIso(element.start_date),
        end_date: longToIso(element.end_date),
        date: longToIso(element.date),
      };
    });
    return originalFormat;
  }
};

export const createEmptyOriginalObj = () => {
  return {
    id: 0,
    type: { id: 0, name: '', color: '' },
    reason: '',
    created_by: '',
    updated_by: '',
    start_date: '',
    end_date: '',
  };
};

// <<<<<<<<< Date Format conversion to match calender library date <<<<<<<<//
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
export const getViewModalRowsData = (vacation) => {
  return [
    {
      id: 1,
      label: 'Request ID',
      content: vacation.id,
    },
    {
      id: 2,
      label: 'Type',
      content: vacation.vacationType.name,
    },
    {
      id: 3,
      label: 'Reason',
      content: vacation.reason,
    },
    {
      id: 4,
      label: 'From',
      content: vacation.start_date,
    },
    {
      id: 5,
      label: 'To',
      content: vacation.end_date,
    },
    {
      id: 6,
      label: 'Total Days',
      content: vacation.total_days,
    },
    {
      id: 7,
      label: 'Status',
      content: Helpers.createStatusBadgeDiv(
        vacation.status.name,
        vacation.status.style.bg,
        vacation.status.style.text,
      ),
    },
    {
      id: 8,
      label: 'Created By',
      content: vacation.created_by,
    },
    {
      id: 9,
      label: 'Created At',
      content: vacation.created_at,
    },
    {
      id: 10,
      label: 'Updated By',
      content: vacation.updated_by,
    },
    {
      id: 11,
      label: 'Updated At',
      content: vacation.updated_at,
    },
  ];
};

export const createFormData = (vacation) => {
  return {
    id: vacation.id,
    vacation_type_id: vacation.vacationType.id,
    reason: vacation.reason,
    start_date: vacation.start_date,
    end_date: vacation.end_date,
    total_days: vacation.total_days,
  };
};
