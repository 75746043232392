import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FormControl, TextField } from '@mui/material';
import * as Constants from '../../../Helpers/Constants';
import * as Helpers from '../../../Helpers/Helpers';
import _ from 'lodash';
import {
  FINISHED_STATUSES_RECURRING_PAYMENT,
  RequestsTableRecurringPaymentStatusesConstants,
  STATUSES_RECURRING_PAYMENT,
  createRecurringPaymentTypeDiv,
  getRecurringPaymentTypeStyleClass,
} from '../helpers/Constants';

// Statuses Related Functions

export const getRecurringPaymentStatusesByTableType = (tableType) => {
  if (tableType === Constants.TablesTypes.Pending) {
    return [STATUSES_RECURRING_PAYMENT.Pending];
  } else if (tableType === Constants.TablesTypes.Finished) {
    return FINISHED_STATUSES_RECURRING_PAYMENT;
  }
};

export const createRecurringPaymentStatusesFilterQuery = (tableType) => {
  const statuses = getRecurringPaymentStatusesByTableType(tableType);
  const queryString = Helpers.createStatusesQuery(statuses);
  return queryString;
};
// Statuses Related Functions

// Mui datatables Utils

export const getDatatableColumns = () => {
  const datatableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'user',
      label: 'User',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Email : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Email"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '25rem' }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'reason',
      label: 'Type',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'start_date',
      label: 'Start Date',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'end_date',
      label: 'End Date',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return datatableColumns;
};

export const originalToStyled = (originalRequests) => {
  const renderedRequests = originalRequests.map((request) => {
    // Adding status style to the status
    const statusStyle =
      _.find(RequestsTableRecurringPaymentStatusesConstants, {
        id: request.status.id,
      }) || {};
    request.status.style = statusStyle.style;
    request.frequency.class = getRecurringPaymentTypeStyleClass(
      request.frequency.id,
    );

    // Modify original data to required format
    request = {
      ...request,
      user_id: request.user.id,
      user: request.user.name,
      email: request.user.email,
    };

    request = splitRecurringPaymentDecimalDurationField(request, [
      'requested_duration',
      'approved_duration',
    ]);

    return request;
  });

  return renderedRequests;
};

export const styledToMuiDatatable = (renderedRequests, actionsClickHandler) => {
  let datatableRequests = [];
  datatableRequests = renderedRequests.map((request) => {
    let _updatedRequest = {};
    _updatedRequest = {
      ...request,
      // Decimal Time to minutes and hours should be here
      status: Helpers.createStatusBadgeDiv(
        request.status.name,
        request.status.style.bg,
        request.status.style.text,
      ),
      // type viewing from the enum
      frequency: createRecurringPaymentTypeDiv(request.frequency.id),
    };
    return _updatedRequest;
  });

  return datatableRequests;
};

export const originalToPrintable = (originalRequests) => {
  return originalRequests.map((element) => {
    return {
      id: element.id,

      'user id': element.user.id,
      'user code': element.user.code,
      'user name': element.user.name,
      'user email': element.user.email,
      'user role': element.user.roles[0].display_name,
      'user team': element.user.roles[0].team.name,

      type: element.reason,
      value: element.value,
      frequency: element.frequency.name,
      'start date': element.start_date,
      'end date': element.end_date,

      'created by': element.created_by,
      'updated by': element.updated_by,
      'created at': element.created_at,
      'updated at': element.updated_at,
    };
  });
};

// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array
  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};

const splitRecurringPaymentDecimalDurationField = (
  recurringPaymentRequest,
  decimalDurationFieldNames,
) => {
  decimalDurationFieldNames.forEach((decimalDurationFieldName) => {
    if (recurringPaymentRequest[decimalDurationFieldName]) {
      const [hours, minutes] = Helpers.splitDecimalDurationToHrsAndMins(
        recurringPaymentRequest[decimalDurationFieldName],
      );

      // Getting new fields names
      const splitFieldName = decimalDurationFieldName.split('_');

      recurringPaymentRequest = {
        ...recurringPaymentRequest,
        [`${splitFieldName[0]}_hours`]: hours,
        [`${splitFieldName[0]}_minutes`]: minutes,
        [decimalDurationFieldName]: `${hours} hrs ${minutes} minutes`,
      };
    }
  });
  return recurringPaymentRequest;
};
