import styled from 'styled-components';

export const StyledSurveyContainer = styled.div`
  .sv-string-viewer {
    white-space: pre-line;
  }

  .sd-body.sd-body--static {
    max-width: 65vw;
  }
`;
