import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Autocomplete, FormControl, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-toastify';

import * as Requests from '../../../Helpers/Requests';
import {
  GrantSubTypes,
  handlePaymentAdjustmentInputFieldChange,
  NegativeAdjustmentTypes,
  paymentAdjustmentDateFields,
  paymentAdjustmentRequestFormFields,
  paymentAdjustmentTypes,
} from '../helpers/Constants';
import { handleRequestErrors } from '../../../Helpers/Requests';
import _ from 'lodash';
import {
  handleDateFieldsChange,
  useEffectAsync,
  catchRequestError,
} from '../../../Helpers/Helpers';
import dayjs from 'dayjs';
import { Pages } from '../../../Helpers/Constants';

export default function PaymentAdjustmentCreateMain() {
  const [formData, setFormData] = React.useState({
    [paymentAdjustmentRequestFormFields.Day.Name]: dayjs().format('YYYY-MM-DD'),
  });
  const [selectedType, setSelectedType] = React.useState({});
  const [invalidFormFields, setInvalidFormFields] = React.useState(true);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [allowedTypes, setAllowedTypes] = React.useState([]);
  const [usersData, setUsersData] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});

  useEffectAsync(async () => {
    setUsersData(await Requests.getUsers());
  }, []);

  useEffectAsync(async () => {
    try {
      // Get user allowed types and set the initial value
      const _allowedTypes = await Requests.getAllowedTypes();
      setAllowedTypes(_allowedTypes);

      // Setting initial form values
      let initialFormValues = {};

      // Set initial type for the needed statuses.
      setSelectedType(_allowedTypes[0]);
      initialFormValues = {
        ...initialFormValues,
        type: _allowedTypes[0].id,
      };

      // set initial grant values if the initial type is grant
      if (_allowedTypes[0].id === paymentAdjustmentTypes.Grant.id) {
        initialFormValues = {
          ...initialFormValues,
          subType: GrantSubTypes[0],
          value: GrantSubTypes[0].value,
          reason: GrantSubTypes[0].type,
          employee_id: selectedUser.id,
        };
      }

      setFormData({
        ...formData,
        ...initialFormValues,
      });
    } catch (error) {
      handleRequestErrors(error);
    }
  }, []);

  React.useEffect(() => {
    if (Object.keys(formData).length) {
      const RequiredFormFields = [
        paymentAdjustmentRequestFormFields.Type.Name,
        paymentAdjustmentRequestFormFields.EmployeeId.Name,
        paymentAdjustmentRequestFormFields.Value.Name,
        paymentAdjustmentRequestFormFields.Reason.Name,
      ];

      let isInvalid = false;

      RequiredFormFields.forEach((field) => {
        if (!formData[field]) {
          isInvalid = true;
        }
      });

      setInvalidFormFields(isInvalid);
    }
  }, [formData]);

  const handleDropDownChange = (colName, newValueObj) => {
    setSelectedUser({
      [colName]: newValueObj?.id || '',
    });
    setFormData({
      ...formData,
      [paymentAdjustmentRequestFormFields.EmployeeId.Name]:
        newValueObj?.id || '',
    });
  };

  const handleSubType = (e) => {
    setFormData({
      ...formData,
      subType: e.target.value,
      value: e.target.value.value,
      reason: e.target.value.type,
    });
  };

  const handleSubmit = (e) => {
    setRequestLoading(true);
    e.preventDefault();

    const formValues = { ...formData };
    // always negative input values if adjustment type is negative (ex: deduction)
    const NegativeAdjustmentTypesIds = NegativeAdjustmentTypes.map(
      (type) => type.id,
    );

    formValues.value = NegativeAdjustmentTypesIds.includes(formData.type)
      ? -Math.abs(formValues.value)
      : Math.abs(formValues.value);

    Requests.requestPaymentAdjustment(formValues, selectedUser.id)
      .then(() => {
        toast('One Time Payment Created Successfully.', {
          type: 'success',
        });
        setTimeout(() => {
          window.location.href = Pages.PaymentAdjustmentRequest.path();
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      {Object.keys(allowedTypes).length > 0 && (
        <>
          <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
            Create One Time Payment for User
          </div>
          <FormControl className="w-100" variant="standard">
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="d-flex flex-column w-100">
                <div className="d-flex">
                  <div className="h5 ms-3 w-25 d-flex">User :</div>
                  <Autocomplete
                    getOptionLabel={(option) => {
                      if (typeof option === 'object') {
                        return String(option.email);
                      } else {
                        const optionObj = _.find(usersData, {
                          id: option,
                        });
                        return optionObj ? String(optionObj.email) : option;
                      }
                    }}
                    isOptionEqualToValue={(optionObj, valueObj) => {
                      return optionObj.id == valueObj;
                    }}
                    value={selectedUser.id || null}
                    options={usersData ? Object.values(usersData) : []}
                    onChange={(e, newValue) =>
                      handleDropDownChange('id', newValue)
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={'Email'}
                          className="mb-1 d-block"
                          fullWidth
                        />
                      );
                    }}
                  />
                </div>
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Type :
                  </div>
                  <TextField
                    select
                    label="Select Type"
                    value={formData.type}
                    disabled
                    className="w-25"
                  >
                    {allowedTypes.map((type) => {
                      const typeObject = _.find(
                        Object.values(paymentAdjustmentTypes),
                        {
                          id: type.id,
                        },
                      );
                      return (
                        <MenuItem key={typeObject.id} value={typeObject.id}>
                          {typeObject.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>

                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Select grant type :
                  </div>
                  <TextField
                    select
                    label="Select a type"
                    value={formData.subType}
                    onChange={handleSubType}
                    className="w-25"
                  >
                    {GrantSubTypes.map((item) => {
                      return (
                        <MenuItem key={item.type} value={item}>
                          {item.type}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>

                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    {paymentAdjustmentRequestFormFields.Day.Title} :
                  </div>
                  <DatePicker
                    label={paymentAdjustmentRequestFormFields.Day.PlaceHolder}
                    value={
                      formData[paymentAdjustmentRequestFormFields.Day.Name]
                    }
                    onChange={(date) =>
                      handleDateFieldsChange(
                        date,
                        paymentAdjustmentRequestFormFields.Day.Name,
                        paymentAdjustmentDateFields,
                        setInvalidDateValue,
                        formData,
                        setFormData,
                      )
                    }
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>
            <div className="w-100 d-flex align-items-center mt-5">
              <button
                type="submit"
                className="btn btn-success ms-auto me-5"
                onClick={(e) => handleSubmit(e)}
                disabled={
                  invalidFormFields || invalidDateValue || requestLoading
                }
              >
                Create One Time Payment
              </button>
            </div>
          </FormControl>
        </>
      )}
    </>
  );
}
