import React, { useState, useMemo } from 'react';
import { Button } from '@mui/material';

function useDatatable(customOptions) {
  const [tableState, setTableState] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  let currentFilters = [];

  const defaultOptions = useMemo(
    () => ({
      selectableRows: 'none',
      page: tableState.page,
      rowsPerPage: tableState.rowsPerPage,
      rowsPerPageOptions: [5, 10, 25],
      viewColumns: false,
      search: false,
      print: false,
      serverSide: true,
      responsive: 'standard',
      confirmFilters: true,
      filter: true,
      filterType: 'textField',
      setRowProps: () => ({
        sx: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
      }),

      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button
              variant="contained"
              onClick={() => customOptions.handleFilterSubmit(applyNewFilters)}
            >
              Apply Filters
            </Button>
          </div>
        );
      },
      onFilterChange: (column, newFilters, type) => {
        const applyFilters = () => newFilters;
        if (newFilters !== currentFilters) {
          customOptions.handleFilterSubmit(applyFilters);
          currentFilters = newFilters;
        }

        // Reset the pagination to the first page
        handleTableChange('changePage', { page: 0 });
      },
      onChangePage: (newPage) =>
        handleTableChange('changePage', { page: newPage }),
      onChangeRowsPerPage: (newRowsPerPage) =>
        handleTableChange('changeRowsPerPage', { rowsPerPage: newRowsPerPage }),
      onTableChange: handleTableChange,
      setCellHeaderProps: (_cell) => {
        const style = {};
        style.maxWidth = '50px';
        return style;
      },
    }),
    [tableState],
  );

  const options = useMemo(
    () => ({
      ...defaultOptions,
      ...customOptions,
    }),
    [defaultOptions, customOptions],
  );

  return {
    tableState,
    options,
    handleTableChange,
  };

  function handleTableChange(action, tableStateUpdate) {
    switch (action) {
      case 'search':
        setTableState((prev) => ({
          ...prev,
          searchText: tableStateUpdate.searchText,
        }));
        break;
      case 'sort':
        setTableState((prev) => ({
          ...prev,
          sortOrder: tableStateUpdate.sortOrder,
        }));
        break;
      case 'changePage':
        setTableState((prev) => ({
          ...prev,
          page: tableStateUpdate.page,
        }));
        break;
      case 'changeRowsPerPage':
        setTableState((prev) => ({
          ...prev,
          rowsPerPage: tableStateUpdate.rowsPerPage,
          page: 0,
        }));
        break;
      default:
        break;
    }
  }
}

export default useDatatable;
