import * as React from 'react';
import Show from './Show';
import MUIDataTable from 'mui-datatables';
import {
  getWorkLogsWithExtraInfo,
  handleRequestErrors,
} from '../../../../Helpers/Requests';
import {
  getColsNames,
  getTableCols,
  mergeColsNamesWithRowData,
} from '../../utils';
import { getTableOptions } from '../../consts';

export default function Table({ selectedDay, invalidDateValue }) {
  const [workLogs, setWorklogs] = React.useState([]);
  const [tableUpdated, setTableUpdated] = React.useState([]);

  React.useEffect(() => {
    if (!invalidDateValue) {
      getWorkLogsWithExtraInfo({
        start_date: selectedDay,
        end_date: selectedDay,
      })
        .then((worklogsExtraInfo) => {
          setWorklogs(worklogsExtraInfo);
        })
        .catch((err) => handleRequestErrors(err));
    }
  }, [invalidDateValue, selectedDay, tableUpdated]);

  // Show Worklog record section
  const [viewedWorklogLoaded, setViewedWorklogLoaded] = React.useState(false);
  const [openViewWorklogDialog, setOpenViewWorklogDialog] =
    React.useState(false);
  const [viewedWorklogRecord, setViewedWorklogRequest] = React.useState({});

  const handleViewWorklogOpen = (row) => {
    const worklog = mergeColsNamesWithRowData(getColsNames(), row);
    setViewedWorklogRequest(worklog);
    setViewedWorklogLoaded(true);
    setOpenViewWorklogDialog(true);
  };

  const handleViewWorklogClose = () => setOpenViewWorklogDialog(false);
  // View Worklog record section

  return (
    <>
      {viewedWorklogLoaded && (
        <Show
          open={openViewWorklogDialog}
          onClose={handleViewWorklogClose}
          viewedWorklogRecord={viewedWorklogRecord}
          tableUpdated={tableUpdated}
          setTableUpdated={setTableUpdated}
        />
      )}
      <MUIDataTable
        title="Worklogs Extra Info"
        data={workLogs}
        columns={getTableCols()}
        options={getTableOptions(handleViewWorklogOpen)}
      />
    </>
  );
}
