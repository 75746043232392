import * as React from 'react';

import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import EditUser from './EditUser';
import { DialogContent, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  DocumentCols,
  ExpiryCols,
  RequiredPhotosCount,
  Sections,
  UsersTableCols,
  getColLabels,
  getExpiryRemainingDays,
  isDocumentExpiringSoon,
  isPhotosCountComplete,
} from './helpers';
import { PERMISSIONS, Pages } from '../../Helpers/Constants';
import { can, handleViewFile } from '../../Helpers/Helpers';

export default function ManageUser({
  onClose,
  selectedValue,
  open,
  managedUserData,
  formattedUserData,
  tableUpdated,
  setTableUpdated,
  usersData,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Edit User dialog //
  const [openEditUserDialog, setOpenEditUserDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleEditUserOpen = () => {
    handleCloseMenu();
    setOpenEditUserDialog(true);
  };
  const handleEditUserClose = () => {
    handleClose();
    setOpenEditUserDialog(false);
  };
  // Edit User dialog //

  const renderSections = (formattedUserData) => {
    return Object.values(Sections).map((section, index) => {
      return (
        <>
          <div key={index} className="h6 mt-4" style={{ fontSize: '1.25rem' }}>
            {section.Label}
          </div>
          <div>
            {getColLabels(section.Columns).map((sectionColKey) => {
              return renderListItem([
                sectionColKey,
                formattedUserData[sectionColKey],
              ]);
            })}
          </div>
        </>
      );
    });
  };

  const renderListItem = ([key, value]) => {
    const formattedUserData = { [key]: value };

    if (value) {
      if (getColLabels(DocumentCols).includes(key)) {
        return (
          <ListItem key={key}>
            <ListItemText
              primary={`${key} : `}
              style={{ flex: 'none', marginRight: '0.5rem' }}
            />
            <button
              className="btn btn-link p-0"
              value={formattedUserData[key]}
              onClick={(e) => handleViewFile(e.target.value)}
            >
              View Document
            </button>
          </ListItem>
        );
      } else if (getColLabels(ExpiryCols).includes(key)) {
        const expiryRemainingDays = getExpiryRemainingDays(
          formattedUserData[key],
        );
        const BadgeClass = isDocumentExpiringSoon(expiryRemainingDays)
          ? 'bg-danger'
          : 'bg-success';
        return (
          <ListItem key={key}>
            <ListItemText
              primary={`${key} : `}
              style={{ flex: 'none', marginRight: '0.5rem' }}
            />
            <div
              className={`badge ${BadgeClass}`}
              style={{ padding: '0.30rem 0.5rem' }}
            >{`${expiryRemainingDays} Days Remaining`}</div>
          </ListItem>
        );
      } else if (key === UsersTableCols.PersonalPhotoCount.Label) {
        const BadgeClass = !isPhotosCountComplete(formattedUserData[key])
          ? 'bg-danger'
          : 'bg-success';
        const RemainingPhotosCount =
          RequiredPhotosCount - formattedUserData[key];
        const RemainingPhotosCountString =
          RemainingPhotosCount > 0
            ? `( ${RemainingPhotosCount} Remaining )`
            : ``;
        return (
          <ListItem key={key}>
            <ListItemText
              primary={`${key} : `}
              style={{ flex: 'none', marginRight: '0.5rem' }}
            />
            <div
              className={`badge ${BadgeClass}`}
              style={{ padding: '0.30rem 0.5rem' }}
            >{`${formattedUserData[key]} Photos ${RemainingPhotosCountString}`}</div>
          </ListItem>
        );
      }
    }

    // Default case
    return (
      <ListItem key={key}>
        <ListItemText
          primary={`${key} : `}
          style={{ flex: 'none', marginRight: '0.5rem' }}
        />
        {formattedUserData[key] ? (
          <div>{`${formattedUserData[key]}`}</div>
        ) : (
          <div
            className={`badge bg-secondary`}
            style={{ padding: '0.30rem 0.5rem' }}
          >
            Unspecified
          </div>
        )}
      </ListItem>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
      <EditUser
        open={openEditUserDialog}
        onClose={handleEditUserClose}
        managedUserData={managedUserData}
        tableUpdated={tableUpdated}
        setTableUpdated={setTableUpdated}
        usersData={usersData}
      />
      <div className="d-flex justify-content-between align-items-center me-2">
        <DialogTitle>Employee Data</DialogTitle>
        <div className="adminpanel-button" onClick={handleOpenMenu}>
          <MoreVertIcon />
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {can([PERMISSIONS.UpdateUsers]) && (
          <MenuItem onClick={handleEditUserOpen}>
            <EditIcon className="me-2" />
            <div className="me-3">Edit Info</div>
          </MenuItem>
        )}
        {can([
          PERMISSIONS.ViewAnyStatusUpdates,
          PERMISSIONS.ViewStatusUpdates,
        ]) && (
          <MenuItem>
            <EditIcon className="me-2" />
            <NavLink
              id={managedUserData[UsersTableCols.ID.Name]}
              to={{
                pathname: Pages.StatusUpdates.path(
                  managedUserData[UsersTableCols.ID.Name],
                ),
                state: { username: managedUserData[UsersTableCols.Name.Name] },
              }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              View Status Updates
            </NavLink>
          </MenuItem>
        )}
        {can([PERMISSIONS.ViewAnyVacations, PERMISSIONS.ViewVacations]) && (
          <MenuItem>
            <BeachAccessIcon className="me-2" />
            <NavLink
              id={managedUserData[UsersTableCols.ID.Name]}
              to={{
                pathname: Pages.UserVacations.path(
                  managedUserData[UsersTableCols.ID.Name],
                ),
                state: { username: managedUserData[UsersTableCols.Name.Name] },
              }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              View Vacations
            </NavLink>
          </MenuItem>
        )}
      </Menu>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>{renderSections(formattedUserData)}</List>
      </DialogContent>
      <DialogActions
        className="d-flex justify-content-end"
        sx={{
          position: 'sticky',
          bottom: 0,
          py: 2,
          px: 3,
          bgcolor: 'background.default',
        }}
      >
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
