import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as Requests from '../../Helpers/Requests';
import { toast } from 'react-toastify';

export default function HolidayDeleteModal(props) {
  const [requestLoading, setRequestLoading] = React.useState(false);

  const deleteHoliday = () => {
    setRequestLoading(true);

    const holidayId = props.currentHoliday.id;
    Requests.deleteHoliday(holidayId)
      .then(() => {
        props.setHolidaysUpdated(!props.holidaysUpdated);
        toast('Holiday was deleted successfully.', { type: 'success' });
        props.setShowModal(null);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <Modal
        style={{ marginTop: '20vh' }}
        show={props.showModal === 'delete'}
        onHide={() => props.setShowModal('view')}
      >
        <Modal.Header>
          <Modal.Title>Delete Holiday</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this holiday?</div>
          <b> This action can not be undone.</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="me-2"
            variant="secondary"
            onClick={() => props.setShowModal('view')}
          >
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteHoliday()}
            disabled={requestLoading}
          >
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
