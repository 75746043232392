import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserExcusesBalance, getCurrentUser } from '../../Helpers/Requests';

export const fetchExcusesData = createAsyncThunk(
  'excuses/fetchData',
  async (selectedDay) => {
    const user = await getCurrentUser();
    const data = await getUserExcusesBalance(user.id, selectedDay);
    return data;
  },
);

const initialState = {
  used: null,
  remaining: null,
  loading: true,
  error: null,
};

const excusesSlice = createSlice({
  name: 'excuses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExcusesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExcusesData.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: null,
        };
      })
      .addCase(fetchExcusesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setExcusesData } = excusesSlice.actions;
export default excusesSlice.reducer;
