import React from 'react';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { handleDateFieldChange } from '../../Helpers/Helpers';
import { TextField } from '@mui/material';
import Table from './components/view/Table';

export default function WorkLogsPage() {
  const [selectedDay, setSelectedDay] = React.useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);

  return (
    <>
      {Object.keys(selectedDay).length > 0 && (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
              className="mb-4"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Selected Day"
                  value={selectedDay}
                  onChange={(date) =>
                    handleDateFieldChange(
                      date,
                      setSelectedDay,
                      setInvalidDateValue,
                    )
                  }
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>

          <Table
            selectedDay={selectedDay}
            invalidDateValue={invalidDateValue}
          />
        </>
      )}
    </>
  );
}
