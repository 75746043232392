import { TimeFields } from './consts';

export const convertApprovedDurationToDecimal = (timeFieldsData) => {
  return parseFloat(
    (
      parseInt(timeFieldsData[TimeFields.ApprovedHours.Name]) +
      parseInt(timeFieldsData[TimeFields.ApprovedMinutes.Name]) / 60
    ).toFixed(4),
  );
};
