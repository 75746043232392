import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';

import * as Requests from '../../Helpers/Requests';
import {
  DateCols,
  DropDownCols,
  StatusUpdatesTableCols,
  TextCols,
} from './helpers';
import { getColNames } from '../Users/helpers';
import _ from 'lodash';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  filterObjNullValues,
  handleDateFieldsChange,
} from '../../Helpers/Helpers';
import { toast } from 'react-toastify';

export default function EditStatusUpdate({
  onClose,
  selectedValue,
  open,
  managedStatusUpdateData,
  tableUpdated,
  setTableUpdated,
}) {
  const disabledFields = [StatusUpdatesTableCols.User.Name];

  const hiddenFields = [StatusUpdatesTableCols.ID.Name];

  const [selectedUser, setSelectedUser] = React.useState();

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);

  const [editStatusUpdateFormValues, setEditStatusUpdateFormValues] =
    React.useState({});

  const handleClose = () => {
    onClose(selectedValue);
    setEditStatusUpdateFormValues({});
  };

  // Setting initial values of the form object
  React.useEffect(() => {
    setSelectedUser(managedStatusUpdateData.user.name);
    let initialFormValues = filterObjNullValues(managedStatusUpdateData);
    initialFormValues = {
      ...initialFormValues,
      employee_id: managedStatusUpdateData.user.id,
      team: managedStatusUpdateData.team.id,
    };
    setEditStatusUpdateFormValues(initialFormValues);
  }, [managedStatusUpdateData]);

  const handleInputChange = (e, ele, fieldMaxLength = null) => {
    if (fieldMaxLength) {
      e.target.value = e.target.value.substring(0, fieldMaxLength);
    }
    const { name, value } = e.target;
    setEditStatusUpdateFormValues({
      ...editStatusUpdateFormValues,
      [name]: value,
    });
  };

  const handleEditStatusUpdateSubmit = () => {
    setRequestLoading(true);

    const formValues = { ...editStatusUpdateFormValues };

    // Use FormData to allow for file parameters
    const formData = new FormData();

    // Backend required parameter
    formData.append('_method', 'PUT');

    for (const [key, value] of Object.entries(formValues)) {
      formData.append(key, value);
    }

    Requests.updateStatusUpdate(
      managedStatusUpdateData.user.id,
      managedStatusUpdateData.id,
      formData,
    )
      .then((response) => {
        toast(response.data.message, { type: 'success' });

        setTableUpdated(!tableUpdated);
        handleClose();
      })
      .catch((error) => {
        Requests.handleRequestErrors(error);
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const renderDateField = (field) => {
    return (
      <div style={{ width: `${field.Width}rem` }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={editStatusUpdateFormValues[field.Name]}
            onChange={(date) =>
              handleDateFieldsChange(
                date,
                field.Name,
                DateCols,
                setInvalidDateValue,
                editStatusUpdateFormValues,
                setEditStatusUpdateFormValues,
              )
            }
            inputFormat="DD-MM-YYYY"
            label={field.Label}
            renderInput={(params) => <TextField fullWidth {...params} />}
            className="mb-1 mt-3 d-block"
          />
        </LocalizationProvider>
      </div>
    );
  };

  const renderEditFormFields = (
    managedStatusUpdateData,
    editStatusUpdateFormValues,
  ) => {
    return (
      <>
        {Object.keys(managedStatusUpdateData).map((fieldName) => {
          if (getColNames(TextCols).includes(fieldName)) {
            const textCol = _.find(TextCols, { Name: fieldName });
            return (
              <TextField
                key={textCol.Name}
                label={textCol.Label}
                name={textCol.Name}
                type={textCol.Type}
                defaultValue={editStatusUpdateFormValues[textCol.Name]}
                onChange={(e, ele) =>
                  handleInputChange(e, ele, textCol.MaxLength)
                }
                fullWidth
                margin="dense"
                variant="standard"
                disabled={disabledFields.includes(textCol.Name)}
                hidden={hiddenFields.includes(textCol.Name)}
              />
            );
          } else if (getColNames(DateCols).includes(fieldName)) {
            const dateCol = _.find(DateCols, {
              Name: fieldName,
            });
            return renderDateField(dateCol);
          } else if (getColNames(DropDownCols).includes(fieldName)) {
            const dropDownCol = _.find(DropDownCols, { Name: fieldName });

            const defaultValue =
              managedStatusUpdateData[dropDownCol.Name] &&
              typeof managedStatusUpdateData[dropDownCol.Name] === 'object'
                ? managedStatusUpdateData[dropDownCol.Name].id
                : managedStatusUpdateData[dropDownCol.Name] || '';

            return (
              <div
                key={dropDownCol.Name}
                style={{ width: `${dropDownCol.Width}rem` }}
              >
                <TextField
                  label={dropDownCol.Label}
                  onChange={(e) => handleInputChange(e)}
                  select
                  name={dropDownCol.Name}
                  defaultValue={defaultValue}
                  className="mb-1 mt-3 d-block"
                  fullWidth
                >
                  {Object.values(dropDownCol.Options).map((option) => {
                    return (
                      <MenuItem key={option.Label} value={option.Value}>
                        {option.Label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            );
          }
          // Default Case
          return <></>;
        })}
      </>
    );
  };
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Edit Status Update</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          <TextField
            label="User"
            value={selectedUser}
            name="user"
            className="mt-4"
            fullWidth
            margin="dense"
            variant="standard"
            disabled={true}
          />
          {renderEditFormFields(
            managedStatusUpdateData,
            editStatusUpdateFormValues,
          )}
        </List>
      </DialogContent>
      <DialogActions
        className="d-flex justify-content-between"
        sx={{
          position: 'sticky',
          bottom: 0,
          py: 2,
          px: 3,
          bgcolor: 'background.default',
        }}
      >
        <button
          onClick={handleEditStatusUpdateSubmit}
          disabled={requestLoading || invalidDateValue}
          className="btn btn-warning me-3"
        >
          Confirm Changes
        </button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
