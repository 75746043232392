import React from 'react';

import { FormControl, TextField } from '@mui/material';
import _ from 'lodash';

export const getDatatableColumns = () => {
  const datatableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'user',
      label: 'User',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Email : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Email"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '25rem' }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'reason',
      label: 'Reason',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'installments_count',
      label: 'Installments Count',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return datatableColumns;
};

export const originalToStyled = (originalRequests) => {
  const renderedRequests = originalRequests.map((request) => {
    // Modify original data to required format
    request = {
      ...request,
      user_id: request.user.id,
      user: request.user.name,
      email: request.user.email,
    };

    return request;
  });

  return renderedRequests;
};

export const styledToMuiDatatable = (renderedRequests, actionsClickHandler) => {
  let datatableRequests = [];
  datatableRequests = renderedRequests.map((request) => {
    let _updatedRequest = {};
    _updatedRequest = {
      ...request,
    };
    return _updatedRequest;
  });

  return datatableRequests;
};

export const originalToPrintable = (originalRequests) => {
  return originalRequests.map((element) => {
    return {
      id: element.id,

      'user id': element.user.id,
      'user code': element.user.code,
      'user name': element.user.name,
      'user email': element.user.email,
      'user role': element.user.roles[0].display_name,
      'user team': element.user.roles[0].team.name,

      value: element.value,
      reason: element.reason,
      comment: element.comment,

      'created by': element.created_by,
      'updated by': element.updated_by,
      'created at': element.created_at,
      'updated at': element.updated_at,
    };
  });
};
// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array
  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};
