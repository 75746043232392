import * as React from 'react';
import { Button } from '@mui/material';
import { userData } from '../../../Helpers/Constants';
import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import * as ExcuseRequestsUtils from './ExcusesViewUtils';
import ExcusesViewShow from './ExcusesViewShow';

import MUIDataTable from 'mui-datatables';

export default function ExcusesViewTable(props) {
  const { currentTable } = props;

  const IdCellIndex = 0;

  // Requests data variables
  const [originalExcuseRequests, setOriginalExcuseRequests] = React.useState(
    [],
  );
  const [datatableExcuseRequests, setDatatableExcuseRequests] = React.useState(
    [],
  );
  // Requests data variables

  // Table effects variables
  const [currentColumns, setCurrentColumns] = React.useState([]);
  let currentTableState = {};
  let currentFilters = [];

  const [currentFilterFields, setCurrentFilterFields] = React.useState({});
  // Table effects variables

  // Pagination variables
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 5,
  });

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (updatedRowsPerPageValue) => {
    setController({
      ...controller,
      rowsPerPage: updatedRowsPerPageValue,
      page: 0,
    });
  };
  // Pagination variables

  // Mui datatables variables
  const options = {
    selectableRows: 'none',
    count: requestsCount,
    page: controller.page,
    rowsPerPage: controller.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    search: false,
    viewColumns: false,
    print: false,
    serverSide: true,
    responsive: 'standard',
    confirmFilters: true,
    filter: true,
    filterType: 'textField',
    onRowClick: (row) => {
      handleViewRequestOpen(row[IdCellIndex]);
    },
    // controlling download content
    onDownload: (buildHead, buildBody, columns, data) => {
      Requests.getExcuseRequests(
        currentTable.type,
        null,
        null,
        currentFilterFields,
        userData.id,
      )
        .then((requests) => {
          const tableData = requests.data;
          const printableTableData =
            ExcuseRequestsUtils.originalToPrintable(tableData);
          // convert array to csv
          const array = [Object.keys(printableTableData[0])].concat(
            printableTableData,
          );
          const csv = array
            .map((it) => Object.values(it).toString())
            .join('\n');

          Helpers.downloadBlob(
            csv,
            'Excuse_Requests.csv',
            'text/csv;charset=utf-8;',
          );
          return printableTableData;
        })
        .catch((err) => Requests.handleRequestErrors(err));

      return false;
    },
    // setting row style
    setRowProps: () => ({
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    }),
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    // setting Head cell style
    setCellHeaderProps: (cell) => {
      const style = {};
      style.maxWidth = '50px';
      return style;
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(newTableState.page);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(newTableState.rowsPerPage);
          break;
      }
      currentTableState = newTableState;
    },
    // Handle changing the filter from the chips
    onFilterChange: (column, newFilters, type) => {
      const applyFilters = () => newFilters;
      if (newFilters !== currentFilters) {
        handleFilterSubmit(applyFilters);
        currentFilters = newFilters;
      }

      // Reset the pagination to the first page
      setController((prev) => ({ ...prev, page: 0 }));
    },
  };
  // Mui datatables

  // Component did mount and Monitoring filter and table updates
  React.useEffect(() => {
    Requests.getExcuseRequests(
      currentTable.type,
      controller.page,
      controller.rowsPerPage,
      currentFilterFields,
      userData.id,
    )
      .then((requests) => {
        setOriginalExcuseRequests(requests.data);
        setRequestsCount(requests.meta.total);
        setCurrentColumns(ExcuseRequestsUtils.getDatatableColumns());
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [controller, currentTable]);
  // Component did mount.

  // update styled and datatable requests whenever fetched requests change
  React.useEffect(() => {
    const _styledExcuseRequests = ExcuseRequestsUtils.originalToStyled(
      originalExcuseRequests,
    );
    const _datatableExcuseRequests = ExcuseRequestsUtils.styledToMuiDatatable(
      _styledExcuseRequests,
      handleViewRequestOpen,
    );
    setDatatableExcuseRequests(_datatableExcuseRequests);
  }, [originalExcuseRequests]);
  // update styled and datatable requests whenever fetched requests change

  // Handling apply filters button action
  const handleFilterSubmit = (applyFilters) => {
    const filterFields = ExcuseRequestsUtils.createFilterFields(
      currentTableState,
      applyFilters,
    );

    setCurrentFilterFields(filterFields);

    Requests.getExcuseRequests(
      currentTable.type,
      controller.page,
      controller.rowsPerPage,
      filterFields,
      userData.id,
    )
      .then((filteredRequests) => {
        setOriginalExcuseRequests(filteredRequests.data);
        setRequestsCount(filteredRequests.meta.total);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  // Handling apply filters button action

  // View Excuse Request section
  const [viewedRequestLoaded, setViewedRequestLoaded] = React.useState(false);
  const [openViewRequestDialog, setOpenViewRequestDialog] =
    React.useState(false);
  const [viewedExcuseRequest, setViewedExcuseRequest] = React.useState({});

  const handleViewRequestOpen = (requestId) => {
    Requests.getExcuseRequest(requestId)
      .then((excuseRequest) => {
        setViewedExcuseRequest(excuseRequest);
        setViewedRequestLoaded(true);
        setOpenViewRequestDialog(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  };
  const handleViewRequestClose = () => setOpenViewRequestDialog(false);
  // View Excuse Request section

  return (
    <>
      {viewedRequestLoaded && (
        <ExcusesViewShow
          open={openViewRequestDialog}
          onClose={handleViewRequestClose}
          viewedExcuseRequest={viewedExcuseRequest}
        />
      )}
      <MUIDataTable
        title={currentTable.titleName}
        data={datatableExcuseRequests}
        columns={currentColumns}
        options={options}
      />
    </>
  );
}
