import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FormControl, TextField } from '@mui/material';
import * as Constants from '../../../Helpers/Constants';
import * as Helpers from '../../../Helpers/Helpers';
import _ from 'lodash';
import {
  FINISHED_STATUSES_EXCUSES,
  PENDING_STATUSES_EXCUSES,
  RequestsTableExcuseStatusesConstants,
  STATUSES_EXCUSES,
} from '../Constants';

// Statuses Related Functions
export const originalToStyled = (originalRequests) => {
  const renderedRequests = originalRequests.map((request) => {
    // Adding status style to the status
    const statusStyle =
      _.find(RequestsTableExcuseStatusesConstants, {
        id: request.status.id,
      }) || {};
    request.status.style = statusStyle.style;

    return request;
  });
  return renderedRequests;
};

export const getStatusesByTableTypeAndRole = (
  tableType,
  userRole,
  callerComponent,
) => {
  if (callerComponent === Constants.CallerComponent.View) {
    if (tableType === Constants.TablesTypes.Pending) {
      return PENDING_STATUSES_EXCUSES;
    } else if (tableType === Constants.TablesTypes.Finished) {
      return FINISHED_STATUSES_EXCUSES;
    }
  } else if (callerComponent === Constants.CallerComponent.Manage) {
    if (tableType === Constants.TablesTypes.Pending) {
      return getPendingStatusesByRole(userRole);
    } else if (tableType === Constants.TablesTypes.Finished) {
      return getFinishedStatusesByRole(userRole);
    }
  } else {
    console.error('Unexpected Caller Component');
  }
};

export const getPendingStatusesByRole = (userRole) => {
  // Planswift
  if (Constants.PlanswiftManagersRoles.includes(userRole)) {
    return [STATUSES_EXCUSES.PlanswiftPendingManagerApproval];
  }

  // Revit
  if (Constants.RevitManagersRoles.includes(userRole)) {
    return [STATUSES_EXCUSES.RevitPendingManagerApproval];
  }

  // Unity
  if (Constants.UnityManagersRoles.includes(userRole)) {
    return [
      STATUSES_EXCUSES.UnityPendingTeamManagerApproval,
      STATUSES_EXCUSES.UnityPendingLeaderApproval,
    ];
  }
  if (Constants.UnityLeadersRoles.includes(userRole)) {
    return [STATUSES_EXCUSES.UnityPendingLeaderApproval];
  }

  // Digitizing
  if (Constants.DigitizingManagersRoles.includes(userRole)) {
    return [STATUSES_EXCUSES.DigitizingPendingManagerApproval];
  }

  // HR And Admin
  if ([Constants.ROLES.HR, Constants.ROLES.Admin].includes(userRole)) {
    return [STATUSES_EXCUSES.HrPendingApproval];
  }
};

export const getFinishedStatusesByRole = (userRole) => {
  if ([Constants.ROLES.HR, Constants.ROLES.Admin].includes(userRole)) {
    return FINISHED_STATUSES_EXCUSES;
    // Unity
  } else if (Constants.UnityLeadersRoles.includes(userRole)) {
    return Helpers.getAllExcept(Object.values(STATUSES_EXCUSES), [
      ...getPendingStatusesByRole(userRole),
    ]);
  } else if (Constants.UnityManagersRoles.includes(userRole)) {
    return Helpers.getAllExcept(Object.values(STATUSES_EXCUSES), [
      ...getPendingStatusesByRole(userRole),
      STATUSES_EXCUSES.UnityPendingLeaderApproval,
    ]);
  } else {
    return Helpers.getAllExcept(
      Object.values(STATUSES_EXCUSES),
      getPendingStatusesByRole(userRole),
    );
  }
};

export const createExcuseStatusesFilterQuery = (
  tableType,
  userRole,
  callerComponent,
) => {
  const statuses = getStatusesByTableTypeAndRole(
    tableType,
    userRole,
    callerComponent,
  );
  const queryString = Helpers.createStatusesQuery(statuses);
  return queryString;
};
// Statuses Related Functions

// Mui datatables Utils

export const getDatatableColumns = () => {
  const datatableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'user',
      label: 'User',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Email : ${value}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  label="Email"
                  value={filterList[index][0] || ''}
                  onChange={(e) => {
                    filterList[index][0] = e.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'Date',
      name: 'date',
      options: {
        filter: false,
        sort: false,
        display: 'true',
      },
    },
    {
      label: 'From',
      name: 'from',
      options: {
        display: false,
        filter: true,
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `From : ${reversedDate}`;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'To',
      name: 'to',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (date) => {
            // Reversing date format in the filter chip to show the preferred format
            const reversedDate = [`${date[0].split('-').reverse().join('-')}`];
            return `To : ${reversedDate}`;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={filterList[index][0] || null}
                    onChange={(date) => {
                      filterList[index][0] = date.format('YYYY-MM-DD');
                      onChange(filterList[index], index, column);
                    }}
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return datatableColumns;
};

export const styledToMuiDatatable = (renderedRequests, actionsClickHandler) => {
  let datatableRequests = [];
  datatableRequests = renderedRequests.map((request) => {
    let _updatedRequest = {};
    _updatedRequest = {
      ...request,
      user: request.user.name,
      email: request.user.email,
      status: Helpers.createStatusBadgeDiv(
        request.status.name,
        request.status.style.bg,
        request.status.style.text,
      ),
    };
    return _updatedRequest;
  });

  return datatableRequests;
};
// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array
  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};

export const originalToPrintable = (originalRequests) => {
  return originalRequests.map((element) => {
    return {
      id: element.id,

      'user id': element.user.id,
      'user code': element.user.code,
      'user name': element.user.name,
      'user email': element.user.email,
      'user role': element.user.roles[0].display_name,
      'user team': element.user.roles[0].team.name,

      date: element.date,
      reason: element.reason,
      status: element.status.name,
      comment: element.comment,

      'created by': element.created_by,
      'updated by': element.updated_by,
      'created at': element.created_at,
      'updated at': element.updated_at,
    };
  });
};
