import { FormControl, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../../Helpers/Helpers';
import { getYearlyJournalReport } from '../../../../Helpers/Requests';

export default function YearlyJournalReportPage() {
  const [requestLoading, setRequestLoading] = useState(false);
  const [yearFilterValue, setYearFilterValue] = useState(
    dayjs().startOf('year'),
  );

  const handleReviewClick = async () => {
    setRequestLoading(true);

    try {
      const year = yearFilterValue.format('YYYY');
      const response = await getYearlyJournalReport(year);

      downloadFile(response, 'application/zip', 'yearly_journal.zip');
    } catch (err) {
      const decoder = new TextDecoder('utf-8');
      const errData = JSON.parse(decoder.decode(err.response.data));

      toast(errData.message, { type: 'error' });
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <>
      <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
        Yearly Journal Reports
      </div>
      <FormControl className="w-100" variant="standard">
        <div className="d-flex flex-row align-items-start justify-content-between">
          <div className="d-flex flex-column w-100">
            <div className="d-flex">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Select Reports Year :
              </div>
              <DatePicker
                views={['year']}
                openTo="year"
                className="w-25"
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={yearFilterValue}
                onChange={(date) =>
                  setYearFilterValue(dayjs(date).startOf('year'))
                }
              />
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-end mt-5">
          <button
            className="btn btn-primary me-5"
            onClick={handleReviewClick}
            disabled={requestLoading}
          >
            Download Reports
          </button>
        </div>
      </FormControl>
    </>
  );
}
