export const NursingScheduleDateFields = {
  StartDate: 'start_date',
  EndDate: 'end_date',
};

export const STATUSES_NURSING_SCHEDULE = {
  Approved: 1,
  Rejected: 2,
  Pending: 3,
};

export const FINISHED_STATUSES_NURSING_SCHEDULE = [
  STATUSES_NURSING_SCHEDULE.Approved,
  STATUSES_NURSING_SCHEDULE.Rejected,
];

export const PENDING_STATUSES_NURSING_SCHEDULE = [
  STATUSES_NURSING_SCHEDULE.Pending,
];

export const RequestsTableNursingScheduleStatusesConstants = [
  {
    id: STATUSES_NURSING_SCHEDULE.Approved,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },
  {
    id: STATUSES_NURSING_SCHEDULE.Rejected,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
  {
    id: STATUSES_NURSING_SCHEDULE.Pending,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
];

export const handleNursingScheduleInputFieldChange = (
  e,
  fieldName,
  formData,
  setFormData,
) => {
  setFormData({
    ...formData,
    [fieldName]: e.target.value,
  });
};
