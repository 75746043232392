import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';

import * as Requests from '../../Helpers/Requests';
import {
  DateCols,
  DropDownCols,
  StatusUpdatesTableCols,
  TextCols,
} from './helpers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getColNames } from '../Users/helpers';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { handleDateFieldsChange } from '../../Helpers/Helpers';
import { toast } from 'react-toastify';

export default function AddStatusUpdate({
  onClose,
  selectedValue,
  open,
  requestedUser,
  tableUpdated,
  setTableUpdated,
}) {
  const { userId } = useParams();

  const disabledFields = [];

  const hiddenFields = [];

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const [addStatusUpdateFormValues, setAddStatusUpdateFormValues] =
    React.useState({});

  React.useEffect(() => {
    setAddStatusUpdateFormValues({
      employee_id: userId,
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
    });
  }, [userId, tableUpdated]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddStatusUpdateFormValues({
      ...addStatusUpdateFormValues,
      [name]: value,
    });
  };

  const handleAddStatusUpdateSubmit = (e) => {
    setRequestLoading(true);

    const formValues = { ...addStatusUpdateFormValues };

    // Use FormData to allow for file parameters
    const formData = new FormData();

    for (const [key, value] of Object.entries(formValues)) {
      formData.append(key, value);
    }

    Requests.addStatusUpdate(userId, formData)
      .then((response) => {
        toast(response.data.message, { type: 'success' });
        setTableUpdated(!tableUpdated);
        handleClose();
      })
      .catch((error) => {
        Requests.handleRequestErrors(error);
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const renderDateField = (field) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={addStatusUpdateFormValues[field.Name]}
          defaultValue={dayjs().format('YYYY-MM-DD')}
          onChange={(date) =>
            handleDateFieldsChange(
              date,
              field.Name,
              DateCols,
              setInvalidDateValue,
              addStatusUpdateFormValues,
              setAddStatusUpdateFormValues,
            )
          }
          inputFormat="DD-MM-YYYY"
          label={field.Label}
          renderInput={(params) => <TextField {...params} />}
          className="mb-1 mt-3 d-block"
        />
      </LocalizationProvider>
    );
  };

  const renderAddFormFields = (StatusUpdatesTableCols) => {
    return (
      <>
        {Object.keys(StatusUpdatesTableCols).map((fieldName) => {
          if (
            getColNames(TextCols).includes(
              StatusUpdatesTableCols[fieldName].Name,
            )
          ) {
            const textCol = _.find(TextCols, {
              Name: StatusUpdatesTableCols[fieldName].Name,
            });
            return (
              <TextField
                key={textCol.Name}
                label={textCol.Label}
                name={textCol.Name}
                type={textCol.Type}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="standard"
                disabled={disabledFields.includes(textCol.Name)}
                hidden={hiddenFields.includes(textCol.Name)}
              />
            );
          } else if (
            getColNames([...DateCols]).includes(
              StatusUpdatesTableCols[fieldName].Name,
            )
          ) {
            const dateCol = _.find([...DateCols], {
              Name: StatusUpdatesTableCols[fieldName].Name,
            });
            return renderDateField(dateCol);
          } else if (
            getColNames(DropDownCols).includes(
              StatusUpdatesTableCols[fieldName].Name,
            )
          ) {
            const dropDownCol = _.find(DropDownCols, {
              Name: StatusUpdatesTableCols[fieldName].Name,
            });

            return (
              <div
                key={dropDownCol.Name}
                style={{ width: `${dropDownCol.Width}rem` }}
              >
                <TextField
                  label={dropDownCol.Label}
                  onChange={(e) => handleInputChange(e)}
                  select
                  name={dropDownCol.Name}
                  defaultValue=""
                  className="mb-1 mt-3 d-block"
                  fullWidth
                >
                  {Object.values(dropDownCol.Options).map((option) => {
                    return (
                      <MenuItem key={option.Label} value={option.Value}>
                        {option.Label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            );
          }
          // Default Case
          return <></>;
        })}
      </>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Add Status Update</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          <TextField
            key="name"
            label="Name"
            value={requestedUser.name}
            name="name"
            type="text"
            fullWidth
            margin="dense"
            variant="standard"
            disabled={true}
          />
          {renderAddFormFields(StatusUpdatesTableCols)}
        </List>
      </DialogContent>
      <DialogActions
        className="d-flex justify-content-between"
        sx={{
          position: 'sticky',
          bottom: 0,
          py: 2,
          px: 3,
          bgcolor: 'background.default',
        }}
      >
        <button
          onClick={handleAddStatusUpdateSubmit}
          disabled={requestLoading || invalidDateValue}
          className="btn btn-warning me-3"
        >
          Create Status Update
        </button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
