import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserVacationBalances,
  getCurrentUser,
} from '../../Helpers/Requests';
import { BALANCE_REQUEST_STATUSES } from '../../components/Vacations/Constants';

export const fetchVacationsData = createAsyncThunk(
  'vacations/fetchData',
  async ({ user, year }) => {
    const RequiredStatuses = [
      BALANCE_REQUEST_STATUSES.Approved,
      BALANCE_REQUEST_STATUSES.Pending,
    ];

    const currentUser = user || (await getCurrentUser());
    const data = currentUser.id
      ? await getUserVacationBalances(currentUser.id, RequiredStatuses, year)
      : initialState;
    return data;
  },
);

const initialState = {
  normal: {
    used: 0,
    remaining: 0,
  },
  urgent: {
    used: 0,
    remaining: 0,
  },
  sick: {
    used: 0,
    remaining: 0,
  },
  unpaid: {
    used: 0,
    remaining: 0,
  },
  loading: false,
  error: null,
};

const vacationsSlice = createSlice({
  name: 'vacations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacationsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVacationsData.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: null,
        };
      })
      .addCase(fetchVacationsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setVacationsData } = vacationsSlice.actions;
export default vacationsSlice.reducer;
