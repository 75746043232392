import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';

import { Autocomplete } from '@mui/material';
import _ from 'lodash';
import {
  filterObjNullValues,
  nullifyObjectValues,
} from '../../../../Helpers/Helpers';
import {
  createWorkLogsExtraInfo,
  handleRequestErrors,
  updateWorkLogsExtraInfo,
} from '../../../../Helpers/Requests';
import {
  DropDownCols,
  WorklogsTableCols,
  hiddenCols,
  nonEditableCols,
} from '../../consts';
import { getColNames } from '../../../Users/helpers';
import { toast } from 'react-toastify';

export default function Edit({
  onClose,
  selectedValue,
  open,
  viewedWorklogRecord,
  tableUpdated,
  setTableUpdated,
}) {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [editWorklogFormValues, setEditWorklogFormValues] = React.useState({});

  const handleClose = () => {
    onClose(selectedValue);
    setEditWorklogFormValues(nullifyObjectValues({ ...editWorklogFormValues }));
  };

  // Setting initial values of the form object
  React.useEffect(() => {
    const initialFormValues = filterObjNullValues(viewedWorklogRecord);
    setEditWorklogFormValues(initialFormValues);
  }, [viewedWorklogRecord]);

  const handleInputChange = (e, ele, fieldMaxLength = null) => {
    if (fieldMaxLength) {
      e.target.value = e.target.value.substring(0, fieldMaxLength);
    }
    const { name, value } = e.target;
    setEditWorklogFormValues({
      ...editWorklogFormValues,
      [name]: value,
    });
  };

  const handleDropDownChange = (colName, newValueObj) => {
    setEditWorklogFormValues({
      ...editWorklogFormValues,
      [colName]: newValueObj?.id || '',
    });
  };

  const handleEditWorklogSubmit = () => {
    setRequestLoading(true);

    // Use FormData to allow for file parameters
    const formData = new FormData();

    for (const [key, value] of Object.entries(editWorklogFormValues)) {
      formData.append(key, value);
    }

    (() => {
      if (editWorklogFormValues[WorklogsTableCols.ID.Name]) {
        // Record has id in worklogs extra info (record was created before)
        formData.append('_method', 'PUT');
        return updateWorkLogsExtraInfo(
          viewedWorklogRecord[WorklogsTableCols.ID.Name],
          formData,
        );
      } else {
        // Record has no id and a create request will be made
        return createWorkLogsExtraInfo(formData);
      }
    })()
      .then((response) => {
        toast(response.data.message, { type: 'success' });

        setTableUpdated(!tableUpdated);
        handleClose();
      })
      .catch((error) => {
        handleRequestErrors(error);
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const renderEditFormFields = (editWorklogFormValues) => {
    return (
      <>
        {getColNames(Object.values(WorklogsTableCols)).map((colKey) => {
          return renderEditFormField([colKey, editWorklogFormValues]);
        })}
      </>
    );
  };

  const renderEditFormField = ([fieldName, editWorklogFormValues]) => {
    if (getColNames(nonEditableCols).includes(fieldName)) {
      const textCol = _.find(nonEditableCols, { Name: fieldName });
      return (
        <TextField
          key={textCol.Name}
          label={textCol.Label}
          name={textCol.Name}
          type={textCol.Type}
          defaultValue={editWorklogFormValues[textCol.Name]}
          onChange={(e, ele) => handleInputChange(e, ele, textCol.MaxLength)}
          fullWidth
          margin="dense"
          variant="standard"
          disabled={getColNames(nonEditableCols).includes(textCol.Name)}
          hidden={getColNames(hiddenCols).includes(textCol.Name)}
        />
      );
    } else if (getColNames(DropDownCols).includes(fieldName)) {
      const dropDownCol = _.find(DropDownCols, { Name: fieldName });
      return (
        <div
          key={dropDownCol.Name}
          style={{ width: `${dropDownCol.Width}rem` }}
        >
          <Autocomplete
            getOptionLabel={(option) => {
              if (typeof option === 'object') {
                return String(option.name);
              } else {
                const optionObj = _.find(dropDownCol.Options, {
                  id: option,
                });
                return optionObj ? String(optionObj.name) : option;
              }
            }}
            isOptionEqualToValue={(optionObj, valueObj) => {
              return optionObj.id == valueObj;
            }}
            value={editWorklogFormValues[dropDownCol.Name] || null}
            options={
              dropDownCol.Options ? Object.values(dropDownCol.Options) : []
            }
            onChange={(e, newValue) =>
              handleDropDownChange(dropDownCol.Name, newValue)
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={dropDownCol.Label}
                  className="mb-1 mt-3 d-block"
                  fullWidth
                />
              );
            }}
          />
        </div>
      );
    }
    // Default Case
    return <></>;
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Edit Worklog</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {renderEditFormFields(editWorklogFormValues)}
        </List>
      </DialogContent>
      <DialogActions
        className="d-flex justify-content-between"
        sx={{
          position: 'sticky',
          bottom: 0,
          py: 2,
          px: 3,
          bgcolor: 'background.default',
        }}
      >
        <button
          onClick={handleEditWorklogSubmit}
          disabled={requestLoading}
          className="btn btn-warning me-3"
        >
          Confirm Changes
        </button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
