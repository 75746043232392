import React from 'react';
import {
  List,
  ListItem,
  MenuItem,
  FormControl,
  TextField,
  Autocomplete,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import VacationsBalanceTable from './VacationsBalanceTable';
import { VACATIONTYPES } from '../Constants';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { userData } from '../../../Helpers/Constants';
import {
  filterDuplicateHolidays,
  getDayNameFromDate,
  getTotalDeductedDays,
  getVacationsByDuration,
  getYearlyWeekends,
  handleDateFieldsChange,
  updateDaysTypeWithHolidays,
  useEffectAsync,
} from '../../../Helpers/Helpers';
import {
  formRequest,
  getHolidays,
  getStaff,
  getVacationsTypes,
  getWeekends,
} from '../../../Helpers/Requests';

export default function VacationsCreateMain({ isActionOnUserBehalf = false }) {
  const [vacationsTypes, setVacationsTypes] = React.useState([]);
  const [holidays, setHolidays] = React.useState([]);
  const [formData, setFormData] = React.useState({
    user_id: null,
    vacation_type_id: null,
    total_days: 0,
    reason: '',
  });
  const [noBalance, setNoBalance] = React.useState(false);
  const [chosenVacationTypeName, setChosenVacationTypeName] = React.useState(
    VACATIONTYPES.Normal.Name,
  );
  const [renderedDuration, setRenderedDuration] = React.useState([]);
  const [currentYear, setCurrentYear] = React.useState(dayjs().year());
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});

  const dateFields = {
    StartDate: {
      Name: 'start_date',
    },
    EndDate: {
      Name: 'end_date',
    },
  };

  useEffectAsync(async () => {
    if (isActionOnUserBehalf) setUsersData(await getStaff());
  }, [isActionOnUserBehalf]);

  // Setting initial vacation data
  useEffectAsync(async () => {
    const _VacationTypes = await getVacationsTypes();

    setVacationsTypes(_VacationTypes);

    setFormData((prevFormData) => ({
      ...prevFormData,
      user_id: isActionOnUserBehalf ? selectedUser.id : null,
      vacation_type_id: _VacationTypes[0].id,
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
    }));
  }, [isActionOnUserBehalf]);

  // Setting Holidays
  useEffectAsync(async () => {
    const [_Holidays, _Weekends] = await Promise.all([
      getHolidays(),
      getWeekends(),
    ]);

    const _YearlyWeekends = getYearlyWeekends(_Weekends);
    const _FilteredYearlyWeekends = filterDuplicateHolidays(
      _YearlyWeekends,
      _Holidays,
    );

    setHolidays([..._Holidays, ..._FilteredYearlyWeekends]);
  }, []);

  function resetDuration() {
    setRenderedDuration([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      total_days: 0,
    }));
  }

  useEffectAsync(async () => {
    if (dayjs(formData.start_date).isValid())
      setCurrentYear(dayjs(formData.start_date).year());

    // if one of the dates are not valid. reset the duration
    if (
      !dayjs(formData.start_date).isValid() ||
      !dayjs(formData.end_date).isValid()
    ) {
      resetDuration();
      return;
    }

    // if years are not equal. warn and reset the duration
    if (dayjs(formData.start_date).year() !== dayjs(formData.end_date).year()) {
      toast(
        'The vacation start and end date must be within the same year.\n' +
          '*hint: If you need a year crossing vacation. do it on two separate requests',
        { type: 'info' },
      );

      resetDuration();
      return;
    }

    const durationDays = getVacationsByDuration(
      formData.start_date,
      formData.end_date,
      formData.vacation_type_id,
      vacationsTypes,
    );
    if (holidays.length) {
      const durationDaysWithTypes = updateDaysTypeWithHolidays(
        durationDays,
        holidays,
      );
      setRenderedDuration(durationDaysWithTypes);

      setFormData((prevFormData) => ({
        ...prevFormData,
        total_days: getTotalDeductedDays(durationDays, holidays),
      }));
    }
  }, [
    formData.start_date,
    formData.end_date,
    formData.vacation_type_id,
    vacationsTypes,
    holidays,
  ]);

  const handleUserChange = (colName, newValueObj) => {
    setSelectedUser({
      [colName]: newValueObj?.id || '',
    });
  };

  const handleTypeChange = (e) => {
    setFormData({
      ...formData,
      vacation_type_id: e.target.value,
    });
    const typeNameById = vacationsTypes.find(
      (type) => type.id === e.target.value,
    ).name;
    setChosenVacationTypeName(typeNameById);
  };

  const handleReasonChange = (e) => {
    setFormData({
      ...formData,
      reason: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setRequestLoading(true);

    e.preventDefault();
    const defaultFormDataReason = formData.reason
      ? formData.reason
      : 'Reason Not Specified';
    const updatedFormData = {
      ...formData,
      reason: defaultFormDataReason,
    };

    const userId = isActionOnUserBehalf ? selectedUser.id : userData.id;
    formRequest(`users/${userId}/vacations`, 'post', updatedFormData)
      .then(() => {
        toast('Vacation Created Successfully.', { type: 'success' });
        setTimeout(() => (window.location.href = `/dashboard`), 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => setRequestLoading(false));
  };

  const createDayComponentByType = (day) => {
    const dayName = getDayNameFromDate(day.start_date);
    return (
      <div className="d-flex w-100">
        <div className="w-50 h6 me-3">{`${dayName} ${day.start_date}`}</div>
        <div className="h6">{day.name}</div>
      </div>
    );
  };

  return (
    <>
      {formData.vacation_type_id && formData.start_date && (
        <>
          <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
            {isActionOnUserBehalf
              ? 'Create Vacation for User'
              : 'Request a Vacation'}
          </div>
          <FormControl className="w-100" variant="standard">
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="d-flex flex-column w-100">
                {isActionOnUserBehalf && (
                  <div className="d-flex">
                    <div className="h5 ms-3 w-25 d-flex">User :</div>
                    <Autocomplete
                      getOptionLabel={(option) => {
                        if (typeof option === 'object') {
                          return String(option.email);
                        } else {
                          const optionObj = _.find(usersData, {
                            id: option,
                          });
                          return optionObj ? String(optionObj.email) : option;
                        }
                      }}
                      isOptionEqualToValue={(optionObj, valueObj) => {
                        return optionObj.id == valueObj;
                      }}
                      value={selectedUser.id || null}
                      options={usersData ? Object.values(usersData) : []}
                      onChange={(e, newValue) =>
                        handleUserChange('id', newValue)
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'Email'}
                          className="mb-1 d-block"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                )}
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex">Vacation type :</div>
                  <TextField
                    select
                    label="Select Type"
                    value={formData.vacation_type_id}
                    onChange={handleTypeChange}
                    className="w-25"
                  >
                    {vacationsTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.display_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Reason :
                  </div>
                  <TextField
                    label="Type a Reason"
                    value={formData.reason}
                    onChange={handleReasonChange}
                    className="w-25"
                  ></TextField>
                </div>
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Start Date :
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From"
                      value={formData.start_date}
                      onChange={(date) =>
                        handleDateFieldsChange(
                          date,
                          'start_date',
                          dateFields,
                          setInvalidDateValue,
                          formData,
                          setFormData,
                        )
                      }
                      inputFormat="DD-MM-YYYY"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div className="d-flex mt-5">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    End Date :
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To"
                      value={formData.end_date}
                      onChange={(date) =>
                        handleDateFieldsChange(
                          date,
                          'end_date',
                          dateFields,
                          setInvalidDateValue,
                          formData,
                          setFormData,
                        )
                      }
                      inputFormat="DD-MM-YYYY"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <VacationsBalanceTable
                user={isActionOnUserBehalf ? selectedUser : null}
                year={currentYear}
                vacationType={chosenVacationTypeName}
                currentlyBooked={formData.total_days}
                noBalance={noBalance}
                setNoBalance={setNoBalance}
              />
            </div>
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Total Days :
              </div>
              <List
                className="w-50 border border-2 pt-3 ps-0"
                style={{ height: '200px', overflowY: 'scroll' }}
              >
                {renderedDuration.map((day) => (
                  <ListItem key={day.start_date}>
                    {createDayComponentByType(day)}
                  </ListItem>
                ))}
              </List>
              <div className="h5 ms-5 w-25 d-flex align-items-center">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="mb-3">Total Deducted Days</div>
                  <div>{formData.total_days} Days</div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex align-items-center">
              <button
                type="submit"
                className="btn btn-success ms-auto me-5"
                onClick={(e) => handleSubmit(e)}
                disabled={
                  noBalance ||
                  !formData.total_days ||
                  requestLoading ||
                  invalidDateValue ||
                  (isActionOnUserBehalf && !selectedUser.id)
                }
              >
                {isActionOnUserBehalf ? 'Create Vacation' : 'Submit Request'}
              </button>
            </div>
          </FormControl>
        </>
      )}
    </>
  );
}
