import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as Helpers from '../../../Helpers/Helpers';

import OvertimeManageApprove from './approve';

import * as Constants from '../../../Helpers/Constants';

import * as OvertimeManageUtils from './utils';

import OvertimeManageReject from './reject';

export default function OvertimeManageShow({
  onClose,
  selectedValue,
  open,
  managedOvertimeRequest,
  currentTable,
}) {
  const [renderedOvertimeRequest, setRenderedOvertimeRequest] = React.useState(
    {},
  );

  const [openApproveRequestDialog, setOpenApproveRequestDialog] =
    React.useState(false);

  const [openRejectRequestDialog, setOpenRejectRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Approve Request dialog //
  const handleApproveRequestOpen = () => setOpenApproveRequestDialog(true);
  const handleApproveRequestClose = () => setOpenApproveRequestDialog(false);
  // Approve Request dialog //

  // Reject Request dialog //
  const handleRejectRequestOpen = () => setOpenRejectRequestDialog(true);
  const handleRejectRequestClose = () => setOpenRejectRequestDialog(false);
  // Reject Request dialog //

  // Component did update
  React.useEffect(() => {
    // Update overtime request.
    const _renderedOvertimeRequest = OvertimeManageUtils.originalToStyled([
      managedOvertimeRequest,
    ]);
    setRenderedOvertimeRequest(_renderedOvertimeRequest[0]);
  }, [managedOvertimeRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === Constants.TablesTypes.Pending) {
    dialogActions = (
      <>
        <div>
          {Helpers.can([Constants.PERMISSIONS.ApproveOvertime]) && (
            <button
              className="btn btn-danger ms-2"
              onClick={() => handleRejectRequestOpen()}
            >
              Reject
            </button>
          )}
        </div>
        <div>
          <Button onClick={handleClose}>Close</Button>

          {Helpers.can([Constants.PERMISSIONS.ApproveOvertime]) && (
            <button
              className="btn btn-success ms-2 me-2 mb-2"
              onClick={() => handleApproveRequestOpen()}
            >
              Approve
            </button>
          )}
        </div>
      </>
    );
  } else if (currentTable.type === Constants.TablesTypes.Finished) {
    dialogActions = (
      <>
        <div className="ms-auto me-2">
          <Button onClick={handleClose}>Close</Button>
        </div>
      </>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedOvertimeRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <OvertimeManageApprove
              open={openApproveRequestDialog}
              onClose={handleApproveRequestClose}
              renderedOvertimeRequest={renderedOvertimeRequest}
            />
            <OvertimeManageReject
              open={openRejectRequestDialog}
              onClose={handleRejectRequestClose}
              renderedOvertimeRequest={renderedOvertimeRequest}
            />
            <DialogTitle>Extra Hours Request Details</DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText
                  primary={<>Request ID : {renderedOvertimeRequest.id}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>User Name : {renderedOvertimeRequest.user}</>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Email : {renderedOvertimeRequest.email}</>}
                />
              </ListItem>
              <ListItem>
                Type :
                <span className={'ms-2 ' + renderedOvertimeRequest.type.class}>
                  {renderedOvertimeRequest.type.name || ''}
                </span>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>Requested Day : {renderedOvertimeRequest.requested_day}</>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Requested Duration :{' '}
                      {renderedOvertimeRequest.requested_duration}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<>Reason : {renderedOvertimeRequest.reason}</>}
                />
              </ListItem>
              <ListItem>
                <div>Status :</div>
                <span
                  className={`
						ms-2 badge
						${renderedOvertimeRequest.status.style.bg}
						${renderedOvertimeRequest.status.style.text}
					`}
                >
                  {renderedOvertimeRequest.status.name}
                </span>
              </ListItem>
              {renderedOvertimeRequest.approved_day && (
                <ListItem>
                  <ListItemText
                    primary={
                      <>Approved Day : {renderedOvertimeRequest.approved_day}</>
                    }
                  />
                </ListItem>
              )}
              {renderedOvertimeRequest.approved_duration && (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Approved Duration :{' '}
                        {renderedOvertimeRequest.approved_duration}
                      </>
                    }
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Management Notes : {renderedOvertimeRequest.comment || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated At :{' '}
                      {renderedOvertimeRequest.updated_at || ''}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Last Updated By :{' '}
                      {renderedOvertimeRequest.updated_by || ''}
                    </>
                  }
                />
              </ListItem>
            </List>

            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
