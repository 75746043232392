import * as React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import * as Requests from '../../../Helpers/Requests';
import { Pages } from '../../../Helpers/Constants';
import { toast } from 'react-toastify';
import { paymentAdjustmentManageFormFields } from '../helpers/Constants';

export default function PaymentAdjustmentManageApprove({
  onClose,
  selectedValue,
  open,
  renderedPaymentAdjustmentRequest,
}) {
  const [isEditFormFieldsLoaded, setIsEditFormFieldsLoaded] =
    React.useState(false);

  const [timeFieldsData, setTimeFieldsData] = React.useState({});

  const [requestLoading, setRequestLoading] = React.useState(false);

  const [editRequestFormValues, setEditRequestFormValues] = React.useState({});

  const handleClose = () => {
    onClose(selectedValue);

    // Reset component status
    setEditRequestFormValues(getFormInitialValues());
  };

  const getFormInitialValues = () => {
    return {
      ...renderedPaymentAdjustmentRequest,
    };
  };

  React.useEffect(() => {
    setEditRequestFormValues(getFormInitialValues());
    setIsEditFormFieldsLoaded(true);
  }, [renderedPaymentAdjustmentRequest]);

  React.useEffect(() => {
    if (isEditFormFieldsLoaded) {
      setEditRequestFormValues({
        ...editRequestFormValues,
      });
    }
  }, [timeFieldsData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditRequestFormValues({
      ...editRequestFormValues,
      [name]: value,
    });
  };

  const handleEditRequestSubmit = (e) => {
    setRequestLoading(true);

    e.preventDefault();

    const formData = { ...editRequestFormValues };

    Requests.formRequest(
      `paymentAdjustments/${renderedPaymentAdjustmentRequest.id}/approve`,
      'put',
      formData,
    )
      .then(() => {
        toast('One Time Payment Approved successfully.', { type: 'success' });
        setTimeout(() => {
          window.location.replace(Pages.PaymentAdjustmentManage.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <form onSubmit={handleEditRequestSubmit} style={{ width: '500px' }}>
        <DialogTitle>Approve One Time Payment Request</DialogTitle>
        <List sx={{ pt: 0 }}>
          <DialogContent>
            <div className="d-flex mt-4">
              <div
                className="me-3 d-flex align-items-center"
                style={{ width: '30%' }}
              >
                {paymentAdjustmentManageFormFields.Comment.Title} :
              </div>
              <div className="d-flex w-100">
                <TextField
                  key={paymentAdjustmentManageFormFields.Comment.Name}
                  label={paymentAdjustmentManageFormFields.Comment.Title}
                  name={paymentAdjustmentManageFormFields.Comment.Name}
                  defaultValue={
                    renderedPaymentAdjustmentRequest[
                      paymentAdjustmentManageFormFields.Comment.Name
                    ]
                  }
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  fullWidth
                  margin="dense"
                />
              </div>
            </div>
          </DialogContent>
        </List>
        <DialogActions className="d-flex justify-content-between">
          <Button onClick={handleClose}>Close</Button>
          <button
            type="submit"
            className="btn btn-success me-3"
            disabled={requestLoading}
          >
            Confirm Approval
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
