import React from 'react';
import EditUser from '../../Users/EditUser';

function InfoCard({
  sectionLabel,
  sectionData,
  userData,
  tableUpdated,
  setTableUpdated,
  section,
}) {
  const [openEditUserDialog, setOpenEditUserDialog] = React.useState(false);
  const [isHovered, setHovered] = React.useState(false);

  const handleEditUserOpen = () => {
    setOpenEditUserDialog(true);
  };
  const handleEditUserClose = () => {
    setOpenEditUserDialog(false);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <>
      <EditUser
        open={openEditUserDialog}
        onClose={handleEditUserClose}
        managedUserData={userData}
        tableUpdated={tableUpdated}
        setTableUpdated={setTableUpdated}
        chosenSection={section}
      />
      <div
        className="card mb-5"
        style={{
          breakInside: 'avoid',
          maxWidth: '30rem',
          // backgroundColor: isHovered ? "#F8F8F8" : "",
          transition: '0.1s ease',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title mb-0">
            <i className="fa fa-user-edit"></i> {sectionLabel}
          </h5>
          {/* <button
            style={{
              background: "none",
              border: "none",
              position: "relative",
            }}
            onClick={handleEditUserOpen}
          >
            <i
              className="fa fa-edit"
              style={{
                position: "absolute",
                right: isHovered ? "2.3rem" : 0,
                top: '5px',
                transition: "0.1s ease-in-out",
                paddingRight: '2px'
              }}
            ></i>
            <span style={{
              zIndex: isHovered ? 999 : -1,
              position: "sticky",
              transition: "0.1s ease-in-out",
            }}>Edit</span>
          </button> */}
        </div>
        <div className="card-body">
          <div className="column mb-4">{sectionData}</div>
        </div>
      </div>
    </>
  );
}

export default InfoCard;
