import React from 'react';
import _ from 'lodash';

export const STATUSES_PAYMENT_ADJUSTMENT = {
  Approved: 1,
  Rejected: 2,
  Pending: 3,
  PendingHrApproval: 20,
  PendingManagerApproval: 60,
};

export const FINISHED_STATUSES_PAYMENT_ADJUSTMENT = [
  STATUSES_PAYMENT_ADJUSTMENT.Approved,
  STATUSES_PAYMENT_ADJUSTMENT.Rejected,
];

export const PENDING_STATUSES_PAYMENT_ADJUSTMENT = [
  STATUSES_PAYMENT_ADJUSTMENT.Pending,
  STATUSES_PAYMENT_ADJUSTMENT.PendingHrApproval,
  STATUSES_PAYMENT_ADJUSTMENT.PendingManagerApproval,
];

export const RequestsTablePaymentAdjustmentStatusesConstants = [
  {
    id: STATUSES_PAYMENT_ADJUSTMENT.Approved,
    style: {
      text: 'text-light',
      bg: 'bg-success',
    },
  },
  {
    id: STATUSES_PAYMENT_ADJUSTMENT.Rejected,
    style: {
      text: 'text-light',
      bg: 'bg-danger',
    },
  },
  {
    id: STATUSES_PAYMENT_ADJUSTMENT.Pending,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
  {
    id: STATUSES_PAYMENT_ADJUSTMENT.PendingHrApproval,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
  {
    id: STATUSES_PAYMENT_ADJUSTMENT.PendingManagerApproval,
    style: {
      text: 'text-light',
      bg: 'bg-secondary',
    },
  },
];

export const paymentAdjustmentRequestFormFields = {
  Type: {
    Name: 'type',
    Title: 'Type',
    PlaceHolder: 'Select a type',
  },
  EmployeeId: {
    Name: 'employee_id',
    Title: 'Email',
    PlaceHolder: 'Email',
  },
  Value: {
    Name: 'value',
    Title: 'Value',
    PlaceHolder: 'Type a value',
  },
  Reason: {
    Name: 'reason',
    Title: 'Reason',
    PlaceHolder: 'Type a Reason',
  },
  Day: {
    Name: 'day',
    Title: 'Day',
    PlaceHolder: 'Select a day',
  },
  Subtype: {
    Name: 'subType',
    Title: 'Subtype',
    PlaceHolder: 'select a subtype',
  },
};

export const paymentAdjustmentDateFields = {
  RequestedDay: paymentAdjustmentRequestFormFields.Day,
};

export const paymentAdjustmentManageFormFields = {
  ...paymentAdjustmentRequestFormFields,
  Comment: {
    Name: 'comment',
    Title: 'Management Notes',
    PlaceHolder: 'Comment',
  },
  Type: {
    Name: 'type',
    Title: 'Type',
    PlaceHolder: 'type',
  },
};

export const handlePaymentAdjustmentInputFieldChange = (
  e,
  fieldName,
  formData,
  setFormData,
) => {
  if (fieldName === paymentAdjustmentRequestFormFields.Value.Name) {
    e.target.value = e.target.value
      .replace(/[^0-9.-]/g, '') // Allow digits, period, and negative sign
      .replace(/(?!^)-/g, '') // Remove any negative signs that are not at the beginning
      .replace(/(\..*)\./g, '$1') // Remove any extra periods than a single one
      .slice(0, 8); // Limit to the first 8 characters
  }

  setFormData({
    ...formData,
    [fieldName]: e.target.value,
  });
};

export const GrantSubTypes = [
  {
    type: 'Engagement',
    value: 14,
  },
  {
    type: 'Marriage',
    value: 21,
  },
  {
    type: 'New Born',
    value: 21,
  },
  {
    type: 'Demise (First Degree Relatives)',
    value: 35,
  },
  {
    type: 'Demise (Second Degree Relatives)',
    value: 21,
  },
];

export const paymentAdjustmentTypes = {
  BonusNormal: {
    id: 1,
    label: 'Monthly Bonus (hrs)',
    class: 'badge bg-info text-dark',
  },
  Grant: {
    id: 2,
    label: 'Grant (hrs)',
    class: 'badge bg-info text-dark',
  },
  Deduction: {
    id: 3,
    label: 'Deduction (LE)',
    class: 'badge bg-info text-dark',
  },
  AddMissedHours: {
    id: 4,
    label: 'Add missing hours (hrs)',
    class: 'badge bg-info text-dark',
  },
  RemoveExtraHours: {
    id: 5,
    label: 'Remove extra hours (hrs)',
    class: 'badge bg-info text-dark',
  },
  OtherDues: {
    id: 7,
    label: 'Other Dues (LE)',
    class: 'badge bg-info text-dark',
  },
  BonusPerformance: {
    id: 8,
    label: 'Performance Bonus (LE)',
    class: 'badge bg-info text-dark',
  },
};

export const NegativeAdjustmentTypes = [
  paymentAdjustmentTypes.Deduction,
  paymentAdjustmentTypes.RemoveExtraHours,
];

export const createPaymentAdjustmentTypeDiv = (typeId) => {
  const type =
    _.find(Object.values(paymentAdjustmentTypes), {
      id: typeId,
    }) || {};
  return <span className={`${type.class}`}>{type.label}</span>;
};

export const addPaymentAdjustmentTypeStylingAttributes = (type) => {
  const typeStyling =
    _.find(Object.values(paymentAdjustmentTypes), {
      id: type.id,
    }) || {};

  return { ...typeStyling, ...type };
};
