import React from 'react';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { PERMISSIONS, TablesTypes } from '../../../../Helpers/Constants';
import { can } from '../../../../Helpers/Helpers';
import Approve from './approve';
import Reject from './reject';
import { originalToStyled } from '../utils';
import LoanInstallments, {
  LoanInstallmentsBgColors,
  LoanInstallmentsComponentTypes,
} from '../../Request/components/LoanInstallments';
import { DialogContent } from '@mui/material';

export default function Show({
  onClose,
  selectedValue,
  open,
  managedLoanRequest,
  currentTable,
}) {
  const [renderedLoanRequest, setRenderedLoanRequest] = React.useState({});

  const [openApproveRequestDialog, setOpenApproveRequestDialog] =
    React.useState(false);

  const [openRejectRequestDialog, setOpenRejectRequestDialog] =
    React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // Approve Request dialog //
  const handleApproveRequestOpen = () => setOpenApproveRequestDialog(true);
  const handleApproveRequestClose = () => setOpenApproveRequestDialog(false);
  // Approve Request dialog //

  // Reject Request dialog //
  const handleRejectRequestOpen = () => setOpenRejectRequestDialog(true);
  const handleRejectRequestClose = () => setOpenRejectRequestDialog(false);
  // Reject Request dialog //

  // Component did update
  React.useEffect(() => {
    // Update Loan request.
    const _renderedLoanRequest = originalToStyled([managedLoanRequest]);
    setRenderedLoanRequest(_renderedLoanRequest[0]);
  }, [managedLoanRequest]);

  // Controlling dialog buttons depending on the current table
  let dialogActions = <></>;
  if (currentTable.type === TablesTypes.Pending) {
    dialogActions = (
      <>
        <div>
          {can([PERMISSIONS.ApproveLoans]) && (
            <button
              className="btn btn-danger ms-2"
              onClick={() => handleRejectRequestOpen()}
            >
              Reject
            </button>
          )}
        </div>
        <div>
          <Button onClick={handleClose}>Close</Button>

          {can([PERMISSIONS.ApproveLoans]) && (
            <button
              className="btn btn-success ms-2 me-2 mb-2"
              onClick={() => handleApproveRequestOpen()}
            >
              Approve
            </button>
          )}
        </div>
      </>
    );
  } else if (currentTable.type === TablesTypes.Finished) {
    dialogActions = (
      <div className="d-flex justify-content-between w-100 mx-1 mb-1">
        <div className="d-flex justify-content-end w-100">
          <button className="btn btn-secondary ms-3 mb-1" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    );
  }
  // Controlling dialog buttons depending on the current table

  return (
    <>
      {Object.keys(renderedLoanRequest).length > 0 && (
        <>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <Approve
              open={openApproveRequestDialog}
              onClose={handleApproveRequestClose}
              renderedLoanRequest={renderedLoanRequest}
            />
            <Reject
              open={openRejectRequestDialog}
              onClose={handleRejectRequestClose}
              renderedLoanRequest={renderedLoanRequest}
            />
            <DialogTitle>Loan Request Details</DialogTitle>
            <DialogContent dividers>
              <List sx={{ pt: 0 }}>
                <ListItem>
                  <ListItemText
                    primary={<>Request ID : {renderedLoanRequest.id}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Email : {renderedLoanRequest.email}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Reason : {renderedLoanRequest.reason}</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<>Value : {renderedLoanRequest.value} (LE)</>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Installments Count :{' '}
                        {renderedLoanRequest.installments_count}
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <div>Status :</div>
                  <span
                    className={`
						ms-2 badge
						${renderedLoanRequest.status.style.bg}
						${renderedLoanRequest.status.style.text}
					`}
                  >
                    {renderedLoanRequest.status.name}
                  </span>
                </ListItem>
                {renderedLoanRequest.comment && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          Management Notes : {renderedLoanRequest.comment || ''}
                        </>
                      }
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        Last Updated At : {renderedLoanRequest.updated_at || ''}
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span>
                        Last Updated By : {renderedLoanRequest.updated_by || ''}
                      </span>
                    }
                  />
                </ListItem>
                <LoanInstallments
                  rowsData={managedLoanRequest.installments}
                  displayType={LoanInstallmentsComponentTypes.View}
                  backgroundColor={LoanInstallmentsBgColors.White}
                />
              </List>
            </DialogContent>
            <DialogActions className="d-flex justify-content-between">
              {dialogActions}
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
