import dayjs from 'dayjs';

const IdCellIndex = 1;

const ExpiryWarningLimit = 30;

export const RequiredPhotosCount = 6;

export const UsersList = {
  ALL: 'all',
  TERMINATED: 'terminated',
};

export const UsersTableCols = {
  Name: { Name: 'name', Label: 'Name', Type: 'text' },
  ID: { Name: 'id', Label: 'ID' },
  Code: { Name: 'code', Label: 'Code', Type: 'number' },
  Email: { Name: 'email', Label: 'Email', Type: 'email' },
  Team: { Name: 'team', Label: 'Team' },
  Role: { Name: 'role', Label: 'Role' },
  RoleStartDate: {
    Name: 'role_start_date',
    Label: 'Role Start Date',
    Width: 18,
  },
  Manager: { Name: 'manager', Label: 'Direct Manager' },
  NameActivityTimeline: {
    Name: 'name_activity_timeline',
    Label: 'Name Activity Timeline',
    Type: 'text',
  },
  NameArabic: { Name: 'name_arabic', Label: 'Name Arabic', Type: 'text' },
  NameAbbreviation: {
    Name: 'name_abbreviation',
    Label: 'Name Abbreviation',
    Type: 'text',
  },
  PersonalEmail: {
    Name: 'personal_email',
    Label: 'Personal Email',
    Type: 'email',
  },
  Mobile1: { Name: 'mobile_1', Label: 'Mobile 1', Type: 'number' },
  Mobile2: { Name: 'mobile_2', Label: 'Mobile 2', Type: 'number' },
  LandLine: { Name: 'land_line', Label: 'Land Line', Type: 'number' },
  Religion: {
    Name: 'religion',
    Label: 'Religion',
    Options: {
      Muslim: { id: 'Muslim', name: 'Muslim' },
      Christian: { id: 'Christian', name: 'Christian' },
    },
    Width: 11.5,
  },
  Gender: {
    Name: 'gender',
    Label: 'Gender',
    Options: {
      Male: { id: 'Male', name: 'Male' },
      Female: { id: 'Female', name: 'Female' },
    },
    Width: 11.5,
  },
  BirthDate: { Name: 'birth_date', Label: 'Birth Date', Width: 18 },
  Country: {
    Name: 'country',
    Label: 'Country',
    Options: {
      Egypt: { id: 'Egypt', name: 'Egypt' },
    },
    Width: 11.5,
  },
  City: {
    Name: 'city',
    Label: 'City',
    Options: {
      Alexandria: { id: 'Alexandria', name: 'Alexandria' },
      Assiut: { id: 'Assiut', name: 'Assiut' },
      Aswan: { id: 'Aswan', name: 'Aswan' },
      Beheira: { id: 'Beheira', name: 'Beheira' },
      BaniSuef: { id: 'Bani Suef', name: 'Bani Suef' },
      Cairo: { id: 'Cairo', name: 'Cairo' },
      Daqahliya: { id: 'Daqahliya', name: 'Daqahliya' },
      Damietta: { id: 'Damietta', name: 'Damietta' },
      Fayyoum: { id: 'Fayyoum', name: 'Fayyoum' },
      Gharbiya: { id: 'Gharbiya', name: 'Gharbiya' },
      Giza: { id: 'Giza', name: 'Giza' },
      Helwan: { id: 'Helwan', name: 'Helwan' },
      Ismailia: { id: 'Ismailia', name: 'Ismailia' },
      KafrElSheikh: { id: 'Kafr El Sheikh', name: 'Kafr El Sheikh' },
      Luxor: { id: 'Luxor', name: 'Luxor' },
      MarsaMatrouh: { id: 'Marsa Matrouh', name: 'Marsa Matrouh' },
      Minya: { id: 'Minya', name: 'Minya' },
      AlMinufiyah: { id: 'Al Minufiyah', name: 'Al Minufiyah' },
      NewValley: { id: 'New Valley', name: 'New Valley' },
      NorthSinai: { id: 'North Sinai', name: 'North Sinai' },
      PortSaid: { id: 'Port Said', name: 'Port Said' },
      Qalioubiya: { id: 'Qalioubiya', name: 'Qalioubiya' },
      Qena: { id: 'Qena', name: 'Qena' },
      RedSea: { id: 'Red Sea', name: 'Red Sea' },
      Sharqiya: { id: 'Sharqiya', name: 'Sharqiya' },
      Sohag: { id: 'Sohag', name: 'Sohag' },
      SouthSinai: { id: 'South Sinai', name: 'South Sinai' },
      Suez: { id: 'Suez', name: 'Suez' },
      Tanta: { id: 'Tanta', name: 'Tanta' },
    },
    Width: 11.5,
  },
  Address: { Name: 'address', Label: 'Address', Type: 'text' },
  University: { Name: 'university', Label: 'University', Type: 'text' },
  Faculty: { Name: 'faculty', Label: 'Faculty', Type: 'text' },
  Specialization: {
    Name: 'specialization',
    Label: 'Specialization',
    Type: 'text',
  },
  Degree: { Name: 'degree', Label: 'Degree', Type: 'text' },
  GraduationYear: {
    Name: 'graduation_year',
    Label: 'Graduation Year',
    Type: 'number',
  },
  EmploymentType: {
    Name: 'employment_type',
    Label: 'Employment Type',
    Options: {
      FullTime: { id: 'Full Time', name: 'Full Time' },
      PartTime: { id: 'Part Time', name: 'Part Time' },
      Switched: { id: 'Switched', name: 'Switched' },
      Terminated: { id: 'Terminated', name: 'Terminated' },
    },
    Width: 11.5,
  },
  TerminationType: {
    Name: 'termination_type',
    Label: 'Termination Type',
    Options: {
      Fired: { id: 'Fired', name: 'Fired' },
      Resignation: { id: 'Resignation', name: 'Resignation' },
      Retired: { id: 'Retired', name: 'Retired' },
    },
    Width: 11.5,
  },
  TerminationNotes: {
    Name: 'termination_notes',
    Label: 'Termination Notes',
    Type: 'text',
  },
  JoinDataParadigm: {
    Name: 'join_date_paradigm',
    Label: 'Join Date Paradigm',
    Width: 18,
  },
  JoinDataParagons: {
    Name: 'join_date_paragons',
    Label: 'Join Date Paragons',
    Width: 18,
  },
  HourRate: { Name: 'hour_rate', Label: 'Hour Rate', Type: 'number' },
  PaymentMethod: {
    Name: 'payment_method',
    Label: 'Payment Method',
    Options: {
      Bank: { id: 'Bank', name: 'Bank' },
      Cash: { id: 'Cash', name: 'Cash' },
    },
    Width: 11.5,
  },
  BankAccountNumber: {
    Name: 'bank_account_number',
    Label: 'Bank Account Number',
    Type: 'number',
  },
  BankEmployeeID: {
    Name: 'bank_employee_id',
    Label: 'Bank Employee ID',
    Type: 'number',
  },
  BankBranchNumber: {
    Name: 'bank_branch_number',
    Label: 'Bank Branch Number',
    Type: 'number',
  },
  MilitaryStatus: {
    Name: 'military_status',
    Label: 'Military Status',
    Options: {
      NotRequired: { id: 'Not Required', name: 'Not Required' },
      Done: { id: 'Done', name: 'Done' },
      Required: { id: 'Required', name: 'Required' },
      TemporaryExempted: {
        id: 'Temporary Exempted',
        name: 'Temporary Exempted',
      },
      PermanentlyExempted: {
        id: 'Permanently Exempted',
        name: 'Permanently Exempted',
      },
      InMilitary: { id: 'In Military', name: 'In Military' },
    },
    Width: 11.5,
  },
  InsuranceStatus: {
    Name: 'insurance_status',
    Label: 'Insurance Status',
    Options: {
      Insured: { id: 'Insured', name: 'Insured' },
      NotInsured: { id: 'Not Insured', name: 'Not Insured' },
      InsuredInAnotherCompany: {
        id: 'Insured In Another Company',
        name: 'Insured In Another Company',
      },
    },
    Width: 11.5,
  },
  InsuranceNumber: {
    Name: 'insurance_number',
    Label: 'Insurance Number',
    Type: 'number',
  },
  InsuranceSalary: {
    Name: 'insurance_salary',
    Label: 'Insurance Salary',
    Type: 'number',
  },
  PreviousInsurancePeriods: {
    Name: 'previous_insurance_periods',
    Label: 'Previous Insurance Periods',
    Type: 'text',
  },

  PrivateMedicalInsuranceStatus: {
    Name: 'private_medical_insurance_status',
    Label: 'Private Medical Insurance Status',
    Options: {
      Yes: { id: true, name: 'Yes' },
      No: { id: false, name: 'No' },
    },
    Width: 18,
  },
  PrivateMedicalInsuranceExpiryDate: {
    Name: 'private_medical_insurance_expiry_date',
    Label: 'Private Medical Insurance Expiry Date',
    Width: 18,
  },
  PublicMedicalInsuranceStatus: {
    Name: 'public_medical_insurance_status',
    Label: 'Public Medical Insurance Status',
    Options: {
      Yes: { id: true, name: 'Yes' },
      No: { id: false, name: 'No' },
    },
    Width: 18,
  },
  PublicMedicalInsuranceExpiryDate: {
    Name: 'public_medical_insurance_expiry_date',
    Label: 'Public Medical Insurance Expiry Date',
    Width: 18,
  },
  WorkforceCertificate: {
    Name: 'workforce_certificate',
    Label: 'Workforce Certificate',
  },
  WorkforceCertificateNumber: {
    Name: 'workforce_certificate_number',
    Label: 'Workforce Certificate Number',
    Type: 'number',
  },
  WorkforceCertificateDate: {
    Name: 'workforce_certificate_date',
    Label: 'Workforce Certificate Date',
    Width: 18,
  },
  DisabilityType: {
    Name: 'disability_type',
    Label: 'Disability Type',
    Type: 'text',
  },
  MaritalStatus: {
    Name: 'marital_status',
    Label: 'Marital Status',
    Options: {
      Single: { id: 'Single', name: 'Single' },
      Engaged: { id: 'Engaged', name: 'Engaged' },
      Married: { id: 'Married', name: 'Married' },
      Divorced: { id: 'Divorced', name: 'Divorced' },
      Widow: { id: 'Widow', name: 'Widow' },
    },
    Width: 11.5,
  },
  NumberOfKids: {
    Name: 'number_of_kids',
    Label: 'Number Of Kids',
    Options: {
      0: { id: 0, name: 0 },
      1: { id: 1, name: 1 },
      2: { id: 2, name: 2 },
      3: { id: 3, name: 3 },
      4: { id: 4, name: 4 },
      5: { id: 5, name: 5 },
    },
    Width: 11.5,
  },
  IdentityCard: { Name: 'identity_card', Label: 'Identity Card' },
  IdentityCardExpiryDate: {
    Name: 'identity_card_expiry_date',
    Label: 'Identity Card Expiry',
    Width: 18,
  },
  NationalIdNumber: {
    Name: 'national_id_number',
    Label: 'National ID Number',
    Type: 'number',
    MaxLength: 14,
  },
  Contract: { Name: 'contract', Label: 'Contract' },
  ContractStartDate: {
    Name: 'contract_start_date',
    Label: 'Contract Start Date',
    Width: 18,
  },
  ContractEndDate: {
    Name: 'contract_end_date',
    Label: 'Contract End Date',
    Width: 18,
  },
  ContractRenew: {
    Name: 'contract_renew',
    Label: 'Contract Renew',
    Options: {
      Yes: { id: true, name: 'Yes' },
      No: { id: false, name: 'No' },
    },
    Width: 11.5,
  },
  PositionInContract: {
    Name: 'position_in_contract',
    Label: 'Position in Contract',
    Type: 'text',
  },
  GrossSalaryInContract: {
    Name: 'gross_salary_in_contract',
    Label: 'Gross Salary in Contract',
    Type: 'number',
  },
  AssociationCard: { Name: 'association_card', Label: 'Association Card' },
  AssociationCardExpiryDate: {
    Name: 'association_card_expiry_date',
    Label: 'Association Card Expiry Date',
    Width: 18,
  },
  BirthCertificate: { Name: 'birth_certificate', Label: 'Birth Certificate' },
  GraduationCertificate: {
    Name: 'graduation_certificate',
    Label: 'Graduation Certificate',
  },
  MilitaryCertificate: {
    Name: 'military_certificate',
    Label: 'Military Certificate',
  },
  MilitaryCertificateExpiryDate: {
    Name: 'military_certificate_expiry_date',
    Label: 'Military Certificate Expiry',
    Width: 18,
  },
  PersonalPhoto: { Name: 'personal_photo', Label: 'Personal Photo' },
  PersonalPhotoCount: {
    Name: 'personal_photo_count',
    Label: 'Personal Photos Count',
    Options: {
      0: { id: 0, name: 0 },
      1: { id: 1, name: 1 },
      2: { id: 2, name: 2 },
      3: { id: 3, name: 3 },
      4: { id: 4, name: 4 },
      5: { id: 5, name: 5 },
      6: { id: 6, name: 6 },
    },
    Width: 11.5,
  },
  CriminalRecord: { Name: 'criminal_record', Label: 'Criminal Record' },
  Certificate111: { Name: 'certificate_111', Label: 'Certificate 111' },
  Certificate111ExpiryDate: {
    Name: 'certificate_111_expiry_date',
    Label: 'Certificate 111 Expiry',
    Width: 18,
  },
  SkillCertificate: { Name: 'skill_certificate', Label: 'Skill Certificate' },
  HealthCertificate: {
    Name: 'health_certificate',
    Label: 'Health Certificate',
  },
  C1Document: { Name: 'c1_document', Label: 'C1 Document' },
  C2Document: { Name: 'c2_document', Label: 'C2 Document' },
  C6Document: { Name: 'c6_document', Label: 'C6 Document' },

  // Calculated Columns
  DocumentsFullAndUpdated: {
    Name: 'has_document_expiring_soon',
    Label: 'Papers ok',
  },
};

export const Sections = {
  BasicInfo: {
    Label: 'Basic Info',
    Columns: [
      UsersTableCols.Name,
      UsersTableCols.ID,
      UsersTableCols.Code,
      UsersTableCols.Email,
      UsersTableCols.NameActivityTimeline,
      UsersTableCols.NameArabic,
      UsersTableCols.NameAbbreviation,
      UsersTableCols.PersonalEmail,
      UsersTableCols.Mobile1,
      UsersTableCols.Mobile2,
      UsersTableCols.LandLine,
      UsersTableCols.Religion,
      UsersTableCols.Gender,
      UsersTableCols.BirthDate,
      UsersTableCols.Country,
      UsersTableCols.City,
      UsersTableCols.Address,
      UsersTableCols.University,
      UsersTableCols.Faculty,
      UsersTableCols.Specialization,
      UsersTableCols.Degree,
      UsersTableCols.GraduationYear,
      UsersTableCols.MilitaryStatus,
      UsersTableCols.MaritalStatus,
      UsersTableCols.NumberOfKids,
    ],
  },
  PositionInfo: {
    Label: 'Position Info',
    Columns: [
      UsersTableCols.JoinDataParadigm,
      UsersTableCols.JoinDataParagons,
      UsersTableCols.EmploymentType,
      UsersTableCols.TerminationType,
      UsersTableCols.TerminationNotes,
      UsersTableCols.Team,
      UsersTableCols.Role,
      UsersTableCols.RoleStartDate,
      UsersTableCols.Manager,
    ],
  },
  PaymentInfo: {
    Label: 'Payment Info',
    Columns: [
      UsersTableCols.HourRate,
      UsersTableCols.PaymentMethod,
      UsersTableCols.BankAccountNumber,
      UsersTableCols.BankEmployeeID,
      UsersTableCols.BankBranchNumber,
    ],
  },
  InsuranceInfo: {
    Label: 'Insurance Info',
    Columns: [
      UsersTableCols.InsuranceStatus,
      UsersTableCols.InsuranceNumber,
      UsersTableCols.InsuranceSalary,
      UsersTableCols.PreviousInsurancePeriods,
      UsersTableCols.PrivateMedicalInsuranceStatus,
      UsersTableCols.PrivateMedicalInsuranceExpiryDate,
      UsersTableCols.PublicMedicalInsuranceStatus,
      UsersTableCols.PublicMedicalInsuranceExpiryDate,
    ],
  },
  PapersInfo: {
    Label: 'Papers Info',
    Columns: [
      UsersTableCols.WorkforceCertificate,
      UsersTableCols.WorkforceCertificateNumber,
      UsersTableCols.WorkforceCertificateDate,
      UsersTableCols.DisabilityType,
      UsersTableCols.IdentityCard,
      UsersTableCols.IdentityCardExpiryDate,
      UsersTableCols.NationalIdNumber,
      UsersTableCols.Contract,
      UsersTableCols.ContractStartDate,
      UsersTableCols.ContractEndDate,
      UsersTableCols.ContractRenew,
      UsersTableCols.PositionInContract,
      UsersTableCols.GrossSalaryInContract,
      UsersTableCols.AssociationCard,
      UsersTableCols.AssociationCardExpiryDate,
      UsersTableCols.BirthCertificate,
      UsersTableCols.GraduationCertificate,
      UsersTableCols.MilitaryCertificate,
      UsersTableCols.MilitaryCertificateExpiryDate,
      UsersTableCols.PersonalPhoto,
      UsersTableCols.PersonalPhotoCount,
      UsersTableCols.CriminalRecord,
      UsersTableCols.Certificate111,
      UsersTableCols.Certificate111ExpiryDate,
      UsersTableCols.SkillCertificate,
      UsersTableCols.HealthCertificate,
      UsersTableCols.C1Document,
      UsersTableCols.C2Document,
      UsersTableCols.C6Document,
      UsersTableCols.DocumentsFullAndUpdated,
    ],
  },
};

export const ProfileOrderedSections = [
  Sections.BasicInfo,
  Sections.PaymentInfo,
  Sections.InsuranceInfo,
  Sections.PositionInfo,
  Sections.PapersInfo,
];

export const FilesColumns = [
  UsersTableCols.WorkforceCertificate,
  UsersTableCols.IdentityCard,
  UsersTableCols.Contract,
  UsersTableCols.AssociationCard,
  UsersTableCols.BirthCertificate,
  UsersTableCols.GraduationCertificate,
  UsersTableCols.MilitaryCertificate,
  UsersTableCols.PersonalPhoto,
  UsersTableCols.CriminalRecord,
  UsersTableCols.Certificate111,
  UsersTableCols.SkillCertificate,
  UsersTableCols.HealthCertificate,
  UsersTableCols.C1Document,
  UsersTableCols.C2Document,
  UsersTableCols.C6Document,
];

export const TextCols = [
  UsersTableCols.Code,
  UsersTableCols.Name,
  UsersTableCols.NameArabic,
  UsersTableCols.NameAbbreviation,
  UsersTableCols.NationalIdNumber,
  UsersTableCols.Address,
  UsersTableCols.Mobile1,
  UsersTableCols.Mobile2,
  UsersTableCols.LandLine,
  UsersTableCols.Email,
  UsersTableCols.PersonalEmail,
  UsersTableCols.PositionInContract,
  UsersTableCols.GrossSalaryInContract,
  UsersTableCols.InsuranceNumber,
  UsersTableCols.InsuranceSalary,
  UsersTableCols.PreviousInsurancePeriods,
  UsersTableCols.WorkforceCertificateNumber,
  UsersTableCols.University,
  UsersTableCols.Faculty,
  UsersTableCols.Specialization,
  UsersTableCols.Degree,
  UsersTableCols.GraduationYear,
  UsersTableCols.DisabilityType,
  UsersTableCols.HourRate,
  UsersTableCols.BankAccountNumber,
  UsersTableCols.BankEmployeeID,
  UsersTableCols.BankBranchNumber,
  //  UsersTableCols.TerminationNotes,
];

export const DropDownCols = [
  UsersTableCols.MaritalStatus,
  UsersTableCols.Religion,
  UsersTableCols.Country,
  UsersTableCols.City,
  UsersTableCols.MilitaryStatus,
  UsersTableCols.NumberOfKids,
  UsersTableCols.EmploymentType,
  UsersTableCols.InsuranceStatus,
  UsersTableCols.PaymentMethod,
  UsersTableCols.Gender,
  UsersTableCols.Manager,
  //  UsersTableCols.TerminationType,
  UsersTableCols.ContractRenew,
  UsersTableCols.PersonalPhotoCount,
  UsersTableCols.PrivateMedicalInsuranceStatus,
  UsersTableCols.PublicMedicalInsuranceStatus,
];

export const DocumentCols = [
  UsersTableCols.IdentityCard,
  UsersTableCols.Contract,
  UsersTableCols.WorkforceCertificate,
  UsersTableCols.AssociationCard,
  UsersTableCols.BirthCertificate,
  UsersTableCols.GraduationCertificate,
  UsersTableCols.MilitaryCertificate,
  UsersTableCols.PersonalPhoto,
  UsersTableCols.CriminalRecord,
  UsersTableCols.Certificate111,
  UsersTableCols.SkillCertificate,
  UsersTableCols.HealthCertificate,
  UsersTableCols.C1Document,
  UsersTableCols.C2Document,
  UsersTableCols.C6Document,
];

export const ExpiryCols = [
  UsersTableCols.IdentityCardExpiryDate,
  UsersTableCols.ContractEndDate,
  UsersTableCols.PrivateMedicalInsuranceExpiryDate,
  UsersTableCols.PublicMedicalInsuranceExpiryDate,
  UsersTableCols.AssociationCardExpiryDate,
  UsersTableCols.MilitaryCertificateExpiryDate,
  UsersTableCols.Certificate111ExpiryDate,
];

export const DateCols = [
  UsersTableCols.BirthDate,
  UsersTableCols.RoleStartDate,
  UsersTableCols.JoinDataParadigm,
  UsersTableCols.JoinDataParagons,
  UsersTableCols.ContractStartDate,
  UsersTableCols.WorkforceCertificateDate,
];

export const getColNames = (array) => {
  return array.map((column) => column.Name);
};

export const getColLabels = (array) => {
  return array.map((column) => column.Label);
};

const stickyStyle = (left, zIndex) => ({
  style: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    background: 'white',
    left,
    zIndex,
  },
});

export const getTableCols = () => {
  const tableCols = [
    {
      colDetails: UsersTableCols.Name,
      isFilterable: false,
      isSortable: true,
      isShown: true,
      setCellProps: () => stickyStyle('0', '100'),
      setCellHeaderProps: () => stickyStyle('0', '101'),
    },
    {
      colDetails: UsersTableCols.ID,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Code,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Email,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Team,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Role,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Manager,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.RoleStartDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.NameActivityTimeline,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.NameArabic,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.NameAbbreviation,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PersonalEmail,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Mobile1,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Mobile2,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.LandLine,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Religion,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Gender,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.BirthDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Country,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.City,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Address,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.University,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Faculty,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Specialization,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Degree,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.GraduationYear,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.EmploymentType,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.TerminationType,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.TerminationNotes,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.JoinDataParadigm,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.JoinDataParagons,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.HourRate,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PaymentMethod,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.BankAccountNumber,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.BankEmployeeID,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.BankBranchNumber,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.MilitaryStatus,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.InsuranceStatus,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.InsuranceNumber,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.InsuranceSalary,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PreviousInsurancePeriods,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PrivateMedicalInsuranceStatus,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PrivateMedicalInsuranceExpiryDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PublicMedicalInsuranceStatus,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PublicMedicalInsuranceExpiryDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.WorkforceCertificate,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.WorkforceCertificateNumber,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.WorkforceCertificateDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.DisabilityType,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.MaritalStatus,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.NumberOfKids,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.IdentityCard,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.IdentityCardExpiryDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.NationalIdNumber,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Contract,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.ContractStartDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.ContractEndDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.ContractRenew,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PositionInContract,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.GrossSalaryInContract,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.AssociationCard,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.AssociationCardExpiryDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.BirthCertificate,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.GraduationCertificate,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.MilitaryCertificate,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.MilitaryCertificateExpiryDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PersonalPhoto,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.PersonalPhotoCount,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.CriminalRecord,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Certificate111,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.Certificate111ExpiryDate,
      isFilterable: false,
      isSortable: true,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.SkillCertificate,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.HealthCertificate,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.C1Document,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.C2Document,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      colDetails: UsersTableCols.C6Document,
      isFilterable: false,
      isSortable: false,
      isShown: true,
    },
    {
      // Calculated Column
      colDetails: UsersTableCols.DocumentsFullAndUpdated,
      isFilterable: true,
      isSortable: true,
      isShown: true,
    },
  ];

  return createTableCols(tableCols);
};

const createTableCols = (tableCols) => {
  return tableCols.map((tableCol) => {
    return createTableCol(...Object.values(tableCol));
  });
};

const createTableCol = (
  colDetails,
  isFilterable,
  isSortable,
  isShown,
  setCellProps,
  setCellHeaderProps,
) => {
  return {
    name: colDetails.Name,
    label: colDetails.Label,
    options: {
      filter: isFilterable,
      sort: isSortable,
      display: isShown,
      setCellProps,
      setCellHeaderProps,
    },
  };
};

export const getTableOptions = (handleRowClick) => {
  return {
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'multiselect',
    downloadOptions: {
      filename: 'paragons-employees.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onRowClick: (row) => {
      handleRowClick(row[IdCellIndex]);
    },
    responsive: 'standard',
    setRowProps: () => ({
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    }),
  };
};

export const getExpiryRemainingDays = (expiryDate) => {
  return dayjs(expiryDate).diff(dayjs(), 'day');
};

export const isDocumentExpiringSoon = (expiryRemainingDays) => {
  return expiryRemainingDays <= ExpiryWarningLimit;
};

const isUserDocumentsFullAndUpdated = (userData, ExpiryCols) => {
  let documentsFullAndUpdated = true;

  // Check documents expiry
  getColNames(ExpiryCols).forEach((expiryCol) => {
    const expiryRemainingDays = getExpiryRemainingDays(userData[expiryCol]);
    if (isDocumentExpiringSoon(expiryRemainingDays)) {
      documentsFullAndUpdated = false;
    }
  });

  // Check documents missing
  getColNames(DocumentCols).forEach((documentCol) => {
    if (!userData[documentCol]) {
      documentsFullAndUpdated = false;
    }
  });

  // Check photos count
  if (parseInt(userData[UsersTableCols.PersonalPhotoCount.Name]) < 6) {
    documentsFullAndUpdated = false;
  }

  return documentsFullAndUpdated;
};

export const formatUsersData = (usersData) => {
  return usersData.map((user) => {
    const formattedUser = { ...user };

    // Create document expiring soon flag
    formattedUser[UsersTableCols.DocumentsFullAndUpdated.Name] =
      isUserDocumentsFullAndUpdated(formattedUser, ExpiryCols) ? 'Yes' : 'No';

    // format team and role info
    formattedUser[UsersTableCols.Team.Name] = user[UsersTableCols.Team.Name]
      ? user[UsersTableCols.Team.Name].name
      : null;
    formattedUser[UsersTableCols.Role.Name] = user[UsersTableCols.Role.Name]
      ? user[UsersTableCols.Role.Name].display_name
      : null;
    formattedUser[UsersTableCols.Manager.Name] = user[
      UsersTableCols.Manager.Name
    ]
      ? user[UsersTableCols.Manager.Name].name
      : null;
    return formattedUser;
  });
};

export const extractShownUserData = (userData) => {
  return {
    ...userData,
    [UsersTableCols.Team.Name]: userData[UsersTableCols.Team.Name]
      ? userData[UsersTableCols.Team.Name].name
      : null,
    [UsersTableCols.Role.Name]: userData[UsersTableCols.Role.Name]
      ? userData[UsersTableCols.Role.Name].display_name
      : null,
    [UsersTableCols.Manager.Name]: userData[UsersTableCols.Manager.Name]
      ? userData[UsersTableCols.Manager.Name].name
      : null,
  };
};

export const isPhotosCountComplete = (photosCount) => {
  return photosCount >= RequiredPhotosCount;
};
