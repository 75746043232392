import * as React from 'react';
import Calendar from 'rc-year-calendar';

import 'tippy.js/dist/tippy.css'; // optional for styling
import tippy from 'tippy.js';
import 'tippy.js/themes/light.css';

import structuredClone from '@ungap/structured-clone';
import { useParams, useLocation } from 'react-router-dom';

import './VacationsView.css';
import VacationViewModal from './VacationViewModal';

import * as Requests from '../../../Helpers/Requests';
import * as Helpers from '../../../Helpers/Helpers';
import * as Constants from '../../../Helpers/Constants';
import NoPermission from '../../ErrorPages/NoPermission';
import {
  createEmptyOriginalObj,
  originalToRendered,
  renderedToOriginal,
} from '../../Dashboard/DashboardUtils';
import {
  BALANCE_REQUEST_STATUSES,
  PENDING_STATUSES_VACATIONS,
  STATUSES_VACATIONS,
  VACATIONTYPES,
} from '../Constants';

export default function VacationsViewMain() {
  const params = useParams();
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>//
  // >>>>>>>>>>>>>>>>>>>>>>> Vacations Data >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>//
  const [showModal, setShowModal] = React.useState(null);
  const [renderedData, setRenderedData] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  const [vacationsTypes, setVacationsTypes] = React.useState([]);
  const [vacationTypesBalances, setVacationTypesBalances] = React.useState([]);
  const [vacationTypesBalancesLoaded, setVacationTypesBalancesLoaded] =
    React.useState(false);
  const [currentVacation, setCurrentVacation] = React.useState({});
  const [requestedUser, setRequestedUser] = React.useState({});

  let tooltip = null;
  const location = useLocation();
  // <<<<<<<<<<<<<<<<<<<<<<< Vacations Data <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
  React.useEffect(() => {
    Requests.getVacationsTypes()
      .then((types) => {
        setVacationsTypes(types);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, []);

  React.useEffect(() => {
    // update component when the url change.
    Requests.getRequestedUser(params.userId)
      .then((user) => {
        setRequestedUser(user);
      })
      .catch((err) => Requests.handleRequestErrors(err));

    const RequiredStatuses = [
      BALANCE_REQUEST_STATUSES.Approved,
      BALANCE_REQUEST_STATUSES.Pending,
    ];

    Requests.getUserVacationBalances(params.userId, RequiredStatuses)
      .then((_vacationTypesBalances) => {
        setVacationTypesBalances(_vacationTypesBalances);
        setVacationTypesBalancesLoaded(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));

    const filterFieldsVacations = {
      'status[]': [...PENDING_STATUSES_VACATIONS, STATUSES_VACATIONS.Approved],
    };

    Requests.getUserVacations(params.userId, filterFieldsVacations)
      .then((vacations) => {
        setOriginalData(vacations.data);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [location]);

  React.useEffect(() => {
    // Convert date format to match the calendar library requirement.
    let _renderedData = originalToRendered(originalData);
    _renderedData = Helpers.injectVacationsStatusStyle(_renderedData);
    setRenderedData(_renderedData);
  }, [originalData]);

  const handleDayEnter = (e) => {
    if (e.events.length > 0) {
      const content = `
          <div class="event-tooltip-content">
            <div
              style="
			    color:${e.events[0].color};
			  ">
              ${e.events[0].reason}
            </div>
          </div>
        `;

      tooltip = tippy(e.element, {
        allowHTML: true,
        placement: 'right',
        content,
        animateFill: false,
        animation: 'shift-away',
        arrow: true,
        theme: 'light',
      });
      tooltip.show();
    }
  };

  const handleDayLeave = () => {
    if (tooltip) {
      tooltip.destroy();
      tooltip = null;
    }
  };

  const handleDayClick = (e) => {
    if (e.events.length) {
      setShowModal('view');
      let _currentVacation = createEmptyOriginalObj();
      _currentVacation = {
        ..._currentVacation,
        ...structuredClone(e.events[0]),
      };
      setCurrentVacation(renderedToOriginal([_currentVacation])[0]);
    }
  };

  const customRenderEvent = (div, dateString, event) => {
    div.style.borderRadius = '0px';

    if (event[0].status.id == STATUSES_VACATIONS.Approved) {
      div.style.backgroundColor = event[0].vacationType.color;

      if (['#737373', 'orange'].includes(event[0].vacationType.color)) {
        div.style.color = 'black';
      }
    } else {
      div.style.boxShadow = `inset 0 -4px 0 0 ${event[0].vacationType.color}`;
    }
  };

  return (
    renderedData &&
    Object.keys(Constants.userData).length > 0 &&
    vacationTypesBalancesLoaded && (
      <>
        {Helpers.can([Constants.PERMISSIONS.ManageUsers]) ? (
          <>
            <div
              id="vacations_main"
              className="d-flex justify-content-center align-items-center h-100 flex-column"
            >
              <div className="h2 mb-5 d-flex align-items-center">
                Vacations of : {requestedUser.name}
              </div>
              <div className="row mb-4 w-100 justify-content-evenly align-items-top">
                <div
                  className="py-3 badge col-lg-2 align-self-end"
                  style={{
                    maxWidth: '170px',
                    backgroundColor: 'white',
                    color: 'green',
                    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <h5 className="mt-2 mb-3">Annual</h5>
                  <p className="h6 fw-normal text-dark">
                    Booked
                    {' : '}
                    {vacationTypesBalances[VACATIONTYPES.Normal.Name].used}
                  </p>
                  <p className="h6 fw-normal text-dark">
                    Available
                    {' : '}
                    {vacationTypesBalances[VACATIONTYPES.Normal.Name].remaining}
                  </p>
                </div>
                <div
                  className="py-3 badge col-lg-2"
                  style={{
                    maxWidth: '170px',
                    backgroundColor: 'white',
                    color: '#737373',
                    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <h5 className="mt-2 mb-3">Unpaid</h5>
                  <p className="h6 fw-normal text-dark">
                    Booked
                    {' : '}
                    {vacationTypesBalances[VACATIONTYPES.Unpaid.Name].used}
                  </p>
                  <p className="h6 fw-normal text-dark">
                    Available
                    {' : '}
                    {vacationTypesBalances[VACATIONTYPES.Unpaid.Name].remaining}
                  </p>
                </div>
              </div>
              <Calendar
                dataSource={renderedData}
                enableRangeSelection={true}
                enableContextMenu={false}
                displayWeekNumber={true}
                weekStart={6}
                // allowOverlap={false}
                style="custom"
                customDataSourceRenderer={(div, dateString, event) =>
                  customRenderEvent(div, dateString, event)
                }
                onDayClick={(e) => handleDayClick(e)}
                onDayEnter={(e) => handleDayEnter(e)}
                onDayLeave={() => handleDayLeave()}
                onRangeSelected={(e) =>
                  setCurrentVacation({
                    start_date: e.start_date,
                    end_date: e.end_date,
                  })
                }
              />
              {currentVacation.vacationType && (
                <VacationViewModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  currentVacation={currentVacation}
                />
              )}
            </div>
          </>
        ) : (
          <NoPermission />
        )}
      </>
    )
  );
}
