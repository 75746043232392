import * as React from 'react';

import ManageStatusUpdate from './ManageStatusUpdate';
import AddStatusUpdate from './AddStatusUpdate';

import * as Requests from '../../Helpers/Requests';
import MUIDataTable from 'mui-datatables';
import {
  StatusUpdatesTableCols,
  extractShownStatusUpdateData,
  formatStatusUpdatesData,
  getTableCols,
  getTableOptions,
} from './helpers';
import _ from 'lodash';
import Loading from '../loading';
import { can, formatObjectKeys } from '../../Helpers/Helpers';
import { PERMISSIONS } from '../../Helpers/Constants';
import { useParams } from 'react-router-dom';
import NoPermission from '../ErrorPages/NoPermission';

export default function ListStatusUpdates() {
  const params = useParams();

  const [requestedUser, setRequestedUser] = React.useState({});
  const [statusUpdatesData, setStatusUpdatesData] = React.useState([]);
  const [tableUpdated, setTableUpdated] = React.useState(false);

  // Manage StatusUpdate dialog //
  const [managedStatusUpdateLoaded, setManagedStatusUpdateLoaded] =
    React.useState(false);
  const [openManageStatusUpdateDialog, setOpenManageStatusUpdateDialog] =
    React.useState(false);
  const [managedStatusUpdateData, setManagedStatusUpdateData] = React.useState(
    [],
  );
  const [formattedStatusUpdateData, setFormattedStatusUpdateData] =
    React.useState([]);

  const [openAddStatusUpdateDialog, setOpenAddStatusUpdateDialog] =
    React.useState(false);

  React.useEffect(() => {
    Requests.getRequestedUser(params.userId)
      .then((user) => {
        setRequestedUser(user);
      })
      .catch((err) => Requests.handleRequestErrors(err));

    Requests.getUserStatusUpdates(params.userId)
      .then((_statusUpdatesData) => {
        setStatusUpdatesData(_statusUpdatesData);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [params.userId, tableUpdated]);

  React.useEffect(() => {
    if (managedStatusUpdateData) {
      // Extract Shown data from object fields (like role and team)
      let _formattedStatusUpdatesData = extractShownStatusUpdateData(
        managedStatusUpdateData,
      );

      // Format Object keys to get the displayable keys
      _formattedStatusUpdatesData = formatObjectKeys(
        _formattedStatusUpdatesData,
        StatusUpdatesTableCols,
      );

      setFormattedStatusUpdateData(_formattedStatusUpdatesData);
    }
  }, [managedStatusUpdateData]);

  const handleManageStatusUpdateOpen = async (statusUpdateId) => {
    const statusUpdate = _.find(statusUpdatesData, { id: statusUpdateId });
    setManagedStatusUpdateData(statusUpdate);
    setManagedStatusUpdateLoaded(true);
    setOpenManageStatusUpdateDialog(true);
  };

  const handleAddStatusUpdateOpen = () => {
    setOpenAddStatusUpdateDialog(true);
  };

  const handleAddStatusUpdateClose = () => {
    setOpenAddStatusUpdateDialog(false);
  };

  const handleManageStatusUpdateClose = () => {
    setOpenManageStatusUpdateDialog(false);
  };

  return (
    <>
      {can([
        PERMISSIONS.ViewAnyStatusUpdates,
        PERMISSIONS.ViewStatusUpdates,
      ]) ? (
        <>
          <AddStatusUpdate
            open={openAddStatusUpdateDialog}
            onClose={handleAddStatusUpdateClose}
            requestedUser={requestedUser}
            tableUpdated={tableUpdated}
            setTableUpdated={setTableUpdated}
          />

          {!statusUpdatesData ? (
            <Loading size="3.5rem" border="0.5rem" />
          ) : (
            <>
              {managedStatusUpdateLoaded && (
                <ManageStatusUpdate
                  open={openManageStatusUpdateDialog}
                  onClose={handleManageStatusUpdateClose}
                  managedStatusUpdateData={managedStatusUpdateData}
                  formattedStatusUpdateData={formattedStatusUpdateData}
                  tableUpdated={tableUpdated}
                  setTableUpdated={setTableUpdated}
                />
              )}
              {Boolean(Object.keys(requestedUser).length) && (
                <div className="h3 mb-5 d-flex align-items-center">
                  Status Updates of : {requestedUser.name}
                </div>
              )}
              <MUIDataTable
                title={'Status Updates'}
                data={formatStatusUpdatesData(statusUpdatesData)}
                columns={getTableCols()}
                options={getTableOptions(handleManageStatusUpdateOpen)}
              />
            </>
          )}
          {can([PERMISSIONS.CreateStatusUpdates]) ? (
            <button
              className="btn btn-success mt-3"
              onClick={() => handleAddStatusUpdateOpen()}
            >
              Add Status Update
            </button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
}
