import React from 'react';
import { getCurrentUser } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Requests from '../../Helpers/Requests';
import * as Constants from '../../Helpers/Constants';
import InfoCard from './Profile/InfoCard';
import {
  getColLabels,
  ProfileOrderedSections,
  Sections,
} from '../Users/helpers';
import { renderListItem } from '../Users/helpers/renderItems';
import { useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';

export default function Profile() {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [sectionUpdated, setSectionUpdated] = React.useState(false);
  const matches = useMediaQuery('(min-width:1100px)');

  const { formattedUserData: formattedCurrentUserData, userData: user } =
    useSelector((store) => store.currentUser);

  React.useEffect(() => {
    dispatch(getCurrentUser(Constants.userData.id));
  }, [dispatch, sectionUpdated]);

  const renderSections = (userData) => {
    const sections = matches ? ProfileOrderedSections : Sections;
    return Object.values(sections).map((section, index) => {
      const sectionData = getColLabels(section.Columns).map(
        (sectionColKey, colIndex) => (
          <div key={colIndex} className="px-2">
            {renderListItem([sectionColKey, userData[sectionColKey]])}
          </div>
        ),
      );

      return (
        <InfoCard
          key={index}
          userData={user}
          tableUpdated={sectionUpdated}
          setTableUpdated={setSectionUpdated}
          sectionLabel={section.Label}
          sectionData={sectionData}
          section={section}
        />
      );
    });
  };

  const userData = Constants.userData;
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {};

    formData.old_password = oldPassword;
    formData.new_password = password;
    formData.new_password_confirmation = passwordConfirm;
    Requests.formRequest('profile/change-password', 'put', formData)
      .then(() => {
        toast('Password Updated Successfully', { type: 'success' });
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      });
  };

  return (
    <>
      <title>Profile</title>
      <div className="container my-4 profile">
        <div style={{ maxWidth: '65rem' }}>
          <h3 className="mb-4 font-weight-normal">Manage Profile</h3>
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="card-title mb-0">
                <i className="fa fa-user-edit"></i> Change Password
              </h5>
            </div>
            <div className="card-body">
              <form className="form-signin">
                <div className="row mb-3">
                  <div className="form-group col-lg-4 col-md-6">
                    <label>Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      autoComplete="on"
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-4 col-md-6">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      autoComplete="on"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-4 col-md-6">
                    <label>Confirm New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      autoComplete="on"
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end me-2">
                  <input
                    type="submit"
                    value="Update Password"
                    style={{ width: '12rem' }}
                    className="btn btn-warning my-3"
                    onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            style={{
              gap: '5rem',
              columnCount: matches ? 2 : 1,
            }}
          >
            {formattedCurrentUserData
              ? renderSections(formattedCurrentUserData)
              : null}
          </div>
        </div>
      </div>
    </>
  );
}
