import * as React from 'react';
import { Outlet } from 'react-router-dom';
import NoPermission from '../components/ErrorPages/NoPermission';
import * as Helpers from '../Helpers/Helpers';

export default function PermissionProtectedRoutes({ permissions }) {
  if (Helpers.can(permissions)) {
    return <Outlet />;
  } else {
    return <NoPermission />;
  }
}
