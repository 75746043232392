import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@mui/material';

import * as HolidaysUtils from './HolidaysUtils.jsx';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import * as Requests from '../../Helpers/Requests';
import { handleDateFieldsChange } from '../../Helpers/Helpers.jsx';
import { toast } from 'react-toastify';

export default function HolidayCreateModal({
  currentHoliday,
  setHolidaysUpdated,
  holidaysUpdated,
  showModal,
  setShowModal,
  setCurrentHoliday,
}) {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);

  const dateFields = {
    StartDate: {
      Name: 'start_date',
    },
    EndDate: {
      Name: 'end_date',
    },
  };

  const handleCreateHolidaySubmit = () => {
    setRequestLoading(true);

    const formData = HolidaysUtils.createFormData(currentHoliday);
    Requests.formRequest(`holidays`, 'post', formData)
      .then((res) => {
        setHolidaysUpdated(!holidaysUpdated);
        setShowModal(null);
        toast('Holiday Created Successfully.', { type: 'success' });
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const handleNameUpdate = (e) => {
    setCurrentHoliday({
      ...currentHoliday,
      name: e.target.value,
    });
  };

  return (
    currentHoliday && (
      <>
        <Modal
          style={{ marginTop: '20vh' }}
          show={showModal === 'create'}
          onHide={() => setShowModal(null)}
        >
          <Modal.Header>
            <Modal.Title>Create Holiday</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal remove_bg_color">
              <div className="form-group row mt-2 mb-4">
                <label
                  htmlFor="holiday-name"
                  className="col-sm-2 control-label"
                >
                  Name
                </label>
                <div className="col-sm-10">
                  <TextField
                    label="Name"
                    id="holiday-name"
                    type="text"
                    className="form-control"
                    value={currentHoliday.reason}
                    onChange={(e) => handleNameUpdate(e)}
                  />
                </div>
              </div>
              <div className="form-group row mt-4 mb-4">
                <label htmlFor="min-date" className="col-sm-2 control-label">
                  Duration
                </label>
                <div className="col-sm-10">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={currentHoliday.start_date}
                      onChange={(date) =>
                        handleDateFieldsChange(
                          date,
                          'start_date',
                          dateFields,
                          setInvalidDateValue,
                          currentHoliday,
                          setCurrentHoliday,
                        )
                      }
                      inputFormat="DD-MM-YYYY"
                      label="From"
                      renderInput={(params) => <TextField {...params} />}
                      className="mb-2"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={currentHoliday.end_date}
                      onChange={(date) =>
                        handleDateFieldsChange(
                          date,
                          'end_date',
                          dateFields,
                          setInvalidDateValue,
                          currentHoliday,
                          setCurrentHoliday,
                        )
                      }
                      inputFormat="DD-MM-YYYY"
                      label="To"
                      renderInput={(params) => <TextField {...params} />}
                      className="my-2"
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(null)}>
              Close
            </Button>
            <Button
              variant="success"
              onClick={() => handleCreateHolidaySubmit()}
              disabled={requestLoading || invalidDateValue}
            >
              Create Holiday
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
}
