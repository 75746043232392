import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Autocomplete, FormControl, TextField } from '@mui/material';
import { toast } from 'react-toastify';

import * as Requests from '../../../../Helpers/Requests';
import _ from 'lodash';
import {
  catchRequestError,
  handleDateFieldsChange,
  useEffectAsync,
} from '../../../../Helpers/Helpers';
import dayjs from 'dayjs';
import { Pages } from '../../../../Helpers/Constants';
import { NursingScheduleDateFields } from '../helpers/Constants';

export default function NursingScheduleRequestMain() {
  const [formData, setFormData] = React.useState({
    start_date: dayjs().format('YYYY-MM-DD'),
    end_date: dayjs().format('YYYY-MM-DD'),
  });
  const [invalidFormFields, setInvalidFormFields] = React.useState(true);
  const [invalidDateValue, setInvalidDateValue] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});

  useEffectAsync(async () => {
    setUsersData(await catchRequestError(Requests.getStaff()));
  }, []);

  React.useEffect(() => {
    if (Object.keys(formData).length) {
      const RequiredFormFields = ['start_date', 'end_date', 'user_id'];

      const isInvalid = RequiredFormFields.some((field) => !formData[field]);

      setInvalidFormFields(isInvalid);
    }
  }, [formData]);

  const handleMonthChange = (date, fieldName) => {
    handleDateFieldsChange(
      date,
      fieldName,
      NursingScheduleDateFields,
      setInvalidDateValue,
      formData,
      setFormData,
    );
  };

  const handleDropDownChange = (colName, newValueObj) => {
    setSelectedUser({
      [colName]: newValueObj?.id || '',
    });
    setFormData({
      ...formData,
      user_id: newValueObj?.id || '',
    });
  };

  const handleSubmit = (e) => {
    setRequestLoading(true);
    e.preventDefault();

    const formValues = { ...formData };
    Requests.requestNursingSchedule(formValues, formValues.user_id)
      .then(() => {
        toast('Child Care Requested Successfully.', {
          type: 'success',
        });
        setTimeout(() => {
          window.location.href = Pages.NursingScheduleRequested.path();
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <div className="h2 ms-3 mb-5 w-50 d-flex align-items-center">
        Request Child Care Hours
      </div>
      <FormControl className="w-100" variant="standard">
        <div className="d-flex flex-row align-items-start justify-content-between">
          <div className="d-flex flex-column w-100">
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Employee :
              </div>
              <Autocomplete
                getOptionLabel={(option) => {
                  if (typeof option === 'object') {
                    return String(option.email);
                  } else {
                    const optionObj = _.find(usersData, {
                      id: option,
                    });
                    return optionObj ? String(optionObj.email) : option;
                  }
                }}
                isOptionEqualToValue={(optionObj, valueObj) => {
                  return optionObj.id == valueObj;
                }}
                value={selectedUser.id || null}
                options={usersData ? Object.values(usersData) : []}
                onChange={(e, newValue) => handleDropDownChange('id', newValue)}
                sx={{ width: 300 }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={'Email'}
                      className="mb-1 d-block"
                      fullWidth
                    />
                  );
                }}
              />
            </div>
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Start Date:
              </div>
              <DatePicker
                label="Select start date"
                views={['year', 'month', 'day']}
                value={formData.start_date}
                onChange={(date) => handleMonthChange(date, 'start_date')}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className="d-flex mt-5">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                End Date:
              </div>
              <DatePicker
                label="Select end date"
                views={['year', 'month', 'day']}
                value={formData.end_date}
                onChange={(date) => handleMonthChange(date, 'end_date')}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
        </div>
        <div className="w-100 d-flex align-items-center mt-5">
          <button
            type="submit"
            className="btn btn-success ms-auto me-5"
            onClick={(e) => handleSubmit(e)}
            disabled={invalidFormFields || invalidDateValue || requestLoading}
          >
            Submit Request
          </button>
        </div>
      </FormControl>
    </>
  );
}
