import * as Constants from '../../../Helpers/Constants';
import * as Helpers from '../../../Helpers/Helpers';
import _ from 'lodash';
import {
  FINISHED_STATUSES_LOANS,
  RequestsTableLoanStatusesConstants,
  PENDING_STATUSES_LOANS,
} from '../consts';

// Statuses Related Functions

export const getLoansStatusesByTableType = (tableType) => {
  if (tableType === Constants.TablesTypes.Pending) {
    return PENDING_STATUSES_LOANS;
  } else if (tableType === Constants.TablesTypes.Finished) {
    return FINISHED_STATUSES_LOANS;
  }
};

export const createLoansStatusesFilterQuery = (tableType) => {
  const statuses = getLoansStatusesByTableType(tableType);
  const queryString = Helpers.createStatusesQuery(statuses);
  return queryString;
};
// Statuses Related Functions

// Mui datatables Utils

export const getDatatableColumns = () => {
  const datatableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'reason',
      label: 'Reason',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'installments_count',
      label: 'Installments Count',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updated_at',
      label: 'Last Updated At',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return datatableColumns;
};

export const originalToStyled = (originalRequests) => {
  const renderedRequests = originalRequests.map((request) => {
    // Adding status style to the status
    const statusStyle =
      _.find(RequestsTableLoanStatusesConstants, {
        id: request.status.id,
      }) || {};
    request.status.style = statusStyle.style;
    return request;
  });

  return renderedRequests;
};

export const styledToMuiDatatable = (renderedRequests, actionsClickHandler) => {
  let datatableRequests = [];
  datatableRequests = renderedRequests.map((request) => {
    let _updatedRequest = {};
    _updatedRequest = {
      ...request,
      // Decimal Time to minutes and hours should be here
      status: Helpers.createStatusBadgeDiv(
        request.status.name,
        request.status.style.bg,
        request.status.style.text,
      ),
    };
    return _updatedRequest;
  });

  return datatableRequests;
};

export const originalToPrintable = (originalRequests) => {
  return originalRequests.map((element) => {
    return {
      id: element.id,

      value: element.value,
      reason: element.reason,
      status: element.status.name,
      comment: element.comment,

      'created by': element.created_by,
      'updated by': element.updated_by,
      'created at': element.created_at,
      'updated at': element.updated_at,
    };
  });
};

// Mui datatables Utils

export const createFilterFields = (currentTableState, applyFilters) => {
  // getting the fields that have value in it,
  // and getting its name from the table columns array
  const filterList = applyFilters();
  const filterFields = {};
  filterList.forEach((field, index) => {
    if (field[0]) {
      const fieldName = currentTableState.columns[index].name;
      const fieldValue = field[0];
      filterFields[fieldName] = fieldValue;
    }
  });
  return filterFields;
};
