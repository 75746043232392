import * as React from 'react';
import { Button } from 'react-bootstrap';

import * as Requests from '../../../../Helpers/Requests';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';
import { Pages } from '../../../../Helpers/Constants';

export default function Delete({
  onClose,
  open,
  renderedLoanRequest,
  selectedValue,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const [requestLoading, setRequestLoading] = React.useState(false);

  const handleDeleteLoan = (e) => {
    setRequestLoading(true);
    e.preventDefault();

    Requests.deleteLoan(renderedLoanRequest.user_id, renderedLoanRequest.id)
      .then(() => {
        toast('Loan was deleted successfully.', {
          type: 'success',
        });
        setTimeout(() => {
          window.location.replace(Pages.LoansManageRunning.path());
        }, 1000);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: 'error' });
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleDeleteLoan} style={{ width: '500px' }}>
          <DialogTitle>Delete Loan</DialogTitle>
          <DialogContent dividers>
            <List sx={{ pt: 0 }}>
              <div className="my-2">
                <div>Are you sure you want to delete this Loan?</div>
                <b> This action can not be undone.</b>
              </div>
            </List>
          </DialogContent>
          <DialogActions className="d-flex justify-content-between mx-2">
            <button className="btn btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              disabled={requestLoading}
            >
              Confirm Delete
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
