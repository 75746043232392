import * as React from 'react';

import ManageUser from './ManageUser';

import * as Requests from '../../Helpers/Requests';
import MUIDataTable from 'mui-datatables';
import {
  UsersTableCols,
  extractShownUserData,
  formatUsersData,
  getTableCols,
  getTableOptions,
} from './helpers';
import _ from 'lodash';
import Loading from '../loading';
import { formatFilesLinks, formatObjectKeys } from '../../Helpers/Helpers';

export default function ListUsers({ UsersList }) {
  const [usersData, setUsersData] = React.useState([]);
  const [tableUpdated, setTableUpdated] = React.useState(false);

  // Manage User dialog //
  const [managedUserLoaded, setManagedUserLoaded] = React.useState(false);
  const [openManageUserDialog, setOpenManageUserDialog] = React.useState(false);
  const [managedUserData, setManagedUserData] = React.useState({
    role: '',
    manager: '',
    team: '',
  });
  const [formattedUserData, setFormattedUserData] = React.useState({
    role: '',
    manager: '',
    team: '',
  });

  React.useEffect(() => {
    Requests.getUsers(UsersList)
      .then((_usersData) => {
        _usersData = formatFilesLinks(_usersData);
        setUsersData(_usersData);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [tableUpdated, UsersList]);

  React.useEffect(() => {
    if (managedUserData) {
      // Extract Shown data from object fields (like role and team)
      let _formattedUserData = extractShownUserData(managedUserData);

      // Format Object keys to get the displayable keys
      _formattedUserData = formatObjectKeys(_formattedUserData, UsersTableCols);

      setFormattedUserData(_formattedUserData);
    }
  }, [managedUserData]);

  // // Add User dialog //
  // const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
  // const handleAddUserOpen = () => setOpenAddUserDialog(true);
  // const handleAddUserClose = () => setOpenAddUserDialog(false);
  // // Add User dialog //

  const handleManageUserOpen = (userId) => {
    const user = _.find(usersData, { [UsersTableCols.ID.Name]: userId });
    setManagedUserData(user);
    setManagedUserLoaded(true);
    setOpenManageUserDialog(true);
  };

  const handleManageUserClose = () => setOpenManageUserDialog(false);
  // Manage User dialog //

  // Rendering the component
  return (
    <>
      {/* <AddUser
        open={openAddUserDialog}
        onClose={handleAddUserClose}
        tableUpdated={tableUpdated}
        setTableUpdated={setTableUpdated}
      /> */}
      {!usersData ? (
        <Loading size="3.5rem" border="0.5rem" />
      ) : (
        <>
          {managedUserLoaded && (
            <ManageUser
              open={openManageUserDialog}
              onClose={handleManageUserClose}
              usersData={usersData}
              managedUserData={managedUserData}
              formattedUserData={formattedUserData}
              tableUpdated={tableUpdated}
              setTableUpdated={setTableUpdated}
            />
          )}
          <MUIDataTable
            title={'Employees List'}
            data={formatUsersData(usersData)}
            columns={getTableCols()}
            options={getTableOptions(handleManageUserOpen)}
          />
        </>
      )}
      {/* <button
        className="btn btn-success mt-3"
        onClick={() => handleAddUserOpen()}
      >
        Add User
      </button> */}
    </>
  );
}
